define([], function() {
  function MultipleImageChoiceGame(config) {
    this.parent_id = config.parent_id;
    this.number_of_answers = config.number_of_answers;
    this.answer_choices = [];
    this.iOS = false;

    this.iOS = /(iPad|iPhone|iPod)/g.test( navigator.userAgent );
  }

  MultipleImageChoiceGame.prototype = {
    init: function() {

      // Event listeners
      var this_obj = this,
        namespace = '#' + this_obj.parent_id;

      if (!this.iOS) {
        $(namespace + ' .mic_answer').addClass('not_ios');
      }

      $(namespace + ' .mic_answer').on("click", function () {
        if ($(this).hasClass("mic_selected")) {
          $(this).removeClass("mic_selected");
        }
        else {
          //make selections
          $(this).addClass('mic_selected');

          var div = $(this);
          var span = div.closest(namespace + ' .mic_wrapper').children('span')[0];
        }
      });

      $(namespace + ' .mic_check_answer').on("click", function () {
        var number_correct = $(namespace + ' .mic_answer.mic_correct.mic_selected').length,
          modifier = '';

        if ($(namespace + ' .mic_selected').length) {
          $(namespace + ' .mic_answer').removeClass('mic_submitted');
          $(namespace + ' .mic_answer.mic_selected').addClass('mic_submitted');

          if ($(namespace + ' .mic_answer.mic_correct.mic_submitted').length == this_obj.number_of_answers &&
            $(namespace + ' .mic_answer.mic_submitted').length == this_obj.number_of_answers) {
            $(namespace + ' .mic_incorrect_text')
              .stop(true, true)
              .fadeOut(100);

            $(namespace + ' .mic_somecorrect_text')
              .stop(true, true)
              .fadeOut(100);

            $(namespace + ' .mic_correct_text')
              .stop(true, true)
              .slideDown(300)
              .delay(4000)
              .fadeOut(1000);
          }
          else if (($(namespace + ' .mic_answer.mic_submitted').not('.mic_correct').length > 0 &&
            $(namespace + ' .mic_answer.mic_correct.mic_submitted').length > 0)
            || (($(namespace + ' .mic_answer.mic_correct.mic_submitted').length < this_obj.number_of_answers) &&
            $(namespace + ' .mic_answer.mic_correct.mic_submitted').length > 0)) {

            if ($(namespace + ' .mic_answer.mic_submitted').length > this_obj.number_of_answers &&
              $(namespace + ' .mic_answer.mic_correct.mic_submitted').length == this_obj.number_of_answers) {
              modifier = ' only ';
            }

            $(namespace + ' .mic_incorrect_text')
              .stop(true, true)
              .fadeOut(100);

            $(namespace + ' .mic_correct_text')
              .stop(true, true)
              .fadeOut(100);

            if (this_obj.number_of_answers == 1) {
              $(namespace + ' .mic_somecorrect_text')
                .find('.mic_response_text')
                .text('There is ' + modifier + this_obj.number_of_answers + ' correct answer. You\'ve found ' + number_correct + ' of them.');
            }
            else {
              $(namespace + ' .mic_somecorrect_text')
                .find('.mic_response_text')
                .text('There are ' + modifier + this_obj.number_of_answers + ' correct answers. You\'ve found ' + number_correct + ' of them.');
            }


            $(namespace + ' .mic_somecorrect_text')
              .stop(true, true)
              .slideDown(300)
              .delay(4000)
              .fadeOut(1000);
          }
          else {

            $(namespace + ' .mic_correct_text')
              .stop(true, true)
              .fadeOut(100);

            $(namespace + ' .mic_somecorrect_text')
              .stop(true, true)
              .fadeOut(100);

            if (this_obj.number_of_answers == 1) {
              $(namespace + ' .mic_incorrect_text')
                .find('.mic_response_text')
                .text('There is ' + this_obj.number_of_answers + ' correct answer. Try again.');
            }
            else {
              $(namespace + ' .mic_incorrect_text')
                .find('.mic_response_text')
                .text('There are ' + this_obj.number_of_answers + ' correct answers. Try again.');
            }

            $(namespace + ' .mic_incorrect_text')
              .stop(true, true)
              .slideDown(300)
              .delay(4000)
              .fadeOut(1000);
          }
        }
        else {
          $(namespace + ' .it_dialog_box')
            .text('Please select an answer.')
            .show()
            .delay(2500)
            .fadeOut(100)
        }
      });
      $(namespace + ' .mic_reset_game').on('click', function () {
        $(namespace + ' .mic_answer').removeClass('mic_selected');
        $(namespace + ' .mic_answer').removeClass('mic_submitted');
      });
    }
  };

  return MultipleImageChoiceGame
});
