define [], ->
  check_all = () ->
    $('#check_all').on('change', ->
      if $('#check_all').is(':checked')
        $('.checkbox').attr('checked', true)
      else
        $('.checkbox').attr('checked', false)
    )

  init: ->
    check_all()