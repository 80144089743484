define([], function() {
  function init($mainIdeaButton) {
    $mainIdeaButton.on('click', function(e) {
      e.preventDefault();
      const $mainIdeas = $('.mainideas');
      const $themedMainIdeas = $('.lower-el-sci, .upper-el-sci, .lower-el-ss, .upper-el-ss, .ms-ss, .hs-ss, .ms-sci');

      if ($mainIdeas.length === 0 && $themedMainIdeas.length === 0) {
        alert('There are no main ideas for this section.');
      }
      else if ($(this).hasClass('on')) {
        $mainIdeas.css({ color: '#3434C8', 'font-weight': 'bold' });
        $themedMainIdeas.toggleClass('display-main-ideas', true);
        $('.main-idea-button.off').show();
        $('.main-idea-button.on').hide();
      }
      else {
        $mainIdeas.css({ color: '#3c3c3c', 'font-weight': 'normal' });
        $themedMainIdeas.toggleClass('display-main-ideas', false);
        $('.main-idea-button.on').show();
        $('.main-idea-button.off').hide();
      }
    });
  }

  return {
    init: init
  };
});
