define(['ImagePopup', 'Zoom'], function(ImagePopup, Zoom) {
  /**
   * Zoom < ImagePopup
   *
   * ImagePopup with dragging and zoom in zoom out buttons
   *
   * @param config
   * @constructor
   */
  function StudentZoom(config) {
    // Inherit from base class
    ImagePopup.call(this, config);
    // Extend
    this.image_box = config.image_box;
  }

  // Start with a base prototype of ImagePopup.prototype
  StudentZoom.prototype = Object.create(ImagePopup.prototype);
  StudentZoom.prototype.constructor = StudentZoom;

  /**
   *  Basic initialization that extends the base
   *  method to include listeners for mousemove (for
   *  magnifying glass)
   */
  StudentZoom.prototype.init = function() {
    // Super
    ImagePopup.prototype.init.apply(this, arguments);
    this.zoom = new Zoom.default({ image_box: this.image_box });
    this.zoom.init()
  };

  StudentZoom.prototype.reset = function() {
    // Super
    this.zoom.reset();
  };

  /**
   * Overwrites the base method in order to include the
   *
   * @returns {*}
   * @private
   */
  StudentZoom.prototype._createZoomedImage = function() {
    let dfd = $.Deferred(),
        this_obj = this,
        image = document.createElement('img');

    image.onload = () => dfd.resolve();

    image.src = this.zoom_url;

    dfd.done(() => {
      document.body.appendChild(image);
      this_obj._setImageDimensions(image);
      this_obj.zoom._createTools();
    });

    return dfd.promise();
  };

  return StudentZoom
});
