define([], function() {
  function TeacherComment(config) {
    this.answer_id = config.answer_id;
    this.textbox = config.textbox;
    this.message_element = config.message_element;
    this.editing_message = config.editing_message || 'Editing...';
    this.saving_message = config.saving_message || 'Saving...';
    this.saved_message = config.saved_message || 'Saved!';
    this.edit_class = config.edit_class || 'editing_comment_message';
    this.saved_class = config.saved_class || 'saved_comment_message';
    this.url = config.url;
  }

  TeacherComment.prototype = {
    init: function () {
      $(this.textbox).on('focus', function() {
        $(this.message_element).html('bllaaah');
        $(this.message_element).text(this.editing_message);
        $(this.message_element).addClass(this.edit_class);
        $(this.message_element).show();
      }.bind(this));

      $(this.textbox).on('blur', function() {
        var comment = $(this.textbox).val();
        var data;

        if (comment != undefined && comment != '') {
          $(this.message_element).text(this.saving_message);

          data = {
            question_answer: {
              teacher_comment: comment
            },
            id: this.answer_id
          };

          $.ajax({
            type: 'JSON',
            method: 'put',
            url: this.url,
            data: data,
            headers: {
              'X-Transaction': 'POST Example',
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
          })
            .done(function(data) {
              $(this.message_element).text(this.saved_message);
              $(this.message_element).addClass(this.saved_class);
              $(this.message_element).fadeOut(500);
            }.bind(this))
        }
      }.bind(this));
    }
  };

  return TeacherComment
});