define(['TCIUtils'], function(TCIUtils) {
  function buildMcGame(game_data, game_id) {
    var content_block = '',
      game_obj = game_data[0],
      game_config = game_obj.config,
      game_answers = game_obj.options,
      answers = [],
      letters = ['A', 'B', 'C', 'D', 'E', 'F'],
      correct_answer_list = game_config.correct_option.split(', '),
      game_question = game_config.question,
      game_media;
    if (game_config.media) game_media = game_config.media[0];

    content_block += '<div class="mc_question interaction_question">' + game_question + '</div>';
    content_block += '<div class="mc_answers_container">';

    for (let i = 0; i < game_answers.length; i++) {
      if (game_answers[i] == "") continue;
      if (correct_answer_list.indexOf(i.toString()) > -1) {
        answers.push('<div class="mc_answer id_' + i + ' mc_correct"><div class="mc_radio_button">' +
          '<div><i class="fa fa-check"></i></div>' +
          '</div><div class="mc_answer_letter">' + letters[i] + '.</div>' + game_answers[i] + '</div>');
      }
      else {
        answers.push('<div class="mc_answer id_' + i + '"><div class="mc_radio_button">' +
          '<div><i class="fa fa-times"></i></div>' +
          '</div><div class="mc_answer_letter">' + letters[i] + '.</div>' + game_answers[i] + '</div>');
      }
    }

    for (let i = 0; i < answers.length; i++) {
      content_block += answers[i];
    }
    content_block += '<button class="mc_check_answer btn btn--green">Check Answer</button>';
    content_block += '<button class="mc_reset_game btn btn--purple">Reset</button>';
    content_block += '</div>';

    if (game_media == 'image') {
      content_block += '<div class="mc_media mc_image" role="presentation"><img src="' + game_config.media[1] + '" alt="" /></div>';

    }
    else if (game_media === 'aws_video') {
      content_block += '<div class="mc_media mc_video">' +
        '<div id="video_player">' +
        '<video preload="metadata" src="' + game_config.media[1] + '"' + 'class="aws_video" width="641" height="435"></video>' +
        '</div>';
    }
    else if (game_media == "vimeo_id") {
      content_block += '<div class="mc_media mc_video">' +
        '<div id="video_player">' +
        '<div class="embed_vimeo">' +
        '<iframe id="vimeo_iframe" src="https://player.vimeo.com/video/' +
        game_config.media[1] + '?fun=0" width= 641 height= 435 ' +
        '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>' +
        '</div>' +
        '</div></div>';
    }

    content_block += '<div class="mc_response_container">';
    content_block += '<div class="mc_response mc_correct_text"><div class="mc_response_back"></div><span class="mc_response_text">Correct! ' + game_config.correct + '</span></div>';
    content_block += '<div class="mc_response mc_somecorrect_text"><div class="mc_response_back"></div><span class="mc_response_text"></span></div>';
    content_block += '<div class="mc_response mc_incorrect_text"><div class="mc_response_back"></div><span class="mc_response_text"></span></div>';
    content_block += "<div class='it_dialog_box'></div>";

    $(`#interaction_${game_id}`).html(content_block);

    TCIUtils.initVideoPlayers();

    return {
      number_of_answers: correct_answer_list.length
    };
  }

  return {
    build: buildMcGame
  };
});
