define([], function() {
  var input_el,
      submit_btn_el,
      dropdown_el,
      hidden_input_el,
      search_url,
      error_div,
      parent_el,
      international_checkbox,
      international_input,
      school_input,
      district_input,
      state_input;

  function setupCheckboxListener() {
    $(international_checkbox).on('change', function(e) {
      if (this.checked) {
        input_el.parentElement.style.display = 'none';
        international_input.style.display = 'inline-block';
        hidden_input_el.value = null;
        school_input.parentElement.style.display = 'none';
        district_input.parentElement.style.display = 'none';
        state_input.parentElement.style.display = 'none';
        nullOutInputs();
        error_div.style.display = 'none';

        if (dropdown_el) {
          dropdown_el.style.display = 'none';
          $(dropdown_el).children()[0].selected = 'selected';
        }
      }
      else {
        input_el.parentElement.style.display = 'inline-block';
        international_input.style.display = 'none';
      }
    });
  }

  function setupSubmitListener() {
    $(submit_btn_el).on('click', function(e) {
      e.preventDefault();
      error_div.style.display = 'none';

      search(input_el.value);
    });
  }

  function populateDropdown(data) {
    var opt,
        br;

    if (data.length === 0) {
      error_div.innerText = 'No results found. Please enter school information or try a different zip code.';
      error_div.style.display = 'block';
      if (dropdown_el) {
        dropdown_el.style.display = 'none';
      }
      showInfoInputs();
      return;
    }

    if (dropdown_el === undefined) {
      dropdown_el = document.createElement('select');

      parent_el.appendChild(dropdown_el);

      setupDropdownListener();
    }
    else {
      $(dropdown_el).empty();
    }

    opt = document.createElement('option');
    opt.innerHTML = 'Select one...';
    opt.disabled = true;
    opt.selected = true;
    dropdown_el.appendChild(opt);

    for (var i = 0; i < data.length; i++) {
      br = document.createElement('br');
      opt = document.createElement('option');
      opt.value = data[i]["mdr_id"];
      opt.innerText = data[i]["name"];

      dropdown_el.style.display = 'inline';
      dropdown_el.className = 'mt20';
      dropdown_el.appendChild(br);
      dropdown_el.appendChild(opt);
    }

    opt = document.createElement('option');
    opt.innerHTML = 'Other';
    opt.value = 'Other';
    dropdown_el.appendChild(opt);
  }

  function setupDropdownListener() {
    $(dropdown_el).on('change', function() {

      var selected_value = dropdown_el.options[dropdown_el.selectedIndex].value;

      if (selected_value == 'Other') {
        showInfoInputs();
      }
      else {
        state_input.parentElement.style.display = 'none';
        district_input.parentElement.style.display = 'none';
        school_input.parentElement.style.display = 'none';
        nullOutInputs();
        hidden_input_el.value = selected_value;
      }
    });
  }

  function showInfoInputs() {
    hidden_input_el.value = null;
    state_input.parentElement.style.display = 'block';
    district_input.parentElement.style.display = 'block';
    school_input.parentElement.style.display = 'block';
  }

  function nullOutInputs() {
    school_input.value = null;
    district_input.value = null;
    $(state_input).children()[0].selected = 'selected';
  }

  function search(term) {
    $.ajax(search_url + '?zip_code=' + term + '.json', {
      type: 'GET'
    })
      .done(function(data) {
        populateDropdown(data);
      })
      .fail(function() {

      });
  }

  return {
    init: function(config) {
      input_el = config.input_el;
      submit_btn_el = config.submit_btn_el;
      hidden_input_el = config.hidden_input_el;
      search_url = config.search_url;
      parent_el = config.parent_el;
      international_checkbox = config.international_checkbox;
      international_input = config.international_input;
      school_input = config.school_input;
      district_input = config.district_input;
      state_input = config.state_input;

      error_div = document.createElement('div');
      error_div.className = 'mt10 red';
      parent_el.appendChild(error_div);
      setupCheckboxListener();
      setupSubmitListener();
    }
  }
});