define(['TCIUtils'], function(TCIUtils) {
  function toc_settings_hash() {
    var toc_settings = { settings: {} };
    $('#program_toc_settings input').each(function() {
      toc_settings.settings[$(this).attr('id')] = { default: $(this).is(':checked') };
    });
    return JSON.stringify(toc_settings);
  }

  function updateSettings() {
    var update_path = $('#update_toc_path').html();
    $.ajax({
      type : "POST",
      url: update_path,
      dataType: 'json',
      contentType: 'application/json',
      data: toc_settings_hash()
    });
  }

  function checkElementBorders() {
    $('.technical_details').each(function() {
      var kit_needed = $(this).children().first();
      var class_time = $(this).children().last();

      if ((kit_needed.length > 0 && class_time.length > 0 &&
        kit_needed.css('display') != "none" &&
        kit_needed.html().trim().length != 0 &&
        class_time.css('display') != "none" &&
        class_time.html().trim().length != 0
        )) {
        kit_needed.addClass('right_border');
        kit_needed.addClass('pr15');
        class_time.addClass('pl15');
      }
      else {
        kit_needed.removeClass('right_border');
        kit_needed.removeClass('pr15');
        class_time.removeClass('pl15');
      }
    });
  }

  function bindCheckboxListener(checkbox) {
    $(checkbox).on('change', function() {
      showHideCategory(checkbox);
      updateSettings();
      checkElementBorders();
    });
  }

  const destructive_settings = ['only_display_trial_lessons'];

  function showHideCategory(checkbox) {
    const isDestructive = destructive_settings.indexOf($(checkbox).attr('id')) > -1;
    const isChecked = $(checkbox).is(':checked');
    if (isChecked !== isDestructive) {
      $('.' + $(checkbox).attr('id')).show();
    }
    else {
      $('.' + $(checkbox).attr('id')).hide();
    }
  }

  function initJumpToLessonListener() {
    $('.lesson-jump').on('click', function() {
      $('.lesson').each(function() {
        if ($(this).hasClass('only_display_trial_lessons')) {
          return true;
        }
        $('html, body').stop().animate({ scrollTop: $(this).offset().top }, 500);
        return false;
      });
    });
  }

  function initCloseInfoBoxListener() {
    $('.close-box').on('click', function() {
      $.ajax({
        type: 'PUT',
        url: $(this).data().url,
        data: {}
      })
        .done(function() {
          $('.lessons_available').hide();
        });
    });
  }

  return {
    init: function() {
      checkElementBorders();

      // disable view classes link for student view
      if ($('.view_classes.disabled').length > 0) {
        $('.view_classes.disabled').on('click', function(e) {
          e.preventDefault();
        });
      }

      // disable dropdown menu from closing when a menu item is clicked:
      const $dropdownMenu = $('.modify-view .dropdown-menu');
      if ($dropdownMenu.length > 0) {
        $dropdownMenu.on('click', (e) => {
          e.stopPropagation();
        });
      }
    },
    initLoad: function() {
      $('#program_toc_settings input').each(function() {
        showHideCategory(this);
        bindCheckboxListener(this);
      });
      // TODO: remove once all users are migrated to program contents page
      if (!($('.program-contents').length)) TCIUtils.lockElementToTop('toc_filters');
      initJumpToLessonListener();
      initCloseInfoBoxListener();
    }
  };
});
