define(['TCIUtils'], function(TCIUtils) {
  function buildLifecycleGame(game_data, game_id) {
    var counter = 0,
      images = [],
      labels = [],
      targets = [],
      content_block = '',
      game_question = '';
    game_data = game_data[0];

    game_question = game_data.directions;
    content_block += "<div class='lc_question interaction_question'>" + game_question + "</div>";

    for (var obj in game_data.labels[0]) {
      if (obj != "") {
        counter++;
      }
    }

    content_block += "<div class='lc_left_side'><div id='image_container_" + game_id + "' class='img_origin dd_origin'>";
    for (var i = 0; i < counter; i++) {
      var image = "<img class='dd_answer dd_answer_js dd_answer_img lc_image id_" + (i + 1) + " " + ((i == 1 || i == 3) ? 'lc_fr' : 'lc_fl') + "' src='" + game_data.images[i + 1] + "' />";
      images.push(image);
    }

    images = TCIUtils.fisherYatesShuffle(images);
    for (var i = 0; i < counter; i++) {
      content_block += images[i];
    }
    content_block += "</div><div id='label_container_" + game_id + "' class='text_origin dd_origin'>";

    labels = [];
    var i = counter;
    for (var obj in game_data.labels[0]) {
      if (obj != "") {
        var label = "<div class='dd_answer dd_answer_js dd_answer_text id_" + (i + 1) + "'>" + game_data.labels[0][obj] + "</div>"
        labels.push(label);
      }
      i++
    }


    labels = TCIUtils.fisherYatesShuffle(labels);
    for (var i = counter; i < 2 * counter; i++) {
      content_block += labels[i - counter];
    }
    content_block += "</div>" +
    "<a href='##' class='lc_check_answer check_answer dd_check_answers'>Check My Answers</a>" +
    "<a href='##' class='lc_reset_answer dd_reset_answers'>Reset</a></div>";

    content_block += "<div class='lc_right_side'><img class='lc_background_image' src='" + game_data.images[0] + "' />";


    content_block +=
      "<div class='lc_dropzone_container container_1'>" +
      "<div class='dd_dropzone id_1 dd_answer_img'></div>" +
      "<div class='dd_dropzone id_5 dd_answer_text'></div>" +
      "</div>" +

      "<div class='lc_dropzone_container container_2'>" +
      "<div class='dd_dropzone id_2 dd_answer_img'></div>" +
      "<div class='dd_dropzone id_6 dd_answer_text'></div>" +
      "</div>" +

      "<div class='lc_dropzone_container container_3'>" +
      "<div class='dd_dropzone id_3 dd_answer_img'></div>" +
      "<div class='dd_dropzone id_7 dd_answer_text'></div>" +
      "</div>" +

      "<div class='lc_dropzone_container container_4'>" +
      "<div class='dd_dropzone id_4 dd_answer_img'></div>" +
      "<div class='dd_dropzone id_8 dd_answer_text'></div>" +
      "</div>" +
      "</div>";

    content_block += "<div class='it_dialog_box'></div>";
    $('#interaction_' + game_id).html(content_block);
  }

  return {
    build: buildLifecycleGame
  }
});