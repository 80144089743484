define(['DropdownAccessibility'], function(DropdownAccessibility) {
  let identifier;
  let dropdown_selector;
  let orientation_selector;
  const dropdowns = [];

  function DropDown(config) {
    this.trigger_node = config.trigger_node;
    this.id = config.id;
    this.orientation = config.orientation || 'right';
    this.is_visible = false;
  }

  DropDown.prototype = {
    init: function() {
      this.dropdown_node = $(`${dropdown_selector}[${identifier}="${this.id}"]`)[0];
      if (this.orientation === 'left') {
        this.dropdown_node.style.left = 0;
        this.dropdown_node.style.right = 'auto';
      }
      else {
        this.dropdown_node.style.left = 'auto';
        this.dropdown_node.style.right = '-16px';
      }

      this.trigger_link = $(this.trigger_node).find('a.js-dropdown-trigger');

      const dropdown_accessibility = new DropdownAccessibility({
        object: this,
        toggleFn: this.toggle,
        openFn: this.open,
        closeFn: this.close,
        trigger: this.trigger_link,
        triggerWrapper: this.trigger_node,
        dropdownWrapper: this.dropdown_node,
        dropdownOptionWrapper: 'li.js-dropdown-item',
        dropdownOptionClass: 'a.js-dropdown-link'
      });

      dropdown_accessibility.init();

      /* Set up listeners */
      $(document).on('click', (e) => {
        let i = 0;
        let current = e.target;

        for (i; i < 8; i++) {
          if (current === this.trigger_node) {
            this.toggle();
            return;
          }
          else if (current.parentNode) {
            current = current.parentNode;
          }
          else {
            this.close();
            return;
          }
        }
        this.close();
      });
    },

    close: function() {
      this.dropdown_node.style.display = 'none';
      $(this.trigger_node).removeClass('white_back');
      $(this.trigger_node).find('a.js-dropdown-trigger').attr('aria-expanded', false);
      this.is_visible = false;
    },

    open: function() {
      this.dropdown_node.style.display = 'block';
      this.trigger_node.className += ' white_back';
      $(this.trigger_node).find('a.js-dropdown-trigger').attr('aria-expanded', true);
      this.is_visible = true;
    },

    toggle: function() {
      if (this.is_visible) {
        this.close();
      }
      else {
        this.open();
      }
    }
  };

  return {
    init: function(config) {
      dropdown_selector = config.dropdown_selector;
      orientation_selector = config.orientation_selector;
      identifier = config.identifier;

      $(config.trigger_selector).each(function() {
        const dropdown = new DropDown({
          trigger_node: this,
          id: $(this).attr('data-dropdown-id'),
          orientation: $(this).attr('data-dropdown-orientation')
        });

        if (dropdown.id) {
          dropdown.init();
          dropdowns.push(dropdown);
        }
      });
    }
  };
});
