define([], function() {
  function PopupGame(game_data, game_id) {
    this.game_id = game_id;
    this.game_data = game_data;
    this.orientation = game_data[0].orientation;
    this.question = game_data[0].question;
    this.popups = game_data[0].popups;
    this.prompts = new PromptBar(this, game_data[0].prompts);
    this.bgImage = game_data[0].bg_image;
    this.view = $(`#interaction_${game_id}`);
  }

  PopupGame.prototype = {
    build: function() {
      let popup;

      renderBackgroundImage(this.view, this.bgImage);
      renderQuestion(this.game_id, this.question, this.view);

      for (let i = 0; i < 3; i++) {
        popup = this.popups[0][`info_popup_${i}`];

        if (popup.text !== '') {
          renderPopup(i, popup.top, popup.left, popup.text, this.view);
        }
      }

      this.prompts.render();

      renderIpDialog(this.view);

      $(document).on('click', '.info_prompt_btn', function(e) {
        $('.info_popup').hide();
        $(`#ip_text_${e.target.id}`).show();
      });
    }
  };

  function PromptBar(game, prompts) {
    // class is `.horizontal_prompt_bar` or `.vertical_prompt_bar`
    this.view = $('<div>').addClass(`${game.orientation}_prompt_bar`);
    this.parent_node = $(`#interaction_${game.game_id}`);
    this.init(prompts);
  }

  PromptBar.prototype = {
    promptView: function(id, top, left, text) {
      return $('<div>').addClass('info_prompt')
        .attr('id', `interaction_text_${id}`)
        .text(text)
        .css({
          top: top,
          left: left
        })
        .append(this.promptButton(id));
    },
    promptButton: function(id) {
      return $('<div>').addClass('info_prompt_btn')
        .attr('id', id)
        .text('Find Out!');
    },
    build: function(prompts) {
      for (let i = 0; i < 3; i++) {
        const prompt = prompts[0][`textbox_${i}`];

        const prompt_left = 30 + (303 * i);

        if (prompt.text !== '') {
          this.view.append(this.promptView(i, 20, prompt_left, prompt.text));
        }
      }
    },
    render: function() {
      this.parent_node.append(this.view);
    },
    init: function(prompts) {
      this.build(prompts);
    }
  };

  function questionView(id, question) {
    return $('<div>')
      .addClass('interaction_question')
      .attr('id', id)
      .text(question)
      .css({ display: 'none' });
  }

  function renderQuestion(id, question, node) {
    node.append(questionView(id, question));
  }

  function bgImageView(image) {
    return $('<div>')
      .addClass('ip_interaction_content')
      .css('background-image', `url(${image})`);
  }

  function renderBackgroundImage(node, image) {
    node.append(bgImageView(image));
  }

  function popupView(id, top, left, text) {
    return $('<div>').addClass('info_popup')
      .attr('id', `ip_text_${id}`)
      .text(text)
      .css({
        top: parseInt(top, 10),
        left: parseInt(left, 10),
        position: 'absolute',
        display: 'none'
      });
  }


  function renderPopup(id, top, left, text, node) {
    node.append(popupView(id, top, left, text));
  }

  function renderIpDialog(node) {
    node.append('<div class="it_dialog_box"></div>');
  }

  return PopupGame;
});
