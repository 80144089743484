export default class CheckboxShowHide {
  constructor(config) {
    this.trigger = config.trigger;
    this.dependent = config.dependent;
  }

  init() {
    this._setupListeners();
  }

  _setupListeners() {
    $(this.trigger).on('change', (e) => {
      if (e.target.checked === true) {
        this.dependent.style.display = 'block';
      }
      else {
        this.dependent.style.display = 'none';
      }
    })
  }
}