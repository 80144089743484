define [], ->
  init = () ->
    $('.score_details, .close_box').on('click', ->
      if $('.score_details').hasClass('help_open')
        $('.score_breakdown').addClass('hidden')
        $('.score_details').removeClass('help_open')
      else
        $('.score_breakdown').removeClass('hidden')
        $('.score_details').addClass('help_open')
    )

    $('.help_icon, .close_box').on('click', ->
      if $('.help_icon').hasClass('help_open')
        $('.score_breakdown').addClass('hide')
        $('.help_icon').removeClass('help_open')
      else
        $('.score_breakdown').removeClass('hide')
        $('.help_icon').addClass('help_open')
    )

    $('.static_teacher_link, .close_box_teach').on('click', ->
      if $('.static_teacher_link').hasClass('help_open')
        $('.teacher_link_warning').addClass('hide')
        $('.static_teacher_link').removeClass('help_open')
      else
        $('.teacher_link_warning').removeClass('hide')
        $('.static_teacher_link').addClass('help_open')
    )

    $('.help_icon_score, .close_box_score').on('click', ->
      if $('.help_icon_score').hasClass('help_open')
        $('.help_icon_score').removeClass('help_open')
      else
        $('.help_icon_score').addClass('help_open')
    )

    $('#assessment_completed_bubble, .close_box_warning').on('click', ->
      if $('#assessment_completed_message').hasClass('message_open')
        $('#assessment_completed_message').addClass('hide')
        $('#assessment_completed_message').removeClass('message_open')
      else
        $('#assessment_completed_message').removeClass('hide')
        $('#assessment_completed_message').addClass('message_open')
    )

    $('.help_icon_guide, .close_box_guide').on('click', ->
      if $('.help_icon_guide').hasClass('help_open')
        $('.study_guide_breakdown').addClass('hide')
        $('.help_icon_guide').removeClass('help_open')
      else
        $('.study_guide_breakdown').removeClass('hide')
        $('.help_icon_guide').addClass('help_open')
    )

    $('#batch_upload_more, .close_box').on('click', ->
      if $('#batch_upload_more').hasClass('help_open')
        $('#batch_upload_info').addClass('hide')
        $('#batch_upload_more').removeClass('help_open')
      else
        $('#batch_upload_info').removeClass('hide')
        $('#batch_upload_more').addClass('help_open')
    )

    $('#batch_update_more, .close_box').on('click', ->
      if $('#batch_update_more').hasClass('help_open')
        $('#batch_update_info').addClass('hide')
        $('#batch_update_more').removeClass('help_open')
      else
        $('#batch_update_info').removeClass('hide')
        $('#batch_update_more').addClass('help_open')
    )

    $('.cal_legend_popup_close, .cal_legend_help').on('click', ->
      current_legend = $(this).closest('div.calendar_legend')

      if $(current_legend).hasClass('popup_open')
        $(current_legend).find('div').eq(0).fadeOut(100, ->
          $(current_legend).find('div').eq(0).addClass('hide')
        )
        $(current_legend).removeClass('popup_open')
      else
        $(current_legend).find('div').eq(0).fadeIn(100, ->
          $(current_legend).find('div').eq(0).removeClass('hide')
        )
        $(current_legend).addClass('popup_open')
    )

  init: init
