define([], function() {
  /**
   * Base Object for an ImagePopup feature
   * This implementation includes a maximized image area and
   * caption area. Zoom functionality should be built into this
   * base class, as that is going to be case specific.
   *
   * @param config
   * @constructor
   */

  function ImagePopup(config) {
    this.overlay = config.overlay || false;
    this.has_larger_version = false;
    this.large_width = 0;
    this.large_height = 0;
    this.image_dimensions_set = false;
    this.image_is_loaded = false;
    this.div_id = config.div_id;
    this.div_obj = config.div_obj;
    this.type = config.type;
    this.zoom_url = config.zoom_url;
  }

  ImagePopup.prototype = {

    /**
     *  Basic initialization to create the
     *  large version of the image (to maximize
     *  resolution)
     */
    init: function() {
      let delay = $('#delay'); if (delay) delay.remove();

      this._setZoomUrl();
      this._createZoomedImage();
    },

    /**
     * Grabs the url to be used as the src for the large res
     * image in the popup
     *
     * @returns {*}
     * @private
     */
    _setZoomUrl: function() {
      if (this.zoom_url == undefined) {
        var passed_url = $(this.div_obj + " a.zoomed_url").attr('href');

        if (passed_url) {
          this.zoom_url = passed_url;
          return passed_url;
        }
        else {
          this.zoom_url = $(this.div_obj + " img").attr('data-magnified-url');
          return false;
        }
      }
    },

    /**
     * Sets up deferred/promise resolution to occur when
     * the large res image has fully loaded, at which point
     * image dimensions can be measured and set in the object
     *
     * @returns [image promise]
     * @private
     */
    _createZoomedImage: function() {
      var dfd = $.Deferred(),
        this_obj = this,
        image = document.createElement('img');

      image.onload = function() {
        dfd.resolve();
      };

      image.src = this.zoom_url;

      dfd.done(function() {
        document.body.appendChild(image);
        this_obj._setImageDimensions(image);
      });

      return dfd.promise();
    },

    /**
     * Measures the given image for width/height and factors in
     * whether there is a larger version or if the zoom factor is used.
     * Also cleans up the DOM by removing the image after measurement
     *
     * @param image
     * @private
     */
    _setImageDimensions: function(image) {
      this.large_height = this._getImageHeight();
      this.large_width = this._getImageWidth();

      // If there is a larger version than what is present on the page, use its
      // dimensions. If there is no larger res version available, use the
      // ZOOM_FACTOR to dictate image size (scaled by CSS)
      this.large_width = Math.round(this.large_width * (this._hasLargerVersion() ? 1 : this.ZOOM_FACTOR));
      this.large_height = Math.round(this.large_height * (this._hasLargerVersion() ? 1 : this.ZOOM_FACTOR));
      document.body.removeChild(image);
    },

    /**
     * Returns the large image's height
     *
     * @returns {*|jQuery}
     * @private
     */
    _getImageHeight: function() {
      return this.large_height = $('img[src="' + this.zoom_url +'"]:visible').height();
    },

    /**
     * Returns the large image's width
     *
     * @returns {*|jQuery}
     * @private
     */
    _getImageWidth: function() {
      return this.large_width = $('img[src="' + this.zoom_url +'"]:visible').width();
    },

    /**
     * Returns true if the associated 'large image' is actually larger than the
     * default image.
     *
     * @returns {boolean}
     * @private
     */
    _hasLargerVersion: function() {
      return (this.large_width > parseInt($(this.div_obj).find('img').width(), 10) && this.large_height > parseInt($(this.div_obj).find('img').height(), 10));
    }
  };

  return ImagePopup
});

