define(['TCIUtils'], function(TCIUtils) {
  function buildCustomDragDropGame(game_data, game_id) {
    var content_block = '',
      game_obj = game_data[0],
      game_question = game_obj.question,
      game_images = game_obj.images,
      images = [],
      counter,
      zones_arr = [],
      ids,
      image;

    for(var i = 0; i < game_images.length; i++){
      game_images[i].zones = game_images[i].zones;
    }

    content_block += "<div class='cdnd_question interaction_question'>" + game_question + "</div>";

    counter = game_images.length;

    content_block += "<div class='cdnd_left_side'><div id='image_container_" + game_id + "' class='img_origin dd_origin'>";

    for (var i = 0; i < counter; i++) {
      if (game_images[i].image != null) {
        zones_arr = game_images[i].zones;

        // Grab zones string, and convert into a string of classes 'id_X'
        ids = zones_arr.split(',').join(' id_');

        image = "<img class='dd_answer dd_answer_js dd_answer_img cdnd_image id_" + ids + "' src='" + game_images[i].image + "' />";
        images.push(image);
      }
    }

    images = TCIUtils.fisherYatesShuffle(images);
    for (var i = 0; i < images.length; i++) {
      content_block += images[i];
    }

//  content_block += "</div><div id='label_container_" + game_id + "' class='text_origin dd_origin'>";

    content_block += "</div>" +
    "<a href='##' class='check_answer cdnd_check_answer dd_check_answers'>Check My Answers</a>" +
    "<a href='##' class='cdnd_reset_answer dd_reset_answers'>Reset</a></div>";

    content_block += "<div class='cdnd_right_side'><img class='cdnd_background_image' src='" + game_obj.background + "' />";

    for (var i = 0; i < counter; i++) {
      if (game_images[i].image && game_images[i].top && game_images[i].left) {
        content_block +=
          "<div class='cdnd_dropzone_container container_" + i + "' style='top:" + game_images[i].top + "px; left:" + game_images[i].left + "px;'>" +
          "<div class='dd_dropzone id_" + (i + 1) + " dd_answer_img'></div>" +
          "</div>"
      }
    }

    content_block += "</div>";
    content_block += "<div class='it_dialog_box'></div>";

    $('#interaction_' + game_id).html(content_block);
  }

  return {
    build: buildCustomDragDropGame
  }
});