/**
 *  Currently built for the Media Library Alt Tag
 *
 */

export default class LivePreview {
  constructor(config) {
    this.output_el = config.output_el;
    this.description_input = config.description_input;
    this.title_input = config.title_input;
    this.caption_input = config.caption_input;
  }

  init() {
    this._setupListeners();
  }

  reinit(els) {
    this._destroyListeners();
    this.output_el = els.output_el || this.output_el;
    this.description_input = els.description_input || this.description_input;
    this.title_input = els.title_input || this.title_input;
    this.caption_input = els.caption_input || this.caption_input;
  }

  _setupListeners() {
    $([this.description_input, this.title_input, this.caption_input]).on('change keyup paste', () => {
      this.output_el.innerHTML = this.buildAltTag();
    });
  }

  _destroyListeners() {
    $([this.description_input, this.title_input, this.caption_input]).off('change');
  }

  buildAltTag() {
    if ($(this.caption_input).val()) {
      return $(this.caption_input).val();
    }
    else if ($(this.description_input).val()) {
      return $(this.description_input).val();
    }
    else if ($(this.title_input).val()) {
      return $(this.title_input).val();
    }
    return '';
  }
}
