define([], function() {

  function ConfirmationField(config) {
    this.form = config.form;
    this.field = config.field;
    this.message = config.field.dataset.confirmMessage;
    this.confirmWith = config.confirmWith;
  }

  ConfirmationField.prototype = {
    init: function() {
      this._buildErrorMessage();
      this._setupListeners();
    },

    _buildErrorMessage: function() {
      this.errorMessage = document.createElement('div');
      this.errorMessage.classList.add('red');
      this.errorMessage.textContent = this.message;
      this.errorMessage.style.display = 'none';
      $(this.confirmWith).after(this.errorMessage);
    },

    _setupListeners: function() {
      $(this.form).on('submit', function() {
        var field = $(this.field).val();
        var confirmation = $(this.confirmWith).val();
        if (field !== confirmation) {
          $(this.errorMessage).stop().show().fadeOut(4000);
          return false;
        }
      }.bind(this));
    }
  };
  return ConfirmationField;
});