define(['ImagePreloader', 'Magnifier','StudentZoom', 'TCIUtils', 'ImagePopup'], function(ImagePreloader, Magnifier, StudentZoom, TCIUtils, ImagePopup) {
  function StudentTextImagePopup(config) {
    this.parent = config.parent;
    this.id = config.id;
    this.TEXT_PADDING = config.text_padding || 10;
    this.image_details = { width: 300 };
    this.width = 400;   // Track width and height as part of the object for calculations
    this.height = 400;  // related to sizing and centering
    this.images_loaded = false;
  }

  StudentTextImagePopup.prototype = {
    init: function() {
      this._buildContainer();
      this._setupOverlay();
    },

    _buildContainer: function() {
      this.container = document.createElement('div');
      this.container.className = 'student_text_image_popup simple_overlay';
      document.body.appendChild(this.container);

      this.close_button = document.createElement('div');
      this.close_button.className = 'close';
      this.container.appendChild(this.close_button);

      $(this.close_button).on('click', function() {
        this.close();
      }.bind(this));
    },

    open: function() {
      this._showOverlay();

      if (!this.images_loaded) {
        this.center();
        this._buildImageBox();
        this._loadImages();
      }
      else {
        if (this.parent.zoom_type === 'magnifier'){
          this.resize();
          this.magnifier.setUpListeners();
        } else if (this.parent.zoom_type === 'in/out buttons'){
          this.resize();
          this.magnifier.reset();
        } else {
          this.resizeSlidebox();
        }
        this.container.style.display = 'block';
      }
      // does this make sense? should images_loaded be
      // populated by image loader promises?
      this.images_loaded = true;
    },

    _buildSpinner: function() {
      this.spinner = document.createElement('div');
      this.spinner.className = 'spinner-container';
      this.spinner.appendChild(TCIUtils.spinner());
      this.container.appendChild(this.spinner);
    },

    _buildImageBox: function() {
      this.image_box = document.createElement('div');
      this.image_box.className = 'image_div';
      this.image_box.style.width = '400px';
      this.image_box.style.height = '350px';
      // this.image_box.style.backgroundColor = '#eeeeee';

      this.text_box = document.createElement('div');
      this.text_box.className = 'photo_info';

      this.credits_box = document.createElement('div');
      this.credits_box.className = 'credits_overlay';
      $(this.credits_box).html(this.parent.credit);

      this.captions_box = document.createElement('div');
      this.captions_box.className = 'caption_overlay hide';
      $(this.captions_box).html(this.parent.caption);

      this.text_box.appendChild(this.captions_box);
      this.text_box.appendChild(this.credits_box);
      this.container.appendChild(this.image_box);

      this._buildSpinner();

      if(this.parent.caption === "" && this.parent.credit === "") {
        this.image_details.width = 0;
      }
      else {
        this.container.appendChild(this.text_box);
      }

      this.container.style.display = 'block';
    },

    _setupOverlay: function() {
      if (!this.overlay) {
        this.overlay = document.createElement('div');
        this.overlay.classList.add('overlay_background');

        // Make the overlay the parent of the container element:
        this.container.parentElement.insertBefore(this.overlay, this.container);
        this.overlay.appendChild(this.container);
      }

      $(this.overlay).on('click', (e) => {
        if (e.target === this.overlay) {
          this.close();
        }
      });
    },

    _showOverlay: function() {
      if (this.overlay) {
        $(this.overlay).addClass('visible');
      }
    },

    _hideOverlay: function() {
      if (this.overlay) {
        $(this.overlay).removeClass('visible');
      }
    },

    _loadImages: function() {
      let this_obj = this,
          images_promise;
      // return images as an (promise) array of promises
      images_promise = ImagePreloader([this.parent.large_src, this.parent.original_src]);
      $(this.spinner).removeClass('hide_important');

      const altText = this.parent.container.getAttribute('data-student-text-alt');

      // When promise array resolves, work with images
      images_promise.done(function() {
        // we actually want to use xlarge instead of large
        // don't want to go through change all instances of this,
        // so just setting large_image to be the same as original_image
        this_obj.large_image = this[1];
        this_obj.original_image = this[1];
        this_obj.large_image.setAttribute('alt', altText);
        this_obj.original_image.setAttribute('alt', altText);

        // Use image loader div (off screen) to get height/width and resize container
        $(this_obj.parent.image_loader).html(this_obj.large_image);
        this_obj.parent.image_loader.appendChild(this_obj.original_image);
        this_obj.original_image_height = $(this_obj.original_image).outerHeight();
        this_obj.resize();
        this_obj.image_box.appendChild(this_obj.large_image);

        if (window.navigator.platform !== 'iPad' && this_obj.parent.zoom_type === 'magnifier') {
          this_obj.magnifier_container = document.createElement('div');
          this_obj.magnifier_container.className = 'magnified_image';
          this_obj.magnifier = new Magnifier({
            type: 'section',
            div_obj: this_obj.container,
            zoom_url: this_obj.parent.original_src
          });

          this_obj.magnifier.init();
          $(this_obj.image_box).addClass('magnifier');
        }

        else if (window.navigator.platform !== 'iPad' && this_obj.parent.zoom_type === 'in/out buttons') {
            this_obj.magnifier_container = document.createElement('div');
            this_obj.magnifier_container.className = 'magnified_image';
            this_obj.magnifier = new StudentZoom({
                image_box: this_obj.image_box,
                zoom_url: this_obj.parent.original_src
            });

            this_obj.magnifier.init();
            $(this_obj.image_box).addClass('magnifier');
        }
        else if (this_obj.parent.zoom_type === 'sliders') {
          this_obj.resizeSlidebox();
          $(this_obj.image_box).html(this_obj.original_image);
          this_obj.image_box.style.overflow = 'scroll';

        }
        else {
          this_obj.image_popup = new ImagePopup({
            type: 'section',
            div_obj: this_obj.container,
            zoom_url: this_obj.parent.original_src
          });

          this_obj.image_popup.init();
          $(this_obj.image_box).addClass('magnifier');
        }

        // Will need to work with the original image for magnifier
        $(this_obj.spinner).addClass('hide_important');

        // Reveal caption overlay
        $(this_obj.captions_box).removeClass('hide');
      });
    },

    center: function() {
      this.container.style.left = ($(window).width() - this.width) / 2 + "px";
      this.container.style.top = (($(window).height()) - this.height) / 2 + "px";
    },

    close: function() {
      this.container.style.display = 'none';
      this._hideOverlay();
    },

    resize: function() {
      if (this.large_image_width === undefined) {
        this.large_image_width = $(this.large_image).outerWidth();
      }
      if (this.large_image_height === undefined) {
        this.large_image_height = $(this.large_image).outerHeight();
      }

      let image_aspect = this.large_image_width / this.large_image_height,
          image_aspect_with_side = this.large_image_width / this.large_image_height,
          window_aspect = ($(window).width() - this.image_details.width) / $(window).height();

      // image more widescreen than window
      if (image_aspect_with_side > window_aspect) {
        // image is wider than the window
        if ((this.large_image_width + this.image_details.width + 30) > $(window).width()) {
          this.width = $(window).width() - 30;

          this.height = (this.width - this.image_details.width)/ image_aspect;
        }
        else {
          this.width = this.large_image_width + this.image_details.width;
          this.height = this.large_image_width / image_aspect;
        }
        this.container.style.width = this.width + 'px';
        this.image_box.style.width = this.width - this.image_details.width + 'px';
        this.image_box.style.height = this.height + 'px';
        this.container.style.height = this.height + 'px';
      }
      // image less widescreen than window
      else {
        // image is taller than the window
        if ((this.large_image_height + 30) > $(window).height()) {
          this.height = $(window).height() - 30;
          this.width = this.height * image_aspect + this.image_details.width;
        }
        else {
          this.height = this.large_image_height;
          this.width = this.large_image_height * image_aspect + this.image_details.width;
        }
        this.container.style.height = this.height + 'px';
        this.image_box.style.height = this.height + 'px';

        this.image_box.style.width = this.width - this.image_details.width + 'px';
        this.container.style.width = this.width + 'px';
      }

      this.text_box.style.height = this.height - this.TEXT_PADDING * 2 + 'px';
      this.center();
    },

    resizeSlidebox: function() {
      var max_width,
        max_height,
        img_width,
        img_height,
        aspect_ratio,
        WINDOW_PADDING = 20;

      max_width = $(window).outerWidth() - WINDOW_PADDING * 2 - this.image_details.width;
      max_height = $(window).outerHeight() - WINDOW_PADDING * 2;
      if (this.original_image_width === undefined) {
        this.original_image_width = $(this.original_image).outerWidth();
      }
      if (this.original_image_height === undefined) {
        this.original_image_height = $(this.original_image).outerHeight();
      }
      img_width = this.original_image_width;
      img_height = this.original_image_height;
      aspect_ratio = img_height / img_width;

      //aspect ratio

      // Maximize popup width for image size
      if ((img_width > max_width) || ((img_width + WINDOW_PADDING * 2) > max_width)) {
        this.width = max_width + 2;
      }
      else {
        this.width = img_width + this.image_details.width + 2;
      }

      if ((img_height > max_height) || ((img_height + WINDOW_PADDING * 2) > max_height)) {
        this.height = max_height + 2;
      }
      else {
        this.height = img_height + 2;
      }

      this.container.style.width = this.width + 'px';
      this.image_box.style.width = this.width - this.image_details.width + 'px';

      this.container.style.height = this.height + 'px';
      this.text_box.style.height = this.height - this.TEXT_PADDING * 2 + 'px';
      this.image_box.style.height = this.height + 'px';
      this.center();
    }
  };

  return StudentTextImagePopup;
});
