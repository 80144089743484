define([], function() {
    function setConsumerKey() {
        if (!($('#lti_key_secret').val().length > 0)){
            generate_consumer_secret();
        }
    }

    function generate_consumer_secret(){
        var url = $('#regenerate_secret').attr('data_url');
        $.get(url, function( data ) {
            $('#lti_key_secret').val(data['key']);
        });
    }

    function setupListener(){
        $('#regenerate_secret').click(function(e) {
            e.preventDefault();
            generate_consumer_secret();

        })
    }



    return {
        init: function() {
            setConsumerKey();
            setupListener();
        }

    }
});