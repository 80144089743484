define([], function() {
  function ImageCheckboxGame(parent_id) {
    this.parent_id = parent_id;
    this.iOS = false;

    this.iOS = /(iPad|iPhone|iPod)/g.test( navigator.userAgent );
  }

  ImageCheckboxGame.prototype = {
    init: function(correct) {

      // Event Listeners
      var this_obj = this;

      if (!this.iOS) {
        $('.ic_answer').addClass('not_ios');
      }

      // Selecting answer(s)
      $('.ic_answer').on("click", function () {

        // Reset checked answers on click
        $(this).removeClass('ic_correct ic_incorrect');

        // Deselection
        if ($(this).hasClass('ic_selected')) {
          $(this).removeClass('ic_selected');
        }
        // Select if you haven't reached maximum selected
        else if($('#' + this_obj.parent_id + ' .ic_selected').length < correct) {
          $(this).addClass('ic_selected');
        }
        // Show warning if you've reach maximum
        else {
          $('#' + this_obj.parent_id + ' .it_dialog_box').text('You cannot select more answers.')
            .show()
            .delay(2500)
            .fadeOut(100);
        }
      });

      $('.ic_check_answer').on("click", function () {
        var $selected = $('#' + this_obj.parent_id + ' .ic_selected');

        if ($selected.length) {
          $selected.each(function() {
            if ($(this).attr('data-correct') == "true") {
              $(this).addClass('ic_correct');
            }
            else {
              $(this).addClass('ic_incorrect');
            }
          });
        }
        else {
          $('#' + this_obj.parent_id + ' .it_dialog_box')
            .text('Please select your answers.')
            .show()
            .delay(2000)
            .fadeOut(100)
        }
      });
    }
  };

  return ImageCheckboxGame
});