define(['Interactions/DragDropGame', 'SlideShow/slideShowGame'], function(DragDropGame, slideShowGame) {
  function fetch_json(url) {
    var json_data;
    $.getJSON(url, function (data) {
        json_data = data;
    })
    .done(function () {
      start_game(json_data);
      window.call_waiting--;
    });
  }

  function parseIdent(dd_class) {
    var ident,
        id_regex,
        class_arr;

    id_regex = /id_(\d*)/;
    class_arr = [];

    class_arr = dd_class.split(' ');
    for (var i = 0; i < class_arr.length; i++) {
      if (class_arr[i].match(id_regex)) {
        ident = class_arr[i].match(id_regex)[1];
      }
    }
    return ident;
  }

  function start_game(data) {
    var game_type = data.type,
      game_id = data.id,
      game_data = data.data,
      game,
      config;
    switch (game_type) {
      case "Vocab":
        var buildVocabGame = require('GameSetups/buildVocabGame');
        buildVocabGame.build(game_data, game_id);
        $('#interaction_' + game_id).addClass(game_type);
        game = DragDropGame.create('interaction_' + game_id);
        game.init();
        slideShowGame.slideShowGame();
        break;
      case "Paragraph":
        var buildParagraphGame = require('GameSetups/buildParagraphGame');
        buildParagraphGame.build(game_data, game_id);
        $('#interaction_' + game_id).addClass(game_type);
        game = DragDropGame.create('interaction_' + game_id);
        game.init();
        slideShowGame.slideShowGame();
        break;
      case "DropdownParagraph":
        var buildDropdownParagraphGame = require('GameSetups/buildDropdownParagraphGame')
        var SimpleGame = require('Interactions/SimpleGame');
        var terms = buildDropdownParagraphGame.build(game_data, game_id);
        $('#interaction_' + game_id).addClass(game_type);
        game = new SimpleGame('interaction_' + game_id);
        game.init(terms);
        slideShowGame.slideShowGame();
        break;
      case "MultipleChoice":
        $('#interaction_' + game_id).addClass(game_type);
        config = {
          parent_id: 'interaction_' + game_id
        };
        var buildMcGame = require('GameSetups/buildMcGame');
        var MultipleChoiceGame = require('interactions/MultipleChoiceGame');
        config.number_of_answers = buildMcGame.build(game_data, game_id).number_of_answers;
        game = new MultipleChoiceGame(config);
        game.init();
        break;
      case "LifeCycle":
        var LifecycleGame = require('GameSetups/LifecycleGame');
        LifecycleGame.build(game_data, game_id);
        $('#interaction_' + game_id).addClass(game_type);
        game = DragDropGame.create('interaction_' + game_id, 2);
        game.init();
        slideShowGame.slideShowGame(true);
        break;
      case "MultipleImageChoice":
        $('#interaction_' + game_id).addClass(game_type);
        config = {
          parent_id: 'interaction_' + game_id
        };
        var buildMultipleImageChoiceGame = require('GameSetups/buildMultipleImageChoiceGame');
        var MultipleImageChoiceGame = require('Interactions/MultipleImageChoiceGame');
        config.number_of_answers = buildMultipleImageChoiceGame.build(game_data, game_id).number_of_answers;
        game = new MultipleImageChoiceGame(config);
        game.init();
        break;
      case "Hotspot":
        var buildHotspotGame = require('GameSetups/buildHotspotGame');
        var hotspot = require('Interactions/InteractiveHotSpot');
        $('#interaction_' + game_id).addClass(game_type);
        buildHotspotGame.build(game_data, game_id);
        hotspot.init("interactive", $('#interaction_' + game_id));
        break;
      case "InfoPopup":
        $('#interaction_' + game_id).addClass(game_type);
        var PopupGame = require('GameSetups/PopupGame');
        var popup_game = new PopupGame(game_data, game_id);
        popup_game.build();
        break;
      case "CustomDragdrop":
        var buildCustomDragDropGame = require('GameSetups/buildCustomDragDropGame');
        buildCustomDragDropGame.build(game_data, game_id);
        $('#interaction_' + game_id).addClass(game_type);
        game = DragDropGame.create('interaction_' + game_id);
        game.init();
        slideShowGame.slideShowGame(true);
        break;
      case "CustomDragdropText":
        var buildCustomDragDropTextGame = require('GameSetups/buildCustomDragDropTextGame');
        buildCustomDragDropTextGame.build(game_data, game_id);
        $('#interaction_' + game_id).addClass(game_type);
        game = DragDropGame.create('interaction_' + game_id);
        game.init();
        slideShowGame.slideShowGame();
        break;
      case "ImageCheckbox":
        var buildImageCheckboxGame = require('GameSetups/buildImageCheckboxGame');
        var ImageCheckboxGame = require('Interactions/ImageCheckboxGame');
        var correct = buildImageCheckboxGame.build(game_data, game_id);
        $('#interaction_' + game_id).addClass(game_type);
        game = new ImageCheckboxGame('interaction_' + game_id);
        game.init(correct);
        break;
      case "NoQuestion":
        var buildNoQuestion = require('GameSetups/buildNoQuestion');
        buildNoQuestion.build(game_data, game_id);
        $('#interaction_' + game_id).addClass(game_type);
        break;
      default:
        console.log('you failed, here you go', game_type);
        break;
    }
  }

  function build_interaction_slider() {
    $('.interactive').each(function () {
      $(this).appendTo($('#interaction_content_container'));
    });
    var first_interactive = $('.interactive')[0];
    var first_id = $(first_interactive).attr('id');
    var first_question = $('#' + first_id + ' .interaction_question').html();
    var num_of_interactions = $('.interactive').length;

    $('#interaction_question_show').html(first_question);
    if (num_of_interactions == 1) {
      $('#interaction_slider_controls').hide();
    }
    $('#interaction_number').text("1 of " + num_of_interactions);
    $('#interaction_question_container').show();
    $('.interactive').eq(0).addClass('active_interaction').show();

    set_up_slider_logic();
  }

  function set_up_slider_logic() {
    var num_of_interactions = $('.interactive').length;

    $('i.fa-arrow-right').on("click", function () {
      if ($('.active_interaction').index() <= num_of_interactions - 1) {
        var index = $('.active_interaction').index('.interactive');

        change_frame("left", index + 1, num_of_interactions);
      }
    });

    $('i.fa-arrow-left').on("click", function () {
      if ($('.active_interaction').index() > 1) {
        var index = $('.active_interaction').index();

        change_frame("right", index - 2, num_of_interactions);
      }
    });
  }

  function change_frame(direction, index, num_of_interactions) {
    var current_interactive,
      current_id,
      current_question,
      opposite = 'left';

    if (direction == 'left') { opposite = 'right' }

    $('.active_interaction').stop(true, true).hide('slide', {direction: direction}, 500);
    $('.interactive').eq(index).stop(true, true).show('slide', {direction: opposite}, 500);
    $('.active_interaction').removeClass('active_interaction');
    $('.interactive').eq(index).addClass('active_interaction');
    $('#interaction_number').text((index + 1) + " of " + num_of_interactions);

    current_interactive = $('.interactive')[index];
    current_id = $(current_interactive).attr('id');
    current_question = $('#' + current_id + ' .interaction_question').html();
    $('#interaction_question_show').html(current_question);
  }

  return {
    init: function() {
      window.call_waiting = 0;

      if ($('.interactive').length > 0) {
        var interactions = $('.interactive');
        for (var i = 0; i < interactions.length; i++) {
          var url = $(interactions[i]).data('url');
          fetch_json(url + ".json");
          window.call_waiting++;
        }

        var build_timeout = window.setInterval(function () {
            if (window.call_waiting == 0) {
              build_interaction_slider();
              $('#interaction_spinner').hide();
              window.clearInterval(build_timeout);
            }
          }, 10
        );
      }
    },
    parseIdent: parseIdent
  }
});


