define(['TCIUtils'], function(TCIUtils) {
  function buildDropdownParagraphGame(game_data, game_id) {
    var image = game_data[1].images;
    var text = game_data[0].text;
    var extra_words = [];
    game_data[3].extra_words ? extra_words = game_data[3].extra_words.match(/\[(.*?)\]/g) : extra_words; // to account for pre-existing tutorials and NOT passing extra words
    var answer_words = text.match(/\[(.*?)\]/g);
    var all_words = answer_words.concat(extra_words);
    var options = '<option value="" disabled selected >Choose a term</option>';
    var content_block = '';
    var answers = [];
    var shuffled_words = TCIUtils.fisherYatesShuffle(all_words);
    var video = game_data[4].video;


    for (var i = 0; i < all_words.length; i++) {
      shuffled_words[i] = WordStripper(shuffled_words[i]);
      options += toOptionTag(shuffled_words[i]);
    }

    // Replace [term]s with correctable <select> HTML
    text = text.replace(/\[(.*?)\]/g, '<div class="sg_correctable id_FIX_ME" >' +
    '<select>' + options + '</select>' +
    '<div class="wrong_icon"><i class="fa fa-times-circle"></i></div>' +
    '<div class="right_icon"><i class="fa fa-check-circle"></i></div>' +
    '</div>');


    content_block += '<div class="interaction_question hide">Select the appropriate term for each blank.</div>';

    content_block += '<div class="ddp_main">';

    if (image != null) {
      content_block += '<div class="ddp_image"><img src="' + image + '"/></div>';
    }
    else if (video && video[0] === "aws_video") {
      content_block += '<div class="vit_video">' +
      '<div id="video_player" class="ml215 mb10">' +
      '<video preload="metadata" src="' + video[1] + '"' + 'class="aws_video" width="498" height="275"></video>' +
      '</div>';
    }
    else if (video && video[0] === "vimeo_id") {
      content_block += '<div class="vit_video">' +
      '<div id="video_player">' +
      '<div class="embed_vimeo">' +
      '<iframe id="vimeo_iframe" class="ml215 mb10" src="https://player.vimeo.com/video/' +
      video[1] + '?fun=0" width= 498 height= 275 ' +
      ' frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>' +
      '</div>' +
      '</div></div>';
    }
    content_block += '<div class="ddp_text">' + text + '</div>';

    content_block += '<div class="clear ddp_user_ctrl">' +
    '<a href="##" class="check_answers">Check My Answers</a>' +
    '<a href="##" class="reset_answers">Reset</a>' +
    '</div>';

    content_block += '</div>';  // end ddp_main
    content_block += "<div class='it_dialog_box'></div>";

    $('#interaction_' + game_id).html(content_block);

    TCIUtils.initVideoPlayers();

    $('.id_FIX_ME').each(function (i) {
      $(this).removeClass('id_FIX_ME');
      $(this).addClass('id_' + i);
    });

    for (var i = 0; i < answer_words.length; i++) {
      answers.push(WordStripper(answer_words[i]));
    }
    return answers;
  }

// Remove square brackets from text
  function WordStripper(word) {
    return word.substring(1, word.length - 1);
  }

// Make <OPTION> from term
  function toOptionTag (term) {
    return "<option>" + term + "</option>";
  }

  return {
    build: buildDropdownParagraphGame
  }
});