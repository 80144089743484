define('TruncatedList', [], function() {
    function TruncatedList(config) {
      this.expand_text = config.expand_text || 'View more…';
      this.collapse_text = config.collapse_text || 'View less…';
      this.default_expanded = config.default_expanded || false;
      this.container = config.container;
      this.item_selector = config.item_selector;
      this.trigger = config.trigger;
      this.num_of_unaffected = config.num_of_unaffected === undefined ? 3 : config.num_of_unaffected;
      this.affected_items = [];
      this.rotate_icon = config.rotate_icon;
    }

    TruncatedList.prototype = {
      init: function() {
        this.expanded = this.default_expanded;

        if (typeof this.trigger === 'undefined') this._buildTrigger();

        $(this.container).find(this.item_selector).each((i, el) => {
          if (i >= this.num_of_unaffected) this.affected_items.push(el);
        });

        $(this.trigger).off()
          .on('click', () => this.expanded ? this.collapse() : this.expand());

        this.expanded ? this.expand() : this.collapse();
      },

      _buildTrigger: function() {
        this.trigger = document.createElement('span');
        this.trigger.style.cursor = 'pointer';
        // TODO: will the trigger always look the same?
        // if so, consider putting the styles into a CSS module
        this.trigger.className = 'js-truncated-list-trigger';
        this.trigger.innerText = this.default_expanded ? this.collapse_text : this.expand_text;
        this.container.appendChild(this.trigger);
      },

      expand: function() {
        $(this.affected_items).each((i, el) => $(el).show());
        this.expanded = true;
        this.trigger.innerHTML = this.collapse_text;
        this.toggleIcon();
      },

      collapse: function() {
        $(this.affected_items).each((i, el) => $(el).hide());
        this.expanded = false;
        this.trigger.innerHTML = this.expand_text;
        this.toggleIcon();
      },

      toggleIcon: function() {
        if (this.rotate_icon !== undefined)
          this.container.find(`.${this.rotate_icon}`).toggleClass('active');
      }
    };

    function initPage() {
      // To be used for globally initializing a page
    }

  return {
    create: TruncatedList,
    initPage: initPage
  }
});
