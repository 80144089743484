define([], function() {
  function ajaxFormSubmit() {
    $('.ajax_form_submit_js').on('click', function(e) {
      const form_id = $(this).attr('data-formid');
      const $form = $(`#${form_id}`);
      const default_url = $form.attr('action');
      const submit_url = $(this).attr('data-url');

      e.preventDefault();

      $form.attr('action', submit_url);
      $form.submit();
      $form.attr('action', default_url);
    });
  }

  return {
    init: function() {
      ajaxFormSubmit();
    }
  };
});
