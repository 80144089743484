define([], function() {
  function Select(config) {
    this.element = config.element;
  }

  Select.prototype = {
    init: function() {
      this.element.addEventListener('change', function() {
        var url = this.options[this.selectedIndex].value;

        if (url !== undefined) {
          window.location.href = url;
        }

      });
    }
  };

  return Select
});