define([], function() {
  function buildHotspotGame(game_data, game_id) {
    var content_block = '',
      game_obj = game_data[0],
      game_hotspots = game_obj.hotspots[0],
      game_textboxes = game_obj.textboxes[0],
      game_question = game_obj.question,
      game_image = game_obj.images[0];

    content_block += '<div class="interaction_question">' + game_question + '</div>';
    content_block += '<div class="hs_interaction_content" style="background-image: url(' + game_image + ')">';

    var index = 0;
    for (var obj in game_hotspots) {
      if (game_hotspots[obj].left) {
        var left = game_hotspots[obj].left - 15;
        var top = game_hotspots[obj].top - 15;

        content_block += '<div class="hs_trigger hs_pin hs_id_' + index + '" style="left: ';
        content_block += left + 'px; top: ' + top + 'px;"></div>';
      }
      index++;
    }

    index = 0;
    for (var obj in game_textboxes) {
      if (game_textboxes[obj].left) {
        var left = game_textboxes[obj].left;
        var top = game_textboxes[obj].top;
        var width = game_textboxes[obj].width || 20;

        content_block += '<div class="hs_info hs_text hs_id_' + index + '" style="left: ';
        content_block += left + 'px; top: ' + top + 'px; width:' + width + '%;">';
        content_block += game_textboxes[obj].text;
        content_block += '</div>';
      }
      index++;
    }

    content_block += '</div>';

    content_block += "<div class='it_dialog_box'></div>";
//  console.log(game_obj, game_hotspots, game_textboxes, game_question, game_image);


    $('#interaction_' + game_id).html(content_block);
  }

  return {
    build: buildHotspotGame
  }
});