define([], function() {
  function notebook() {
    $('.isn_expand_button').click(function() {
      const $notebook = $(`#notebook_${this.id}`);

      if ($notebook.is(':visible')) {
        $notebook.hide();
        $(this).addClass('isn_expand_button');
        $(this).removeClass('isn_collapse_button');
        $(this).html('Expand');
      }
      else {
        $notebook.show();
        $(this).addClass('isn_collapse_button');
        $(this).removeClass('isn_expand_button');
        $(this).html('Collapse');
      }
    });
  }

  function notebookShowAll() {
    if ($('ul.info_list').length > 0) {
      $('.expand_all').on('click', function(e) {
        e.preventDefault();

        const $isn_expand_button = $('.isn_expand_button');

        if ($(this).hasClass('active') === true) {
          $('.notebook_question').hide();
          $('.isn_collapse_button').addClass('isn_expand_button');
          $isn_expand_button.removeClass('isn_collapse_button');
          $isn_expand_button.html('Expand');
          $(this).html('Expand All').removeClass('pressed active');
        }
        else {
          $('.notebook_question').show();
          $('.isn_collapse_button').removeClass('isn_expand_button');
          $isn_expand_button.addClass('isn_collapse_button');
          $isn_expand_button.html('Collapse');
          $(this).html('Collapse All').addClass('pressed active');
        }
      });
    }
  }

  function textareaResize() {
    // This is run in the application pack making every text area that doesn't have the resizable
    // class to have the wrong height. We should reconsider which text areas need to be resizable.
    $('textarea').not('.resizable').autoResize({ minHeight: 70 });
  }

  function check_for_auto_number() {
    $('#auto_number_js').on('click', function() {
      console.log($(this).attr('data-url'));
      let checked = false;
      if ($(this).is(':checked')) checked = true;

      $.ajax({
        type: 'PATCH',
        url: $(this).data('url'),
        data: {
          notebook: {
            auto_numbering: checked
          }
        }
      })
        .fail(function() {
          const $flash = $('#flash');
          $flash
            .removeClass('notice')
            .addClass('alert')
            .html('<p>Failed to update Notebook automatic numbering.</p>');
          $flash
            .stop(true, true)
            .show()
            .delay(3000)
            .fadeOut(500);
        })
        .done(function() {
          const $flash = $('#flash');
          $flash
            .removeClass('alert')
            .addClass('notice')
            .html('<p>Successfully updated Notebook automatic numbering.</p>');
          $flash
            .stop(true, true)
            .show()
            .delay(3000)
            .fadeOut(500);
        });
    });
  }

  return {
    init: function() {
      textareaResize();
      notebook();
      notebookShowAll();

      if ($('#auto_number_js').length) check_for_auto_number();
    }
  };
});
