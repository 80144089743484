define([], function() {
  function no_text_to_speech() {
    $('#th_play').on('click', function () {
      alert('Please use Safari to access text-to-audio.');
    })
  }

  function enableVideoQuestion() {
    $('p.video_answer_save_button').on('click', function() {
      var button = $(this);
      var question_id = $(this).attr('id').split('edit_question_')[1];
      $(`#question_answer_${question_id}`).removeAttr('disabled');
      $(`<input type="submit" 
                value="Save Your Answer" 
                name="commit" 
                id="video_answer_save_button_${question_id}" 
                class="video_answer_save_button pos_fl mr10 mt5">`).insertAfter(button);
      button.remove();
      $(`#question_field_${question_id}`).removeClass('video_answer_field_answered').addClass('video_answer_field');
    });
  }

  return {
    init: function() {
      enableVideoQuestion();
      if ('standalone' in window.navigator && window.navigator.standalone) {
        no_text_to_speech();
      }
    }
  }
});
