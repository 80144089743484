define(['TCIUtils'], function(TCIUtils) {
  function buildCustomDragDropTextGame(game_data, game_id) {
    var content_block = '',
      game_obj = game_data[0],
      game_question = game_obj.question,
      game_texts = game_obj.texts,
      game_type = game_obj.game_type,
      texts = [],
      counter,
      zones_arr = [],
      ids,
      image;

    for(var i = 0; i < game_texts.length; i++){
      game_texts[i].zones = game_texts[i].zones;
    }

    content_block += "<div class='cdnd_question interaction_question'>" + game_question + "</div>";

    counter = game_texts.length;

    content_block += "<div class='cdnd_left_side'><div id='text_container_" + game_id + "' class='text_origin_type2 dd_origin'>";

    for (var i = 0; i < counter; i++) {
      if (game_texts[i].text) {
        zones_arr = game_texts[i].zones;

        // Grab zones string, and convert into a string of classes 'id_X'
        ids = zones_arr.split(',').join(' id_');

        text = "<div class='dd_answer dd_answer_js dd_answer_text_cdnd cdnd_text type_" + game_type + " id_" + ids + "'>" + game_texts[i].text + "</div>";
        texts.push(text);
      }
    }

    texts = TCIUtils.fisherYatesShuffle(texts);
    for (var i = 0; i < texts.length; i++) {
      content_block += texts[i];
    }

//  content_block += "</div><div id='label_container_" + game_id + "' class='text_origin dd_origin'>";

    content_block += "</div>" +
    "<a href='##' class='check_answer cdnd_check_answer dd_check_answers'>Check My Answers</a>" +
    "<a href='##' class='cdnd_reset_answer dd_reset_answers'>Reset</a></div>";

    content_block += "<div class='cdnd_right_side'><img class='cdnd_background_image' src='" + game_obj.background + "' />";

    for (var i = 0; i < counter; i++) {
      if (game_texts[i].text && game_texts[i].top && game_texts[i].left) {
        content_block +=
          "<div class='cdnd_text_dropzone_container container_" + i + "' style='top:" + game_texts[i].top + "px; left:" + game_texts[i].left + "px;'>" +
          "<div class='dd_dropzone type_" + game_type + " id_" + (i + 1) + " dd_answer_text_type2'></div>" +
          "</div>"
      }
    }

    content_block += "</div>";

    content_block += "<div class='it_dialog_box'></div>";
    $('#interaction_' + game_id).html(content_block);
  }

  return {
    build: buildCustomDragDropTextGame
  }
});
