require('fullcalendar');

define ['Vendor/modules/BrowserDetect'], (BrowserDetect) ->
  show_hover_popup = (event, jsEvent) ->
    event_type = event.className[0]
    overflow = 0
    offset = $('#page_wrapper').offset()
    offsetX = offset.left
    scrollOffsetY = $(window).scrollTop()

    if $('#flash').length
      scrollOffsetY -= $('#flash').outerHeight() + 40

    if jsEvent.clientX - offsetX + 230 > 990
      overflow = jsEvent.clientX - offsetX - 760

    $('#calendar_event_popup').html(event.label_text)
    $('#calendar_event_popup').css({'top': jsEvent.clientY - offset.top + scrollOffsetY - 25, 'left': jsEvent.clientX - offsetX - 99 - overflow}).removeClass('hide')

  hide_hover_popup = ->
    $('#calendar_event_popup').addClass('hide')


  highlight_calendar = (vacations, class_name) ->
    all_months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    month_and_year = $('.fc-header-title h2').html()
    month = all_months.indexOf(month_and_year.split(" ")[0]) + 1
    year = month_and_year.split(" ")[1]
    vacations_to_add_to_cal = []

    for vacation in vacations
      vacation_year =  vacation.split("-")[0]
      vacation_month = parseInt(vacation.split("-")[1], 10)
      vacation_day = parseInt(vacation.split("-")[2])

      if vacation_year == year && vacation_month == month
        vacations_to_add_to_cal.push(vacation_day)

    all_days = $('.fc-day-number')

    for day in all_days
      if (parseInt($(day).text()) in vacations_to_add_to_cal) && !$(day).parent().parent().hasClass('fc-other-month')
        $(day).parent().parent().addClass(class_name)

  teacher_calendar = () ->
    if $('#calendar').length
      data = $('#calendar').data('events')
      start_date = $('#calendar').data('start_date')

      staffer_events = []

      if data?
        for event in data
          title = event[0].replace(/_/g, " ")
          staffer_events.push { "title": title, "start": event[1], "end": event[1], "allDay": true, "className": event[2], "color": "#00a4e3", "textColor": "#FFFFFF", "label_text": event[3], "assignment_path": event[4] }

      $('#calendar').fullCalendar(
        weekends: false
        events: staffer_events
        eventMouseover: (event, jsEvent, view) ->
          show_hover_popup(event, jsEvent)
        eventMouseout: (event, jsEvent, view) ->
          hide_hover_popup()
      )

  mini_calendar = () ->
    if $('#calendar-mini').length
      $('#calendar-mini').fullCalendar(
        weekends: false
      )

      $('.fc-button-next, .fc-button-prev').on('click', ->
        $('.mini-cal-day-off').removeClass('mini-cal-day-off')
        $('.mini-cal-assignment').removeClass('mini-cal-assignment')
      )

      url = $('#teacher_home_calendar').data('url')
      ;
      $('.fc-content').wrap("<a href='#{url}' class='mini_cal_link_wrap'/>")
      ;


  ie_cal_link_fallback = ->
    if (BrowserDetect.browser == "Explorer")
      if $('#calendar-mini').length > 0
        $('.fc-content').on('click', ->
          window.location = $('#teacher_home_calendar').data('url')
        )

  add_aria_labels = () ->
    previousButton = $('#calendar .fc-prev-button')
    todayButton = $('#calendar .fc-today-button')
    nextButton = $('#calendar .fc-next-button')

    previousButton.attr('aria-label', 'go to previous month')
    todayButton.attr('aria-label', 'go to current month')
    nextButton.attr('aria-label', 'go to next month')

  init: ->
    teacher_calendar()
    ie_cal_link_fallback()
    mini_calendar()
    add_aria_labels()
