define [], ->
  changeSection = ->
    $('[data-behavior~=sections_dropdown]').each ->
      $(this).on 'change', ->
        program = $(this).attr('data-program')
        locale = $(this).attr('data-locale')
        scope = $(this).attr('data-scope')
        selected_section = this.value

        url = "/shared/sections/#{selected_section}?program_id=#{program}"

        if locale
          url = "#{url}&locale=#{locale}"

        url = addAnchor(url, scope)

        location.href = url

  changeNotebookSection = ->
    $('[data-behavior~=notebook_section_dropdown]').on 'change', ->
      $selected_option = $(this).find('option:selected')
      is_create = $selected_option.attr('data-create')
      url = $selected_option.val()

      if is_create
        $.post(
          "#{url}"
        )
      else
        location.href = url

  scrollToElement = (parent, el, ms) ->
    div = document.getElementById(parent)
    parent_offset = $(div).offset().top
    child_offset = $(el).offset().top
    offset = child_offset - parent_offset
    div.scrollTop = offset

  addAnchor = (url, scope) ->
    return "#{url}##{scope}" if scope != 'text'

    url

  init: ->
    changeSection()
    if ($('.current_section').length)
      scrollToElement('nav_section_holder', '.current_section', 600);
    if $('[data-behavior~=notebook_section_dropdown]').length
      changeNotebookSection()
