define([], function() {
  function TCIVideoThumbnail(config) {
    this.container = config.container;
    this.width = config.width;
    this.thumb_url = 'http://platoproduction20160712.s3.amazonaws.com/system/static_assets/TCI-Video.jpg';
  }

  TCIVideoThumbnail.prototype = {
    init: function() {
      this._buildElements();
      if (this.width != undefined) {
        this.wrapper.style.width = this.width + 'px';
      }
      this._setImage(this.thumb_url);
    },

    _buildElements: function() {
      this.wrapper = document.createElement('div');
      this.wrapper.className = 'TCI_video_thumb_wrapper';
      this.thumb_image = document.createElement('img');
      this.wrapper.appendChild(this.thumb_image);
      this.overlay = document.createElement('div');
      this.overlay.className = 'TCI_video_thumb_overlay';
      this.wrapper.appendChild(this.overlay);
      this.button = document.createElement('div');
      this.button.className = 'TCI_video_thumb_button';
      this.wrapper.appendChild(this.button);
      this.container.appendChild(this.wrapper);
    },

    _setImage: function(src) {
      this.thumb_image.src = src;
    }
  };

  return TCIVideoThumbnail
});