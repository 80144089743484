define([], function() {
  function buildMultipleImageChoiceGame(game_data, game_id) {
    // Image position is dictated by answer number, so shuffling does nothing

    var content_block = '',
      game_obj = game_data[0],
      game_question = game_data[1],
      game_images = [],
      correct_answer_list = game_data[2].split(', '),
      is_correct = "",
      explanation = game_data[3],
      alph = ['A', 'B', 'C', 'D'];

    content_block +=
      "<div class='mic_wrapper'><div class='mic_question interaction_question'><span class='mic_answer_" + game_data[2] + "'>" + game_question + "</span></div>" +
      "<div class='mic_inner_wrapper'><div class='clear'></div>" +
      "<div class='mic_ref'>";

    for (var i = 0; i < 4; i++) {
      if (correct_answer_list.indexOf(i.toString()) > -1) {
        is_correct = " mic_correct";
      }
      else {
        is_correct = "";
      }

      game_images.push("<div class='mic_answer mic_answer_" + (i + 1) + is_correct +"'>" +
      "<div class='mic_letter'>" + alph[i] + ".</div>" +
      "<div class='mic_radio_button'>" +
      "<div><i class='fa fa-times'></i><i class='fa fa-check'></i></div>" +
      "</div>" +
      "<img src='" + game_obj[i].img + "' class='mic_image' />" +
      "</div>");
    }
//  game_images = FisherYatesShuffle(game_images);

    for (var i = 0; i < 4; i++) {
      content_block += game_images[i];
    }

    content_block += "</div><div class='mic_info'>";

    content_block += '<div class="mic_reset_game">Reset</div>'
    content_block += "<a class='mic_check_answer'>Check Answer</a>";

    content_block += '<div class="mic_response_container">';
    content_block += '<div class="mic_response mic_correct_text"><div class="mic_response_back"><span class="mic_response_text">Correct! ' + explanation + '</span></div></div>';
    content_block += '<div class="mic_response mic_somecorrect_text"><div class="mic_response_back"><span class="mic_response_text"></span></div></div>';
    content_block += '<div class="mic_response mic_incorrect_text"><div class="mic_response_back"><span class="mic_response_text"></span></div></div>';
    content_block += "</div></div><div class='it_dialog_box'></div>";

    $('#interaction_' + game_id).html(content_block);

    return {
      number_of_answers: correct_answer_list.length
    }
  }

  return {
    build: buildMultipleImageChoiceGame
  }
});