define([], function() {
  function InlineEditor(config) {
    this.el = config.el;
    this.param = config.param;
    this.css_styled = config.css_styled || false;
  }

  InlineEditor.prototype = {
    init: function() {
      if (!$(this.el).data('inline-edit')) {
        return false;
      }

      this.update_url = $(this.el).data('inline-edit');
      this.content = $(this.el).text();

      this.build();

      return this;
    },

    build: function() {
      this.container = document.createElement('div');
      this.container.classList.add('inline-editor-container');

      this.clone = $(this.el).clone()[0];
      $(this.clone).appendTo(this.container);
      this.clone.style.display = 'inline-block';

      $(this.el).replaceWith(this.container);

      this.edit_button = document.createElement('button');
      this.edit_button.setAttribute('aria-label', 'Edit lesson guide title');
      this.edit_button.className = 'inline-editor-btn btn btn--link-purple';
      this.edit_button.style.display = 'inline-block';
      const edit_icon = document.createElement('i');
      edit_icon.className = 'fa fa-pencil-square-o';
      edit_icon.setAttribute('aria-hidden', 'true');
      this.edit_button.appendChild(edit_icon);

      this.container.appendChild(this.edit_button);

      this.input = document.createElement('input');
      this.input.type = 'text';
      this.input.value = this.content;
      this.input.classList.add('inline-editor-input');
      this.input.setAttribute('aria-label', 'Title');

      this.save_button = document.createElement('button');
      this.save_button.setAttribute('aria-label', 'Save title');
      this.save_button.className = 'inline-editor-btn btn btn--link-green';
      const save_icon = document.createElement('i');
      save_icon.className = 'fa fa-check';
      save_icon.setAttribute('aria-hidden', 'true');
      this.save_button.appendChild(save_icon);

      this.cancel_button = document.createElement('button');
      this.cancel_button.setAttribute('aria-label', 'Cancel title changes');
      this.cancel_button.className = 'inline-editor-btn btn btn--link-red';
      const cancel_icon = document.createElement('i');
      cancel_icon.className = 'fa fa-times';
      cancel_icon.setAttribute('aria-hidden', 'true');
      this.cancel_button.appendChild(cancel_icon);

      // create error message
      this.error_message = document.createElement('p');
      this.error_message.className = 'invalid_field_error inline_editor_field';
      this.error_message.style.display = 'none';

      this.container.appendChild(this.input);
      this.container.appendChild(this.save_button);
      this.container.appendChild(this.cancel_button);
      this.container.appendChild(this.error_message);

      this._setupListeners();
    },

    _setupListeners: function() {
      $(this.edit_button).on('click', function() {
        this.copyTextToInput();
        $(this.clone).hide();
        $(this.edit_button).hide();
        $(this.input).show();
        $(this.save_button).show();
        $(this.cancel_button).show();
      }.bind(this));

      $(this.save_button).on('click', function() {
        const data = {};
        data[this.param] = this.input.value;
        $.ajax({
          dataType: 'json',
          method: 'PUT',
          url: this.update_url,
          data: data
        })
          .done(function(data) {
            if (data.errors || data.error) {
              if (data.error) {
                this.error_message.innerText = data.error;
              }
              else {
                for (attr in data.errors) {
                  this.error_message.innerText = data.errors[attr];
                }
              }
              $(this.error_message).show();
            }
            else {
              this.copyInputToText();
              this.enterReadMode();
            }
          }.bind(this))
          .fail(function() {
            console.log("Couldn't save.");
            this.enterReadMode();
          });
      }.bind(this));

      $(this.cancel_button).on('click', function() {
        this.copyTextToInput();
        this.enterReadMode();
      }.bind(this));
    },

    enterReadMode: function() {
      $(this.clone).show();
      $(this.edit_button).show();
      $(this.input).hide();
      $(this.save_button).hide();
      $(this.cancel_button).hide();
      $(this.error_message).hide();
    },

    copyTextToInput: function() {
      this.input.value = this.clone.innerText;
    },

    copyInputToText: function() {
      this.clone.innerText = this.input.value;
    }
  };

  return InlineEditor;
});
