define([], function() {
  function SimpleGame(parent_slide) {
    this.slide_id = parent_slide;
    this.unanswered = true;
  }

  SimpleGame.prototype = {
    init: function (terms) {
      var this_obj = this;
      this_obj.reset();

      // Add event listeners
      $('#' + this.slide_id + ' .check_answers').on('click', function (e) {
        e.preventDefault();

        if (this_obj.unanswered) {
          $('#' + this_obj.slide_id + ' .it_dialog_box')
            .text('Please select an answer.')
            .show()
            .delay(2500)
            .fadeOut(100)
        }
        else {
          var $correctable;
          for (var i in terms) {
            $correctable = this_obj.getNthCorrectable(i);
            if ($correctable.find('select').val() == terms[i])
              this_obj.markAsCorrect($correctable);
            else
              this_obj.markAsIncorrect($correctable);
          }
        }
      });

      $('#' + this.slide_id + ' .reset_answers').on('click', function (e) {
        e.preventDefault();
        this_obj.reset();
      });

      $('#' + this.slide_id + ' .sg_correctable select').on('change', function () {
        this_obj.unanswered = false;
      });

    },

    getNthCorrectable: function (i) {
      var this_obj = this;
      return $('#' + this_obj.slide_id + ' .sg_correctable.id_' + i);
    },

    markAsCorrect: function ($correctable) {
      $correctable.find('.wrong_icon').hide();
      $correctable.find('.right_icon').show();
    },

    markAsIncorrect: function ($correctable) {
      $correctable.find('.wrong_icon').show();
      $correctable.find('.right_icon').hide();
    },

    reset: function () {
      var this_obj = this;

      $('#' + this_obj.slide_id + ' .sg_correctable').each(function () {
        //$(this).css('border', '');
        $('#' + this_obj.slide_id + ' .sg_correctable select').prop('selectedIndex', 0);
        $('#' + this_obj.slide_id + ' .sg_correctable').find('.wrong_icon').hide();
        $('#' + this_obj.slide_id + ' .sg_correctable').find('.right_icon').hide();
      });
    }
  };

  return SimpleGame
});

