define [], ->
  newStudentFormSubmission = ->
    $('#teacher_add_new_student_form').submit ->
      unless $('#student_last_name').val()
        $('#last_initial_teach_add_student').show()
        return false

  showAdminContactList = ->
    $('#js-teacher-contact-admin').click(->
      $('#sub_admins_info, #admin_email_list').removeClass('hide')
    )

  showNewTrialPopup = ->
    if $('#trial_program_home').length && $('#new_trial_popup').length
      $('#new_trial_popup').removeClass('hide')
      $('.overlay_background').show().removeClass('hide')

      # close everything
      $('.overlay_background, #new_trial_popup_close, #new_trial_popup_close_and_continue').click(->
        $('#new_trial_popup').hide()
        hidePopupOverlay()
      )

      # close popup (play video)
      $('#trial_popup_video_button, #trial_popup_video_link, #trial_popup_video_thumb').click(->
        $('#new_trial_popup').hide()
        hidePopupOverlay()
      )

  hidePopupOverlay = ->
    $('.overlay_background').hide()


  showImageDetailsOnFileSelect = ->
    $('#question_image').on 'change', ->
      $('#assessment_image_details').slideDown(100)

  toggleCreateTemporaryPassword = ->
    $('.js-create-temporary-password').click(->
      $('.js-create-temporary-password-fields').toggleClass('hide')
    )

  init: ->
    newStudentFormSubmission()
    showAdminContactList()
    showNewTrialPopup()
    showImageDetailsOnFileSelect()
    toggleCreateTemporaryPassword()
