define([
  '../../../../node_modules/codemirror/lib/codemirror',
  '../../../../node_modules/codemirror/mode/htmlmixed/htmlmixed.js',
  '../../../../node_modules/codemirror/addon/edit/closetag.js'
], function(CodeMirror) {
  function checkFileExtension() {
    $('#question_submit').on('click', function () {
      const ext = $(this.form).find("input[type='file']").val().split('.').pop().toLowerCase();
      if ($.inArray(ext, ['png', 'jpg', 'jpeg', '']) === -1) {
        alert('Invalid Extension! Please enter either jpg/jpeg/png file.');
        return false;
      }
    });

    $('#import_submit').on('click', function () {
      const ext = $('input#import_csv').val().split('.').pop().toLowerCase();
      if ($.inArray(ext, ['csv']) === -1) {
        alert('Please enter csv file.');
        return false;
      }
      $('#processing').show();
      $('#flash').hide();
    });

    $('#challenge_submit').on('click', function () {
      const ext = $('input#challenge_image_image').val().split('.').pop().toLowerCase();
      if ($.inArray(ext, ['png', 'jpg', 'jpeg', '']) === -1) {
        alert('Invalid Extension! Please enter either jpg/jpeg/png file.');
        return false;
      }
    });
  }

  function showFakePasswordDotsForEdit() {
    let $password_field = $(":input.password_field");
    
    $password_field.on('focus', function () {
      $(this).css({backgroundImage:"none"});
    });

    $password_field.on('blur', function () {
      if ($(this).val() !== "") {
        $(this).removeClass("password_field");
      } else {
        $(this).css({backgroundImage:""});
      }
    });
  }

  function checkImageFileNameForSpecialChars() {
    $('#question_submit').on('click', function () {
      const special_char_match = /[|{}~`[\]]/;
      let file_name = $(this.form).find("input[type='file']").val();
      if (file_name.match(special_char_match)) {
        alert('Invalid File Name. Please remove special character like {, }, |, ~, [, ], and `');
        return false;
      }
    });
  }

  window.checkCapsLockStatus = function(e) {  //called from view onKeyPress
    e = e || event;
    const charKeyCode = e.keyCode ? e.keyCode : e.which;
    // To work with both I.E & Gecko . e.keycode:: I.E & e.which :: Gecko.

    const shiftKey = e.shiftKey ? e.shiftKey : (charKeyCode == 16);
    // To work with both I.E & Gecko. e.shiftKey :: I.E & charKeyCode == 16 :: Gecko.

    // Check both the conditions as described above
    if ((charKeyCode >= 65 && charKeyCode <= 90) && !shiftKey) {
    // Caps lock is on
      $('#caps_warning').fadeIn('300');
    }
    else if ((charKeyCode >= 97 && charKeyCode <= 122) && shiftKey) {
    // Caps lock is on
      $('#caps_warning').fadeIn('300');
    }
    else {
      $('#caps_warning').fadeOut('300');
    }
  };

  function preventEnteringNumberGreaterThanMax() {
    $('form').submit(function () {
      const $number_input = $("input[type=number]");
      
      const max = $number_input.attr('max');
      const min = $number_input.attr('min');

      if (max && max.length > 0) {
        if (parseInt(max) < parseInt($number_input.val())) {
          $('#character_limit_warning').removeClass('hide');
          return false;
        }
      }
      
      if (min && min.length > 0) {
        if (parseInt(min) > parseInt($number_input.val())) {
          $('#character_limit_warning').removeClass('hide');
          return false;
        }
      }
    });
  }

  function clearInputOnClick() {
    let stored_val = '';
    const $input = $('.notebook_score_input_js');

    $input.on('focus', function() {
      if ($(this).val() != "") {
        stored_val = $(this).val();
      }
      $input.val("");
    });

    $input.on('blur', function() {
      if ($(this).val() == "" && stored_val != '') {
        $input.val(stored_val);
      }
      else {
        $input.val($(this).val());
      }
    })
  }

  function validateIntegerSize() {
    $('#scoring_settings_notebook_possible_points, #notebook_grade_form_1, #notebook_grade_form_2').keydown(function (e) {
      const maximum_value = parseInt($(this).attr('max'));

      if (!((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode == 8) || (e.keyCode == 37) || (e.keyCode == 39) || (e.keyCode >= 96 && e.keyCode <= 105)) || e.shiftKey) {
        return false;
      }
      else {
        let current_value = $(this).val() ? $(this).val() : 0;
        if (current_value > 0) {
          current_value = current_value * 10 + mapKeyCodeToInteger(e.keyCode);
        }
        else {
          $('.notebook_score_input_js').val('');
          current_value = mapKeyCodeToInteger(e.keyCode);
        }
        
        if (current_value > maximum_value) {
          $('.character_limit_warning').removeClass('hide');
          return false;
        }
        else {
          $('.character_limit_warning').addClass('hide');
        }
      }
    });
    return false;
  }

  function mapKeyCodeToInteger(code) {
    switch (code) {
      case 48:
        return 0;
      case 96:
        return 0;
      case 49:
        return 1;
      case 97:
        return 1;
      case 50:
        return 2;
      case 98:
        return 2;
      case 51:
        return 3;
      case 99:
        return 3;
      case 52:
        return 4;
      case 100:
        return 4;
      case 53:
        return 5;
      case 101:
        return 5;
      case 54:
        return 6;
      case 102:
        return 6;
      case 55:
        return 7;
      case 103:
        return 7;
      case 56:
        return 8;
      case 104:
        return 8;
      case 57:
        return 9;
      case 105:
        return 9;
    }
  }

  function checkPasswordLength() {
    $('#my_account_settings #edit_staffer, #edit_student_password').submit(function () {
      const password_field = $('#staffer_password, #student_password');

      // return if no password fields exist
      if (password_field.length === 0) return;

      if (password_field.val().length > 0 && password_field.val().length < 6) {
        alert("Password must be at least 6 characters long.");
        return false;
      }
    });
  }

  function code_mirror_init() {
    if ($("#slide_html").length > 0 && $('form.template_select p').length === 0) {
      let editor = CodeMirror.fromTextArea(document.getElementById("slide_html"), {
        mode: 'text/html',
        lineWrapping: true,
        lineNumbers: true,
        autoCloseTags: true
      });

    editor.setSize('100%', 586);
    }
  }

  function check_existing_staffer_by_email() {
    const $field = $('input#staffer_email'),
          url = $field.attr('data-checkemail'),
          email = $field.val();

    $.ajax({
      type: "GET",
      url: url,
      data: {
        email: email
      },
      success: function() {
        $('#email_in_use_warning').hide();
      },
      error: function() {
        $('#email_in_use_warning').show();
      }
    });
  }

  return {
    init: function() {
      showFakePasswordDotsForEdit();
      checkFileExtension();
      checkImageFileNameForSpecialChars();
      preventEnteringNumberGreaterThanMax();
      validateIntegerSize();
      checkPasswordLength();
      code_mirror_init();
      clearInputOnClick();
      if ($('#staffer_email').length) {
        $('input#staffer_email').on('blur', function() {
          check_existing_staffer_by_email();
        });
      }
    }}
});
