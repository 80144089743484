define(['Modal'], function(Modal) {
  let container_element;
  let caret;
  let expand_button;
  let close_button;
  let is_visible = true;
  let $info_list;
  const info_items = [];

  function init(config) {
    container_element = config.element;
    close_button = $(container_element).find('button.close')[0];
    caret = $(container_element).find('i.fa-caret-right')[0];
    expand_button = $(container_element).find('.js-info-bar-toggle')[0];

    $info_list = $(container_element).find('div.items');

    $info_list.find('span.info-bar-item-container').each(function() {
      const info = new InfoItem({
        element: $(this)[0]
      });
      info.init();
      info_items.push(this);
    });

    setupVisibilityListeners();
  }

  function setupVisibilityListeners() {
    close_button.addEventListener('click', toggleBar);
    expand_button.addEventListener('click', toggleBar);
  }

  function toggleBar() {
    if (is_visible) {
      $info_list.hide();
      $(expand_button).attr('aria-expanded', false);
      $(caret).addClass('rotate');
      is_visible = false;
    }
    else {
      $info_list.show();
      $(expand_button).attr('aria-expanded', true);
      $(caret).removeClass('rotate');
      is_visible = true;
    }
  }

  function InfoItem(config) {
    this.element = config.element;
    this.title = config.title;
    this.content = config.content;
    this.trigger_element = config.trigger_element;
    this.tciOnly = config.element.dataset.tciOnly === 'true';
  }

  InfoItem.prototype = {
    init: function() {
      if (!this.title) {
        this.title = $(this.element).find('[data-title]').html();
      }
      if (!this.content) {
        this.content = $.parseHTML($(this.element).find('[data-content]').html())[0].data;
      }

      this.build();
    },

    build: function() {
      if (!this.trigger_element) {
        this.trigger_element = document.createElement('a');
        this.trigger_element.classList.add('info-bar-item');
        this.trigger_element.href = '#';
        this.trigger_element.innerHTML = this.title;
        if (this.tciOnly) {
          this.trigger_element.innerHTML += "<i aria-label='TCI only' class='fa fa-lock ml5' />";
        }
        this.element.innerHTML = '';
        this.element.appendChild(this.trigger_element);
      }

      this.popup_element = document.createElement('div');
      $(this.popup_element).attr('tabindex', 0);
      document.body.appendChild(this.popup_element);
      $(this.popup_element).append(this.content).addClass('fr-view');

      this.modal = new Modal({
        trigger_element: this.trigger_element,
        popup_element: this.popup_element,
        element_to_focus: this.popup_element,
        popup_class: 'modal_popup_wrapper grey-modal',
        close_button_class: 'close',
        default_closed: !($(this.element).hasClass('on-page-load')),
        overlay: true,
        center: true,
        width: '840px',
        title: this.title,
        close: this.onClose.bind(this)
      });

      this.modal.init();
    },

    onClose: function() {
      if ($(this.trigger_element).parent().hasClass('on-page-load')) {
        this.animateOnClose();
      }
      else {
        this.modal.hide();
      }
    },

    animateOnClose: function() {
      const popup = document.getElementsByClassName(this.modal.popup_class);
      const final_pos = $(this.modal.trigger_element).offset();
      $(popup).css({
        'min-height': '0px',
        height: $(popup).height()
      });
      $('body').scrollTop(0);
      $(popup).animate({
        left: final_pos.left,
        top: final_pos.top,
        width: 0,
        height: 0
      }, 500, function() {
        this.modal.hide();
        $(popup).css({
          left: '', top: '', width: '', 'min-height': '', height: ''
        });
        ($(this.modal.trigger_element).parent().removeClass('on-page-load'));
      }.bind(this));
    }
  };


  return {
    init: init,
    InfoItem: InfoItem
  };
});
