define(['TCIUtils'], function(TCIUtils) {
  function ImageToGif(config) {
    this.container = config.container;
    this.img_src = TCIUtils.cacheBustString(config.img_src);
    this.gif_src = TCIUtils.cacheBustString(config.gif_src);
    this.pair_id = config.pair_id;
    this.slide_id = config.slide_id;
  }

  ImageToGif.prototype = {
    init: function() {
      var this_obj = this,
        inner_image;

      this._imagePreload();

      if (this.pair_id !== undefined && $('div#s_slide_' + this_obj.slide_id + ' [data-img-to-gif-trigger="' + this.pair_id + '"]').length > 0) {
        this_obj.trigger = $('div#s_slide_' + this_obj.slide_id + ' [data-img-to-gif-trigger="' + this.pair_id + '"]')[0];
      }
      else {
        this_obj.trigger = this.container;
      }

      if ($(this.container).find('img').length > 0) {
        inner_image = $(this.container).find('img')[0];
      }
      else {
        inner_image = document.createElement('img');

        this.container.appendChild(inner_image);
      }

      inner_image.src = this.img_src;

      // this.control = document.createElement('div');
      // this.control.style.position = "absolute";
      // this.control.innerHTML = "Play";
      // this.container.appendChild(this.control);

      $(this.trigger).on('click', function() {
        if ($(this_obj.container).find('img').attr('src') == this_obj.img_src) {
          $(this_obj.container).find('img').attr('src', this_obj.gif_src);
          // this_obj.control.innerHTML = "Stop";
        }
        else {
          $(this_obj.container).find('img').attr('src', this_obj.img_src);
          // this_obj.control.innerHTML = "Play";
        }
      });
    },

    _imagePreload: function() {
      var preload_gif = document.createElement('img');

      preload_gif.src = this.gif_src;
    }
  };

  return ImageToGif
});