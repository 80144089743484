const compareAndUpdateCheckbox = (elem, desiredVal) => {
  if (elem.checked !== desiredVal) {
    $(elem).prop('checked', desiredVal);
    $(elem).trigger('change');
  }
};

const setSelectAllCheckbox = (elem) => {
  $(elem).prev('.select_all').each(function() {
    compareAndUpdateCheckbox(this, elem.checked);
  });
};

const lessonLocationOptions = [
  '',
  'Presentation',
  'Student Text',
  'Investigation Planning',
  'Investigation Master',
  'Investigation Fast Track',
  'Unit Projects'
];

const enableLocationOptions = () => {
  $('#alert_message_location > option').each(function() {
    this.disabled = false;
  });
};

const disableLocationOptionsAndWarn = () => {
  let display_warning = false;
  let prev_location = '';

  // disable lesson level locations
  $('#alert_message_location > option').each(function() {
    if (!(lessonLocationOptions.indexOf(this.value) > -1)) {
      if (this.selected) {
        display_warning = true;
        prev_location = this.value;
        this.selected = false;
      }
      this.disabled = true;
    }
  });

  if (display_warning) {
    $('.input.alert_message_location').find('.location_warning').remove();
    $('.input.alert_message_location').append(`<span class="location_warning red pl10"> ${prev_location} is only available for a program-scoped info bar</span>`);
  }
};

const toggleClassesOnScopeChange = () => {
  // display lesson-specific stuff
  $('.display-lesson-list').toggleClass('hide-lessons-checkbox');
  $('.all-lessons-checkbox').toggleClass('hide-lessons');

  // hide program-specific stuff
  $('.program-checkbox').toggleClass('hide-program-checkbox');
  $('.program-level').toggleClass('leaf');
};

const buildDropdown = (options, dropdown, science) => {
  if (science === 'true') {
    Object.keys(options).forEach(function(unit) {
      const optgroup = document.createElement('optgroup');
      optgroup.label = unit;

      for (let i = 0; i < options[unit].length; i++) {
        const option = document.createElement('option');
        option.value = options[unit][i].id;
        option.textContent = options[unit][i].title;
        optgroup.appendChild(option);
      }
      dropdown.appendChild(optgroup);
    });
  }
  else {
    options.forEach(function(option) {
      const newOption = document.createElement('option');
      newOption.value = option.id;
      newOption.text = option.title;
      dropdown.appendChild(newOption);
    });
  }
};

const populateDropdown = (programDropdown, lessonDropdown) => {
  const url = programDropdown.options[programDropdown.selectedIndex].dataset.url;
  const science = programDropdown.options[programDropdown.selectedIndex].dataset.science;

  if (url === 'all') {
    $(lessonDropdown).find('option:first').text('All Lessons');
    $(lessonDropdown).find('option:gt(0)').remove();
    $(lessonDropdown).find('optgroup').remove();
  }
  else if (url === 'none') {
    $(lessonDropdown).find('option:first').text('None');
    $(lessonDropdown).find('option:gt(0)').remove();
    $(lessonDropdown).find('optgroup').remove();
  }

  // if user selects a program
  else {
    $.get(url)
      .done(function(data) {
        $(lessonDropdown).find('option:first').text('All Lessons');
        $(lessonDropdown).find('option:gt(0)').remove();
        $(lessonDropdown).find('optgroup').remove();
        buildDropdown(data, lessonDropdown, science);
      })
      .fail(function() {
        console.log('failure :(');
      });
  }
};

const setupPublishButtons = () => {
  $('a.alert_message_publish:not(.disabled)').each(function() {
    $(this).on('click', function(e) {
      e.preventDefault();
      const url = '/admin/alert_messages/publish';
      const data = {
        id: $(this).attr('data-alert-message-id')
      };
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        button: $(this),
        error: function(response) {
          console.log('error:', response);
        },
        success: function(response) {
          if (response === true) this.button.html('Unpublish');
          else this.button.html('Publish');
        }
      });
    });
  });
};

const initListeners = () => {
  // if a program is toggled, also toggle the hidden select all lessons element
  // and trigger change to toggle lessons
  $('input.program-checkbox').on('change', function(e) {
    setSelectAllCheckbox(e.target);
  });

  // if select all lessons is toggled for a program, toggle the hidden program checkbox
  $('.all-lessons-checkbox').on('change', function(e) {
    $(e.target).next('.program-checkbox').each(function() {
      compareAndUpdateCheckbox(this, e.target.checked);
    });
  });

  // remove location warning message on successful selection
  $('.input.alert_message_location').on('change', function() {
    $(this).find('.location_warning').remove();
  });
};

const updateOnScopeChange = (newScope, pageLoad = false) => {
  if (newScope === 'program') {
    // hide any expanded lessons
    $('.program.expanded').next('ul').hide();
    $('.program.expanded').removeClass('expanded');

    // for any selected lessons, select the parent programs
    $('input:checked.lesson').closest('.parent').prev('li').find('.program-checkbox')
      .each(function() {
        compareAndUpdateCheckbox(this, true);
      });

    // if any programs are selected, check hidden select all element
    if (pageLoad) {
      $('input.program-checkbox:checked').each(function() {
        setSelectAllCheckbox(this);
      });
    }
    else {
      toggleClassesOnScopeChange();
    }
    enableLocationOptions();
  }
  else if (newScope === 'lesson') {
    if (!pageLoad) {
      toggleClassesOnScopeChange();
    }
    disableLocationOptionsAndWarn();
  }
};

const setupFilter = (programDropdown, lessonDropdown) => {
  $(programDropdown).on('change', function(e) {
    populateDropdown(e.target, lessonDropdown);
  });
};

export default {
  initListeners: initListeners,
  setupFilter: setupFilter,
  setupPublishButtons: setupPublishButtons,
  updateOnScopeChange: updateOnScopeChange,
};
