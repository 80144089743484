define([], function() {
  /*
    Toggle Class
    <button id="expand-all">Expand All</button>
    <div class='js-toggle-container'
         data-animation="fade||none||slide (your choice) data-display-type="(your display choice)">
      <div>
        <h3 aria-expanded="false" data-toggle="#my_element_selector_to_toggle"
            data-indicator="#optional_status_symbol">Where you click to toggle
          <i class="fa-edit no-toggle">Something you want to exclude from triggering toggle</i>
        </h3>
        // if you want an optional indicator outside of the toggle-able object
        // this can also toggle the element by adding data-toggle
        <button id="optional_status_symbol">+</button>
        <div id="my_element_selector_to_toggle" style="display: none;">Content</div>
      </div>
    </div>

    Additional Toggle.js notes:
      https://teachtci2.atlassian.net/wiki/spaces/SOF/pages/667582526/Toggle.js+Notes
  */
  function Toggle(config) {
    this.container = config.container;
    this.toggleAttribute = config.toggleAttribute;
    this.toggleSelector = `[${config.toggleAttribute}]:not(.fa-trash)`;
    this.expandAllSelector = config.expandAllSelector || '#expand-all';
    this.activeClass = this.activeClass || 'active';
    this.animation = config.animation || 'slide'; // slide is the default animation to keep existing functionality
    this.displayType = config.displayType || 'block';
  }

  Toggle.prototype = {
    init: function() {
      this._setupListeners();
    },

    _set_display: function(target) {
      if ($(target).is(':visible')) {
        $(target).css('display', this.displayType);
      }
    },

    _toggleVisualIndicator: function(elem) {
      if ($(elem).is('button.expandable-btn')) {
        const indicator_text = $(elem).text() === '+' ? '−' : '+';
        $(elem).text(indicator_text);
      }
    },

    _toggleText: function(elem) {
      const expanded_text = $(elem).data('expanded-text');
      const collapsed_text = $(elem).data('collapsed-text');
      if (expanded_text && collapsed_text) {
        $(elem).hasClass(this.activeClass) ? $(elem).text(expanded_text) : $(elem).text(collapsed_text);
      }
    },

    _toggleIcon: function(selector) {
      const $elem = $(selector);

      const expandedClass = `fa-${$elem.data('expanded-icon')}`;
      const collapsedClass = `fa-${$elem.data('collapsed-icon')}`;

      $elem.toggleClass(`${expandedClass} ${collapsedClass}`);
    },

    _toggleAttrs: function(elem) {
      $(elem).toggleClass(this.activeClass);
      $(elem).attr('aria-expanded', $(elem).hasClass(this.activeClass));
    },

    _toggle: function(elem) {
      const target = $(elem).attr(this.toggleAttribute);

      if ($(elem).data('skip-animation')) {
        $(target).toggle();
        this._set_display(target);

        $(elem).data('skip-animation', false);
      }
      else if (this.animation === 'fade') {
        // setting display type is a little wonky with the fade animation, not including on this option
        // will set to the default display type of the target element
        $(target).fadeToggle();
      }
      else if (this.animation === 'slide') {
        $(target).slideToggle(() => this._set_display(target));
      }
      else {
        $(target).toggle();
        this._set_display(target);
      }

      const visual_indicator = $(elem).data('indicator');
      if (visual_indicator) this._toggleVisualIndicator(visual_indicator);

      const word_indicator = $(elem).data('word-indicator');
      if (word_indicator) this._toggleText(word_indicator);

      const iconSelector = $(elem).data('icon-selector');
      if (iconSelector) this._toggleIcon(iconSelector);

      const toggledEvent = new CustomEvent('toggled');
      window.dispatchEvent(toggledEvent);
    },

    _toggleAll: function(target) {
      const state = $(target).data('toggle-state');
      // needs to be expanded
      if (state === 'collapsed') {
        target.innerText = target.dataset.collapseText || 'Collapse All';
        $(this.toggleSelector)
          .not(`.${this.activeClass}`)
          .each((index, item) => {
            this._toggleAttrs(item);
            const elemToShow = $(item).attr(this.toggleAttribute);
            if ($(elemToShow).is(':hidden')) this._toggle(item);
          });
        $(target).data('toggle-state', 'expanded');
      }
      // needs to be collapsed
      if (state === 'expanded') {
        target.innerText = target.dataset.expandText || 'Expand All';
        $(`.${this.activeClass}${this.toggleSelector}`)
          .each((index, item) => {
            this._toggleAttrs(item);
            const elemToHide = $(item).attr(this.toggleAttribute);
            if ($(elemToHide).is(':visible')) this._toggle(item);
          });
        $(target).data('toggle-state', 'collapsed');
      }
    },

    _setupListeners: function() {
      const self = this;
      $(document).on('click keypress', this.toggleSelector, function(e) {
        if ($(e.target).hasClass('js-prevent-toggle')) return;
        if (e.type === 'keypress') {
          const key = e.which || e.keyCode || 0;
          if (key !== 13 && key !== 32) return;
        }
        if ($(this).data('preventDefault') !== false) e.preventDefault();
        if (!$(e.target).hasClass('no-toggle')) {
          const elem = $(this).attr(self.toggleAttribute);
          $(`[${self.toggleAttribute}="${elem}"]`).each((index, item) => {
            self._toggleAttrs(item);
          });
          self._toggle(this);
        }
      });

      const $expandAllButton = $(this.expandAllSelector);
      const expandAllButtonTrigger = $expandAllButton.data('trigger') || 'data-toggle';
      if (expandAllButtonTrigger !== this.toggleAttribute) return;

      $expandAllButton.on('click', function() { self._toggleAll(this); });

      if ($expandAllButton.length) {
        // Used to expand or collapse newly-rendered elements to
        // match the current state of the button
        $expandAllButton[0].updatePage = function() {
          const isExpanded = $(this).data('toggle-state') === 'expanded';
          const oppositeState = isExpanded ? 'collapsed' : 'expanded';
          $(this).data('toggle-state', oppositeState);
          self._toggleAll(this);
        };
      }
    }
  };

  return Toggle;
});
