define([], function() {
  function sectionCheckAll() {
    $('#section_check_all').on('change', function() {
      if ($(this).is(':checked')) {
        $('.section_checkbox').attr('checked', 'checked');
      }
      else {
        $('.section_checkbox').prop('checked', false);
      }
    });
  }

  function assignmentClassRadioButton() {
    $('#all_classes_radio_button').change(function() {
      if ($(this).is(':checked')) {
        $('.assignment_class_checkbox').attr('checked', 'checked');
        $('#specific_classes').addClass('hide');
        $('#all_classes_due_date').removeClass('hide');
      }
      else {
        $('.assignment_class_checkbox').prop('checked', false);
        $('#specific_classes').removeClass('hide');
        $('#all_classes_due_date').addClass('hide');
      }
    });

    $('#specific_classes_radio_button').change(function() {
      if ($(this).is(':checked')) {
        $('.assignment_class_checkbox').prop('checked', false);
        $('#specific_classes').removeClass('hide');
        $('#all_classes_due_date').addClass('hide');
      }
      else {
        $('.assignment_class_checkbox').attr('checked', 'checked');
        $('#specific_classes').addClass('hide');
        $('#all_classes_due_date').removeClass('hide');
      }
    });
  }

  return {
    init: function() {
      sectionCheckAll();
      assignmentClassRadioButton();
    }
  };
});
