define(['TCIUtils'], function(TCIUtils) {
  function init() {
    setup_isn_listeners();
    checkIframe();
    setUpNextLinkListener();
  }

  function setup_isn_listeners() {
    const $audio_players = $(`#${this.id}`).find('audio.aws_audio');
    if ($audio_players.length > 0) {
      $audio_players.mediaelementplayer({
        preload: 'metadata',
        audioWidth: 400
      });
    }
  }

  function setUpNextLinkListener() {
    if (TCIUtils.inIframe() && window.frameElement && window.frameElement.src.includes('slide_id')) {
      return;
    }

    $(window).on('beforeunload', function() {
      const dirtyElements = $('[data-dirty="true"]').filter((_, el) => el.getAttribute('data-type') !== 'drawing');

      if (dirtyElements.length) {
        return 'You have unsaved data changes that will be lost.';
      }
    });
  }

  function checkIframe() {
    // fix chrome bug that removes scrollbar on iframe visibility toggle
    const target = window.frameElement;

    if (TCIUtils.inIframe() && target) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          const $body = $('body');
          const overflow = $body.css('overflow');
          if (overflow === 'visible' || overflow === 'auto') {
            $body.css('overflow', 'scroll');
          }
          else {
            $body.css('overflow', 'auto');
          }
        });
      });
      observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    }
  }

  return {
    init: init
  };
});
