define(['TCIUtils', 'Accordion', 'TCIAlert'], function(TCIUtils, Accordion, TCIAlert) {
  let assessment;

  function Assessment(config) {
    this.container_id = config.container_id;
  }

  Assessment.prototype = {
    init: function() {
      TCIUtils.reloadIfBackButtonUsed();

      this.accordion = new Accordion({
        default_state: 'expanded',
        expand_all_button: $('.js-toggle-assessment-questions')[0],
        master_id: this.container_id,
        $control_elements: $('.expandable_question'),
        dependents_class: 'js-question-details'
      });

      this.accordion.init();
    },
  };

  function init(config) {
    assessment = new Assessment({
      container_id: config.$container.attr('id')
    });

    assessment.init();
  }

  return {
    init: init
  };
});
