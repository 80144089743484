define(['TCIUtils'], function(TCIUtils) {
  function buildNoQuestion(game_data, game_id) {
    var content_block = '',
      game_obj = game_data,
      media = game_data[0],
      directions = game_data[1]["directions"];

    content_block += '<div id="blab" class="no_question interaction_question">' + directions + '</div>';

    if (media.media && media.media[0] === 'image') {
      content_block += '<img src="' + media.media[1][0] + '" />';
    }
    else if (media.media && media.media[0] === 'aws_video') {
      content_block += '<div class="nq_video">' +
      '<div id="video_player">' +
      '<video preload="metadata" src="' + media.media[1] + '"' + 'class="aws_video" width="930" height="555"></video>' +
      '</div>';
    }
    else if (media.media && media.media[0] === 'vimeo_id') {
      content_block += '<div class="nq_video">' +
      '<div id="video_player">' +
      '<div class="embed_vimeo">' +
      '<iframe id="vimeo_iframe" src="https://player.vimeo.com/video/' +
      media["media"][1] + '?fun=0" width= 930 height= 555 ' +
      '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>' +
      '</div>' +
      '</div></div>';
    }

    $('#interaction_' + game_id).html(content_block);

    TCIUtils.initVideoPlayers();
  }

  return {
    build: buildNoQuestion
  }
});