define ['DropdownAccessibility'], (DropdownAccessibility) ->
  init = () ->
    view_select_accessibility = new DropdownAccessibility({
      object: this,
      toggleFn: toggle_view_select_dropdown,
      openFn: show_view_select_dropdown,
      closeFn: hide_view_select_dropdown,
      trigger: $('.js-current-view'),
      triggerWrapper: $('.js-view-selector'),
      dropdownWrapper: $('.js-views-wrapper'),
      dropdownOptionWrapper: 'li.js-option-wrapper',
      dropdownOptionClass: '.js-view-option'
    })

    view_select_accessibility.init()

    gear_dropdown_accessibility = new DropdownAccessibility({
      object: this,
      toggleFn: toggle_gear_dropdown,
      openFn: show_gear_dropdown,
      closeFn: hide_gear_dropdown,
      trigger: $('.js-gear-trigger'),
      triggerWrapper: $('.js-header-username'),
      dropdownWrapper: $('.js-gear-dropdown'),
      dropdownOptionWrapper: 'li.js-gear-item',
      dropdownOptionClass: 'a.js-gear-link'
    })

    gear_dropdown_accessibility.init()

    if $('#gear_dropdown').length
      $(document).on 'click', (e) ->
        $tgt = $(e.target)
        if $tgt.hasClass('header_user_name') || ($tgt.hasClass('fa-cog') && !$tgt.hasClass('non-dropdown')) || $tgt.hasClass('fa fa-caret-down gear_caret') || $tgt.hasClass('js-gear-trigger')
          if $('#gear_dropdown').is(':hidden')
            if $('#notebook_content').length > 0
              unsaved_nbs = false

              $('.js-notebook-drawing-save-btn').each ->
                if $(this).attr('data-dirty') == 'true'
                  $(this).closest('.notebook-question').addClass 'highlight'
                  unsaved_nbs = true

              $('a').on 'click', (e) ->
                if self.dirty
                  if $(this).attr('data-dirty') == 'true'
                    $(this).closest('.notebook-question').addClass 'highlight'
                    unsaved_nbs = true

              if unsaved_nbs
                confirmation_answer = confirm('You have unsaved work. Are you sure you want to leave?')
                if confirmation_answer
                  clean_dirty_data()
                  show_gear_dropdown()
                else
                  e.preventDefault()
              else
                show_gear_dropdown()
            else
              show_gear_dropdown()
          else
            hide_gear_dropdown()
        else
          hide_gear_dropdown()

    if $('.view-select').length > 0
      $('.view-select').on 'click', ->
        toggle_view_select_dropdown()

  show_gear_dropdown = ->
    $('#gear_dropdown').stop(true, true).fadeIn(50)
    $('.js-gear-trigger').attr('aria-expanded', true)

  hide_gear_dropdown = ->
    $('#gear_dropdown').stop(true, true).fadeOut(50)
    $('.js-gear-trigger').attr('aria-expanded', false)

  toggle_gear_dropdown = ->
    if $('#gear_dropdown').is(':visible')
      hide_gear_dropdown()
    else
      show_gear_dropdown()

  show_view_select_dropdown = ->
    if !$('.view-select').hasClass('active')
      $('.view-select').addClass('active')
      $('.js-current-view').attr('aria-expanded', true)

  hide_view_select_dropdown = ->
    if $('.view-select').hasClass('active')
      $('.view-select').removeClass('active')
      $('.js-current-view').attr('aria-expanded', false)

  toggle_view_select_dropdown = ->
    if $('.view-select').hasClass('active')
      hide_view_select_dropdown()
    else
      show_view_select_dropdown()

  clean_dirty_data = ->
    $('.js-notebook-drawing-save-btn').each ->
      $(this).attr('data-dirty', false)
    $('.notebook_grid_save_button_js').each ->
      $(this).attr('data-dirty', false)

  init: init
