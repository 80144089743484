define([], () => {
  function HideCorrelations() {
    $('.show_hide').on('click', function toggleCorrelations() {
      if ($(this).find('.expandable').hasClass('closed') === true) {
        $(this).find('.expandable').removeClass('closed').addClass('open');
        $(`li[id^="${this.id}_key"]`).show();
      }
      else {
        $(this).find('.expandable').removeClass('open').addClass('closed');
        $(`li[id^="${this.id}_key"]`).hide();
      }
    });
  }

  function ShowAll() {
    $('.js-expand-all').click(function toggleAll() {
      if ($(this).text() === 'Expand All') {
        $('.expandable').removeClass('closed').addClass('open');
        $('.correlation_information').show();
        const current_span = $(this);
        $(current_span).text('Collapse All');
      }
      else {
        $('.expandable').removeClass('open').addClass('closed');
        $('.correlation_information').hide();
        $(this).text('Expand All').removeClass('pressed');
      }
    });
  }

  return {
    init: () => {
      HideCorrelations();
      ShowAll();
    }
  };
});
