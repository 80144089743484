define([], function(window) {
  var auto_savers = [],
      config,
      value,
      params;

  function init() {
    auto_savers = document.querySelectorAll('[data-auto-save]');

    for (var i = 0; i < auto_savers.length; i++) {
      config = undefined;
      value = undefined;
      params = undefined;

      config = JSON.parse(auto_savers[i].getAttribute('data-auto-save'));

      $(auto_savers[i]).on(config.trigger,
        (function(i, config) {
          return function() {
            value = $(auto_savers[i]).val();
            params = (JSON.parse(auto_savers[i].getAttribute('data-auto-save').replace('value', value))).params;
            $.ajax({
              type: config.type || 'PUT',
              url: config.url,
              data: params,
              success: successMessage(config)
            });
          }
        })(i, config)
      );
    }
  }

  function successMessage(config) {
    if (config.success !== undefined) {
      $(config.success).show().delay(2000).fadeOut(400);
    }
  }

  return {
    init: init
  }
});
