/* eslint no-console:0 */
/* eslint-disable new-cap */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require('stylesheets/application.scss');
require('../tailwind-base.scss');
const rails = require('rails-ujs');

const Axios = require('axios');
const routes = require('routes');

Axios.defaults.headers.common['Accept-Language'] = document.querySelector('html').lang;

rails.start();

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.detectEvents();
ReactRailsUJS.mountComponents();

const tippy = require('tippy.js');
const LocalTime = require('local-time');

LocalTime.start();

define(
  'globalEntry',
  [
    'Vendor/modules/BrowserDetect',
    'Tabs',
    'TCIUtils',
    'InlineEditor',
    'TemplateSelect',
    'ConfirmationField',
    'Accordion',
    'DropdownManager',
    'FileSizeValidation',
    'TCIVideoPopup',
    'SlideshowImage',
    'StudentNotebook',
    'StudentTextImage',
    'TCIVideoThumbnail',
    'ZipLookup',
    'Challenges/AdminReadingChallenge',
    'SlideShow/ImageToGif',
    'SlideShow/SlideshowSetup',
    'Interactions/InteractiveHotSpot',
    'Interactions/InteractiveSetup',
    'TeacherAssessment',
    'AutoSave',
    'Assignment',
    'BatchUploadHides',
    'AjaxFormSubmit',
    'Gradebook',
    'Correlations',
    'DatePicker',
    'FormValidation',
    'GlossaryTerms',
    'MapZoom',
    'Notebook',
    'OddEven',
    'student',
    'SystemMessage',
    'TableOfContents',
    'DisplayTabs',
    'TCIValidate',
    'S3VideoPopup',
    'Exclusive/ClassroomDropdown',
    'Admin',
    'calendar',
    'ChangeLesson',
    'checkbox',
    'HeaderNav',
    'Image',
    'ReadingChallenge',
    'ResizeDashboardHeaderWidth',
    'Sections',
    'StudentHomePage',
    'Teacher',
    'Parent',
    'pn_tabs',
    'popup',
    'StickyToolbar',
    'TeacherComment',
    'CollapsibleDropdown',
    'alert_message/AlertMessageEditor',
    'alert_message/InfoBar',
    'States',
    'Dropdown',
    'LtiKeyGenerator',
    'DropdownLink',
    'TCISpinner',
    'SpecialFeature',
    'MainIdea',
    'fabric-3',
    'DataVisualization',
    'Grid/GridBuilder',
    'Sortable',
    'QuestionDroppable',
    'Toggle',
    'Flash',
    'Modal',
    'StudentAssessment',
    'ValidateForm',
    'PresentationGuide',
    'TruncatedList',
    'FilterForm',
    'ModalForm',
    'SetupCSRFToken',
    'Simulations/ClimateSimulation/ClimateSimulation',
    'CheckboxShowHide',
    'LivePreview',
    'ExternalRedirect'
  ], function(
    BrowserDetect,
    Tabs,
    TCIUtils,
    InlineEditor,
    TemplateSelect,
    ConfirmationField,
    Accordion,
    DropdownManager,
    FileSizeValidation,
    TCIVideoPopup,
    SlideshowImage,
    StudentNotebook,
    StudentTextImage,
    TCIVideoThumbnail,
    ZipLookup,
    AdminReadingChallenge,
    ImageToGif,
    SlideshowSetup,
    InteractiveHotSpot,
    InteractiveSetup,
    TeacherAssessment,
    AutoSave,
    Assignment,
    BatchUploadHides,
    AjaxFormSubmit,
    Gradebook,
    Correlations,
    DatePicker,
    FormValidation,
    GlossaryTerms,
    MapZoom,
    Notebook,
    OddEven,
    student,
    SystemMessage,
    TableOfContents,
    DisplayTabs,
    TCIValidate,
    S3VideoPopup,
    ClassroomDropdown,
    Admin,
    calendar,
    ChangeLesson,
    checkbox,
    HeaderNav,
    Image,
    ReadingChallenge,
    ResizeDashboardHeaderWidth,
    Sections,
    StudentHomePage,
    Teacher,
    Parent,
    pn_tabs,
    popup,
    StickyToolbar,
    TeacherComment,
    CollapsibleDropdown,
    AlertMessageEditor,
    InfoBar,
    States,
    Dropdown,
    LtiKeyGenerator,
    DropdownLink,
    TCISpinner,
    SpecialFeature,
    MainIdea,
    fabric,
    DataVisualization,
    GridBuilder,
    Sortable,
    QuestionDroppable,
    Toggle,
    Flash,
    Modal,
    StudentAssessment,
    ValidateForm,
    PresentationGuide,
    TruncatedList,
    FilterForm,
    ModalForm,
    SetupCSRFToken,
    ClimateSimulation,
    CheckboxShowHide,
    LivePreview,
    ExternalRedirect
  ) {
    (function() {
      ExternalRedirect.redirectLinks(document);

      $(document).ready(function() {
        $.extend({
          getUrlVars: function() {
            const vars = [];
            let hash;
            const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (let i = 0; i < hashes.length; i++) {
              hash = hashes[i].split('=');
              vars.push(hash[0]);
              vars[hash[0]] = hash[1];
            }
            return vars;
          },
          getUrlVar: function(name) {
            return $.getUrlVars()[name];
          }
        });

        const presentationGuides = $('.presentation-guide-container');
        if (presentationGuides.length) {
          presentationGuides.each(function() {
            const generatePdfButton = $(this).find('.generate-pdf')[0];
            const guide = new PresentationGuide({
              popup_element: $(this).find('.presentation-guide-modal')[0],
              popup_trigger: $(this).find('.popup-trigger')[0],
              generate_trigger: generatePdfButton,
              loading_container: $(this).find('.pg-loading-container')[0],
              generate_url: generatePdfButton.dataset.buildPdfUrl,
              pdf_updated_url: generatePdfButton.dataset.pdfUpdatedUrl,
              current_pdf_link: $('.current_pdf_link')[0]
            });
            guide.init();
          });
        }

        // Sets up CSRF token for Axios to use.
        SetupCSRFToken.default();

        $('[data-show-hide-toggle-trigger]').each(function() {
          const id = $(this).data('show-hide-toggle-trigger');

          if ($(`[data-show-hide-toggle-dependent="${id}"]`).length > 0) {
            const checkbox_show_hide = new CheckboxShowHide.default({
              trigger: $(this)[0],
              dependent: $(`[data-show-hide-toggle-dependent="${id}"]`)[0]
            });
            checkbox_show_hide.init();
          }
        });

        ///**
        // *
        // *  Admin Grid
        // *
        // *
        // *
        // */
        if ($('.new_admin_notebook_grid').length) {
          $('#notebook_grid_question_rows').val(2);
          $('#notebook_grid_question_columns').val(2);
          window.admin_grid = new AdminGrid({
            id: 'admin_notebook_grid',
            num_rows: 2,
            num_cols: 2,
            image_table_rows: $('.section_images tbody tr')
          });

          admin_grid.init();

          if ($('#admin_grid_submit').length) {
            $('#admin_grid_submit').on('click', function(e) {
              e.preventDefault();

              $('#notebook_grid_question_chunk').val(admin_grid.toString());
              const form = $(this).closest('form');
              $("<input type='submit'>").css('display', 'none').appendTo(form).click();
            });
          }
        }

        if ($('.edit_admin_notebook_grid').length) {
          window.admin_grid = new AdminGrid({
            id: 'admin_notebook_grid',
            num_rows: $('#notebook_grid_question_rows').val(),
            num_cols: $('#notebook_grid_question_columns').val(),
            chunk: $('#notebook_grid_question_chunk').val(),
            image_table_rows: $('.section_images tbody tr')
          });

          admin_grid.init();

          if ($('#admin_grid_submit').length) {
            $('#admin_grid_submit').on('click', function(e) {
              e.preventDefault();

              $('#notebook_grid_question_chunk').val(admin_grid.toString());
              var form = $(this).closest('form');
              $("<input type='submit'>").css('display','none').appendTo(form).click();
            });
          }
        }

        const TCITabs = $('.tci_tabs_container');
        if (TCITabs.length) {
          TCITabs.each(function() {
            const tabs = new Tabs({
              container: this
            });

            tabs.init();
          });
        }

        const validatedForm = $('.validate_form');

        if (validatedForm.length) {
          validatedForm.each(function() {
            const validate = new ValidateForm({
              form: this
            });

            validate.init();
          });
        }

        if ($('[data-toggle-class]').length > 0) {
          $('[data-toggle-class]').each(function() {
            TCIUtils.toggleClass($(this), $(this).data('toggle-class'));
          });
        }

        if ($('[data-inline-edit]').length > 0) {
          $('[data-inline-edit]').each(function() {
            const inline_editor = new InlineEditor({
              el: this,
              param: this.getAttribute('data-param')
            });
            inline_editor.init();
          });
        }

        /**
         * Slide Template Select
         */
        if ($('#template_target').length) {
          TemplateSelect.default();
        }

        /**
         * Field confirmation
         */
        const confirmationFields = $('[data-confirm-with]');
        if (confirmationFields.length > 0) {
          confirmationFields.each(function() {
            const field = new ConfirmationField({
              form: this.form,
              field: this,
              confirmWith: $(this.form).find('#' + this.dataset.confirmWith)[0],
              message: this.dataset.confirmMessage
            });
            field.init();
          });
        }

        if ($('.tci_accordian_js').length) {
          $('.tci_accordian_js').each(function() {
            var accordion, master_id, config;

            if ($(this).attr('data-master-id')) {
              master_id = $(this).attr('data-master-id');
              config = {
                master_id: master_id,
                $control_elements: $('.tci_accordian_control_js[data-master-id=' + master_id + ']'),
                expand_all_button: $('.tci_accordian_js[data-master-id=' + master_id + ']')[0],
                dependents_class: 'tci_accordian_dependent_js',
                button_text_append: $('.tci_accordian_js[data-master-id=' + master_id + ']').data('text-append'),
                aria_label: $('.tci_accordian_control_js').data('label')
              };
            }
            else {
              config = {
                master_id: null,
                $control_elements: $('.tci_accordian_control_js'),
                expand_all_button: $('.tci_accordian_js')[0],
                dependents_class: 'tci_accordian_dependent_js',
                button_text_append: $('.tci_accordian_js').data('text-append'),
                aria_label: $('.tci_accordian_control_js').data('label')
              };
            }

            accordion = new Accordion(config);

            accordion.init();
          });
        }

        if ($('.student_assessment')) {
          StudentAssessment.init({
            $container: $('.student_assessment')
          });
        }

        if ($('.more_item_js').length) {
          DropdownManager.init({
            trigger_selector: '.more_item_js',
            dropdown_selector: '.more_dropdown',
            identifier: 'data-dropdown-id',
            orientation_selector: 'data-dropdown-orientation'
          });
        }

        if ($('.tci_video_popup_js').length) {

          /** Only build popup if needed */
          TCIVideoPopup.init();
        }

        $('[data-zoomurl]').each(function() {
          const obj = new SlideshowImage(document.getElementById('slide_reel'));
          obj.init(this);
          this.setAttribute('aria-loaded', 'true');
        });

        if ($('.notebook-question').length) {
          StudentNotebook.init();
        }

        $('[data-student-text-image="true"]').each(function() {
          const student_image = new StudentTextImage({
            element: $(this)[0]
          });

          student_image.init();
        });

        $('[data-tci-video-thumb]').each(function() {
          const config = $(this).data('tci-video-thumb');
          const thumb = new TCIVideoThumbnail({
            container: $(this)[0],
            width: config.width
          });

          thumb.init();
        });

        if ($('#zip_lookup_input').length) {
          ZipLookup.init({
            input_el: $('#zip_lookup_input')[0],
            hidden_input_el: $('#mdr_input')[0],
            submit_btn_el: $('#zip_lookup_button')[0],
            parent_el: $('#zip_lookup_container')[0],
            search_url: $('[data-zip-lookup-url]').data('zip-lookup-url'),
            international_checkbox: $('#trial_survey_international')[0],
            international_input: $('#zip_lookup_international_input')[0],
            school_input: $('#trial_survey_school')[0],
            district_input: $('#trial_survey_district')[0],
            state_input: $('#trial_survey_state')[0]
          });
        }

        $('.clipart_thumb').each(function() {
          const $thumb_wrapper = $(this);

          $thumb_wrapper.find('img')[0].onload = function() {
            $thumb_wrapper.removeClass('loading');
            $thumb_wrapper.find('i').remove();
          };
        });

        if ($('body[data-controller="challenges"] div.edit').length > 0) {
          const rc = new AdminReadingChallenge();
          rc.init();
        }

        if ($('div[data-img-to-gif]').length) {
          $('div[data-img-to-gif]').each(function() {
            var image_to_gif = new ImageToGif({
              container: this,
              img_src: $(this).attr('data-img-to-gif'),
              gif_src: $(this).attr('data-gif-src'),
              pair_id: $(this).attr('data-pair-id'),
              slide_id: $(this).closest('div.slide').attr('id').split('_')[2]
            });

            image_to_gif.init();
          });
        }

        if ($('[data-clone]').length > 0) {
          SlideshowSetup.initCloner();
        }

        if ($('.slide').length) {
          InteractiveHotSpot.init();
        }

        if ($('.interactive').length) {
          InteractiveSetup.init();
        }

        // I would love to move this out to slideshow/notebook when we have a notebook pack
        if ($('div.tci-simulation[data-sim="climate"]').length > 0) {
          $('div.tci-simulation[data-sim="climate"]').each(function() {
            const sim = new ClimateSimulation.default({
              parent_el: $(this)[0]
            });

            sim.init();
          });
        }

        // SORT into conditionals
        BrowserDetect.init();
        TeacherAssessment.init();
        AutoSave.init();
        Assignment.init();
        BatchUploadHides.init();
        AjaxFormSubmit.init();
        Gradebook.init();
        Correlations.init();
        DatePicker.init();
        FormValidation.init();
        GlossaryTerms.init();
        MapZoom.init();
        Notebook.init();
        OddEven.init();
        student.init();
        SystemMessage.init();
        TableOfContents.init();
        DisplayTabs.init();
        TCIValidate.init();
        S3VideoPopup.init();
        ClassroomDropdown.init();
        Admin.init();
        Dropdown.init();


        // coffee
        calendar.init();
        ChangeLesson.init();
        checkbox.init();
        HeaderNav.init();
        Image.init();
        ReadingChallenge.init();
        ResizeDashboardHeaderWidth.init();
        Sections.init();
        StudentHomePage.init();
        Teacher.init();

        if ($('#lti_key_secret').length) {
          LtiKeyGenerator.init();
        }

        if ($('body').attr('id') === 'js_transfer_parents_show' ||
          $('body').attr('id') === 'js_transfer_customers_show') {
          Parent.init();
        }

        if ($('[data-mr-live-preview]').length) {
          const config_en = {
            output_el: $('[data-mr-live-preview="output_en"]')[0],
            description_input: $('[data-mr-live-preview="description_en"]')[0],
            title_input: $('[data-mr-live-preview="title_en"]')[0],
            caption_input: $('[data-mr-live-preview="caption_en"]')[0],
          };

          window.mr_live_preview_en = new LivePreview.default(config_en);
          window.mr_live_preview_en.init();

          const config_es = {
            output_el: $('[data-mr-live-preview="output_es"]')[0],
            description_input: $('[data-mr-live-preview="description_es"]')[0],
            title_input: $('[data-mr-live-preview="title_es"]')[0],
            caption_input: $('[data-mr-live-preview="caption_es"]')[0],
          };

          window.mr_live_preview_es = new LivePreview.default(config_es);
          window.mr_live_preview_es.init();
        }

        if ($('#pn_tabs_container').length) {
          pn_tabs.init();
        }

        if ($('.js_popup').length) {
          popup.init();
        }

        if ($('#th-controls-toolbar').length) {
          StickyToolbar.init();
        }

        $('.notebook_teacher_comment div textarea').each(function() {
          const teacher_comment = new TeacherComment({
            answer_id: $(this).data('answer-id'),
            textbox: this,
            message_element: $(this).closest('.teacher_comments').find('.comment_message'),
            url: $(this).closest('form').attr('action')
          });
          teacher_comment.init();
        });

        if ($('.ngss_sticky_header').length) {
          TCIUtils.lockElementToTop('ngss_sticky_header');
        }

        /**
         *  Delegator.js
         */

        const $toggleContainers = $('.js-toggle-container');
        if ($toggleContainers.length) {
          $toggleContainers.each(function() {
            const toggle = new Toggle({
              container: this,
              toggleAttribute: this.getAttribute('data-trigger') || 'data-toggle',
              animation: this.getAttribute('data-animation'),
              displayType: this.getAttribute('data-display-type')
            });
            toggle.init();
          });
        }

        const $sortables = $('.sortable');
        if ($sortables.length) {
          window.sortables = new Array(1);
          $sortables.each(function() {
            const $sortable_elem = $(this);
            const sortable = new Sortable({
              $selector: $sortable_elem,
              reorder_url: $sortable_elem.attr('data-reorder-url')
            });
            window.sortables.push(sortable.init());
          });
        }

        const $droppables = $('.question-droppable');
        if ($droppables.length > 0) {
          const droppable = new QuestionDroppable.default();
          droppable.init();
        }

        // Setup all inputs with class select_all
        const $selectAll = $('input.select_all');
        if ($selectAll.length > 0) {
          TCIUtils.setupSelectAllCheckboxes($selectAll);
        }

        // alert message edit pages
        if ($('#programs_list').length > 0) {
          const selector = new CollapsibleDropdown({
            area: document.getElementById('programs_list')
          });
          selector.init();
        }

        // info bar view
        if ($('.info-bar').length) {
          InfoBar.init({
            element: $('.info-bar')[0]
          });
        }

        // Alert message index
        if ($('.alert_message_publish').length > 0) {
          AlertMessageEditor.default.setupPublishButtons();
        }

        if ($('select.program-filter').length > 0 && $('select#lesson-filter').length > 0) {
          AlertMessageEditor.default.setupFilter(
            $('select.program-filter'),
            document.querySelector('select#lesson-filter')
          );
        }

        // info bar preview ('View' action on admin index)
        if ($('.preview-alert-message').length > 0) {
          $('.preview-alert-message').each(function() {
            const _this = this;
            const info = new InfoBar.InfoItem({
              element: $(_this)[0],
              trigger_element: $(_this)[0],
              title: _this.getAttribute('data-title') || ' ',
              content: _this.getAttribute('data-content') || ' '
            });
            info.init();
          });
        }

        // states modal
        if ($('span.states').length > 0) {
          $('span[data-states][data-preload=true]').each(function() {
            const statesModal = new States.picker({
              trigger_element: this,
              id: this.dataset.id,
              type: this.dataset.elementType,
              submit: this.dataset.submit,
              submitUrl: this.dataset.submitUrl,
              statesUrl: this.dataset.statesUrl,
              preload: this.dataset.preload
            });
            statesModal.init();
          });

          $('span[data-states]').on('click', function() {
            const statesModal = new States.picker({
              trigger_element: this,
              id: this.dataset.id,
              type: this.dataset.elementType,
              submit: this.dataset.submit,
              submitUrl: this.dataset.submitUrl,
              statesUrl: this.dataset.statesUrl
            });
            statesModal.init();
          });
        }

        // Dropdown links
        const $dropdownLinks = $('[data-links-dropdown]');
        if ($dropdownLinks.length) {
          $dropdownLinks.each(function() {
            const list = new DropdownLink({
              element: $(this)[0]
            });

            list.init();
          });
        }

        const $modals = $('.modal-trigger');
        if ($modals.length > 0) {
          $modals.each(function(index, trigger) {
            const modal_id = $(trigger).data('modal');
            const $popup = $(modal_id);
            const modal = new Modal({
              trigger_element: trigger,
              popup_element: $popup[0],
              popup_class: 'modal_popup_wrapper grey-modal',
              close_button_class: 'modal_close_button',
              custom_close_selector: '.custom_close_trigger',
              default_closed: true,
              overlay: true,
              center: true,
              width: $(trigger).data('modal-width') || 960,
              z_index: 10000,
              title: $popup.data('modal-title') || $(trigger).data('modal-title'),
              open: function() { window.current_open_modal = this; this.show(); },
              close: function() { window.current_open_modal = null; this.hide(); }
            });
            modal.init();
          }.bind(this));
        }

        // Edgate
        if ($('#correlation_link, #show_correlation_submit, .correlations, #no_correlations').length > 0) {
          (function() {
            var spinner = new TCISpinner({
              text: 'Correlations may take up to 2 minutes to load.   Please wait while we load this page.'
            });

            spinner.build();

            $('#correlation_link, #show_correlation_submit').on('click', function() {
              spinner.show();
            });
            if ($('.correlations, #no_correlations').length) {
              $('#correlations-lesson-select select').on('change', function() {
                spinner.show();
              });
            }
          })();
        }

        const $autoSubmit = $('[data-autosubmit]');
        if ($autoSubmit.length > 0) {
          TCIUtils.setupAutoSubmit($autoSubmit);
        }

        // edit lesson - kind / icon
        const $specialFeature = $('#lesson-kind');
        if ($specialFeature.length === 1) {
          SpecialFeature.preview({
            kind_select: $specialFeature.find('select#lesson_kind')[0],
            icon_container: $specialFeature.find('div#sf_icon')[0],
            icon_select: $specialFeature.find('select#lesson_icon_id')[0],
            icon_preview: $specialFeature.find('i')[0]
          });
        }

        // student text main ideas
        const $mainIdea = $('.main-idea-button');
        if ($mainIdea.length > 0) {
          MainIdea.init($mainIdea);
        }

        /**
         *  End Delegator.js
         */

        if ($('[data-truncated-list]').length > 0) {
          $('[data-truncated-list]').each(function() {
            const truncated_list = new TruncatedList.create({
              container: $(this).parent(),
              trigger: $(this).parent().find('.js-truncated-list-trigger')[0],
              item_selector: 'li',
              num_of_unaffected: $(this).data('num-of-unaffected'),
              expand_text: $(this).data('expand-text'),
              collapse_text: $(this).data('collapse-text'),
              rotate_icon: $(this).data('rotate-icon')
            });

            truncated_list.init();
          });
        }

        const $modalForm = $('.modal-form');
        if ($modalForm.length > 0) {
          $modalForm.each(function() {
            const modal_form = new ModalForm({
              $submit_button: $(this).find('.modal-submit'),
              $form: $(this)
            });

            modal_form.init();
          });
        }
      });

      $(window).on('load', function() {
        setTimeout(() => {
          $('[data-zoomurl]:not([aria-loaded])').each(function() {
            const obj = new SlideshowImage(document.getElementById('slide_reel'));
            obj.init(this);
          });
        }, 1000);

        if ($('#program_toc_settings').length) {
          TableOfContents.initLoad();
        }

        if (window.location.pathname.includes('admin')) return;

        setTimeout(() => {
          const srcNodes = document.querySelectorAll('[src]');
          const hrefNodes = document.querySelectorAll('[href]');
          const nodeLists = [{ attr: 'src', list: srcNodes }, { attr: 'href', list: hrefNodes }];
          const slugsDiv = document.querySelector('#slug-attributes');
          const slugs = {
            lesson_id: slugsDiv.getAttribute('data-lesson-id'),
            program_id: slugsDiv.getAttribute('data-program-id'),
            unit_id: slugsDiv.getAttribute('data-unit-id'),
            classroom_id: slugsDiv.getAttribute('data-classroom-id'),
          };

          nodeLists.forEach((nodeList) => {
            nodeList.list.forEach((node) => {
              for (const key in slugs) {
                const nodeAttr = node.getAttribute(nodeList.attr);
                const val = slugs[key];
                const slugKeys = [`%3A${key}`, `:${key}`];

                slugKeys.forEach((slugKey) => {
                  if (nodeAttr.includes(slugKey)) {
                    const newAttr = nodeAttr.replaceAll(slugKey, val);
                    node.setAttribute(nodeList.attr, newAttr);
                  }

                  if (node.textContent && node.textContent.includes(slugKey)) {
                    node.textContent = node.textContent.replaceAll(slugKey, val);
                  }
                });
              }
            });
          });
        }, 50);
      });

      const $flashMessage = $('#flash');
      if ($flashMessage.length > 0) {
        Flash.init();
      }

      const $toggleClassOn = $('[data-toggle-class-on]');
      if ($toggleClassOn.length > 0) {
        $toggleClassOn.each(function() {
          TCIUtils.toggleClassOn($(this));
        });
      }

      $('.back-to-top').on('click', function(e) {
        $(window).scrollTop(0);
        e.preventDefault();
      });

      /**
       * Initiate tippy.
       */
      tippy.default.setDefaults({
        animateFill: false,
        arrow: true,
        hideOnClick: 'toggle',
        interactive: true,
        theme: 'white'
      });


      /**
       * Prevent default on any elements with this class.
       */
      $('.js-prevent-default').on('click', function preventDefault(e) {
        e.preventDefault();
      });
    })();
  }
);
