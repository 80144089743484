define [], ->
  tabs_selector = ->
    $('.pn_center_tab').on('click', ->
      if !($(this).hasClass('selected_tab'))
        id = $(this).attr('id')

        $('.pn_tab').removeClass('selected_tab selected_tab_right selected_tab_left')
        $('.pn_tabbed_content').hide()

        $(this).addClass('selected_tab')

        $(this).prev('.tab_dividers').eq(0).addClass('selected_tab_right')
        $(this).next('.tab_dividers').eq(0).addClass('selected_tab_left')

        $('#' + id + '_content.pn_tabbed_content').show()

        # TODO: move these elsewhere. when trying to call them upon changing tabs
        # within section edit, the tabs selector click listener would fire before
        # the editor refresh, so the refresh didn't actually work.
        # this same methodology did work within slide editing
        if window.editor then window.editor.refresh()
        if window.editor2 then window.editor2.refresh()
        if window.editor3 then window.editor3.refresh()
    )

  init: ->
    tabs_selector()
