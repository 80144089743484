define([], function() {
  function DropdownAccessibility(config) {
    this.object = config.object;
    this.toggleFn = config.toggleFn;
    this.openFn = config.openFn;
    this.closeFn = config.closeFn;
    this.trigger = config.trigger;
    this.triggerWrapper = config.triggerWrapper;
    this.dropdownWrapper = config.dropdownWrapper;
    this.dropdownOptionWrapper = config.dropdownOptionWrapper;
    this.dropdownOptionClass = config.dropdownOptionClass;
  }

  DropdownAccessibility.prototype = {
    init: function() {
      this.setUpListeners();
    },

    setUpListeners: function() {
      $(this.dropdownWrapper).on('focusout', (e) => {
        if (!($(e.relatedTarget).is(this.dropdownOptionClass))) {
          this.closeFn.bind(this.object)();
        }
      });

      $(this.trigger).on('keydown', (e) => {
        const key = e.which || e.keyCode || 0;

        if (key !== 32 && key !== 40 && key !== 38 && key !== 27) return;

        e.preventDefault();

        // "enter" key toggles the dropdown with the appropriate attrs
        if (key === 32) {
          this.toggleFn.bind(this.object)();
        }
        // "down arrow" key opens the dropdown and focuses on the first element in the dropdown
        else if (key === 40) {
          this.openFn.bind(this.object)();
          $(this.dropdownWrapper).find('a').first().focus();
        }
        // "up arrow" key opens the dropdown and focuses on the last element in the dropdown
        else if (key === 38) {
          this.openFn.bind(this.object)();
          $(this.dropdownWrapper).find('a').last().focus();
        }
        // "esc" key closes the dropdown
        else if (key === 27) {
          this.closeFn.bind(this.object)();
        }
      });

      $(this.dropdownWrapper).on('keydown', $(this.dropdownOptionClass), (e) => {
        const key = e.which || e.keyCode || 0;

        if (key !== 40 && key !== 38 && key !== 27) return;

        e.preventDefault();
        const $target_parent = $(e.target).parent();

        // "down arrow" key listener focuses on the previous element in the dropdown
        if (key === 40) {
          /* since the 'a' tags are wrapped in an 'li', get the 'a' tag's parent element (the 'li')
          * get the li's siblings (the other li elements)
          * if there are none after, wrap back to the first element in the list
          * else focus onto the next element
          * */
          if ($target_parent.nextAll(this.dropdownOptionWrapper).length === 0) {
            $(this.dropdownWrapper).find(this.dropdownOptionClass).first().focus();
          }
          else {
            $target_parent.nextAll(this.dropdownOptionWrapper).first().children(this.dropdownOptionClass)
              .focus();
          }
        }
        // "up arrow" key listener focuses on the next element in the dropdown
        else if (key === 38) {
          if ($target_parent.prevAll(this.dropdownOptionWrapper).length === 0) {
            $(this.dropdownWrapper).find(this.dropdownOptionClass).last().focus();
          }
          else {
            $target_parent.prevAll(this.dropdownOptionWrapper).first().children(this.dropdownOptionClass)
              .focus();
          }
        }
        // "escape" key closes the dropdown, and refocuses on the trigger element
        else if (key === 27) {
          this.closeFn.bind(this.object)();
          $(this.triggerWrapper).find(this.trigger).first().focus();
        }
      });
    }
  };

  return DropdownAccessibility;
});
