define [], ->
  show_hide_transfer_option = ->
    $("#link_school_div").click( ->
      $(".link_options_js").hide()
      $("#button_explanations").hide()
      $("#link_school").fadeIn(500)
    )

    $("#link_homeschool_div").click( ->
      $(".link_options_js").hide()
      $("#button_explanations").hide()

      $("#link_homeschool").fadeIn(500)
    )

    $(".link_back").click( ->
      $("#link_homeschool").hide()
      $("#link_school").hide()
      $("#button_explanations").show()
      $(".link_options_js").fadeIn(500)
    )

  init: ->
    show_hide_transfer_option()