define(['TCIUtils'], function(TCIUtils) {
  function buildParagraphGame(game_data, game_id) {
    var terms = [];
    var text = game_data[0].text;
    var answer_words = text.match(/\[(.*?)\]/g);
    var extra_words = [];
    game_data[3].extra_words ? extra_words = game_data[3].extra_words.match(/\[(.*?)\]/g) : extra_words; // to account for pre-existing tutorials and NOT passing extra words
    var all_words = answer_words.concat(extra_words);
    var image = game_data[1].images;
    var content_block = '';
    var extended = game_data[2].extended || false;
    var replacement_text = extended == "1" ? "<div class='dd_dropzone_type_2_extended dd_dropzone id_FIX_ME dd_underline'></div>" : "<div class='dd_dropzone_type_2 dd_dropzone id_FIX_ME dd_underline'></div>";

    for (var i = 0; i < all_words.length; i++) {
      terms.push(WordStripper(all_words[i]));
      text = text.replace(/\[(.*?)\]/g, replacement_text)
    }

    content_block = "<div class='interaction_question hide'>Drag the terms to their appropriate locations.</div>" +
    "<div class='vocab_term_block abs_it'>" +
    "<div id='origin_" + game_id + "' class='vocab_term_origin dd_origin'>" +
    "<p class='vocab_origin_title'>Word Bank</p>";

    var temp_holder = [];
    for (var i = 0; i < terms.length; i++) {
      temp_holder[i] = "<p class='dd_answer dd_answer_js id_" + (i + 1) + "'>" + terms[i] + "</p>"
    }
    temp_holder = TCIUtils.fisherYatesShuffle(temp_holder);
    for (var i = 0; i < terms.length; i++) {
      content_block += temp_holder[i]
    }

    content_block += "</div><!-- end of vocab-term-origin div -->" +
    "<div class='clear'>" +
    "</div><!-- end of vocab-terms-block div -->" +
    "<a href='##' class='type_1_check_answer dd_check_answers'>Check My Answers</a>" +
    "<a href='##' class='type_1_reset_answer dd_reset_answers'>Reset</a>" +
    "</div><!-- end of vocab-terms-block div -->";

    if (image != null) {
      content_block += "<img class='paragraph_image' src='" + image + "'/>"
    }

    content_block += "<div class='vocab_definition_block_2 abs_it'>";
    content_block += text;

    content_block += "</div><!-- end of vocab-definition-block div -->";
    content_block += "<div class='it_dialog_box'></div>";
    $('#interaction_' + game_id).html(content_block);

    $('#interaction_' + game_id + ' .dd_dropzone').each(function(index) {
      var number = '<span>' + (index + 1) + '</span>';
      $(this).append(number);
    });

    $('.id_FIX_ME').each(function (i) {
      $(this).removeClass('id_FIX_ME');
      $(this).addClass('id_' + (i + 1));
    });
  }

  //remove square brackets
  function WordStripper(word) {
    return word.substring(1, word.length - 1);
  }

  return {
    build: buildParagraphGame
  }
});