define(['TCISpinner'], function(TCISpinner) {
  function copyListener() {
    $(document).on('click', '.js-copy-spinner', function() {
      const spinner = new TCISpinner({ text: 'Making a copy...' });
      spinner.build();
      spinner.show();
    });
  }

  return {
    init: function() {
      copyListener();
    }
  };
});
