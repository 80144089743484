define([], function() {
  function Sortable(config) {
    this.$selector = config.$selector || $('.sortable');
    this.reorder_url = config.reorder_url || this.$selector.attr('data-reorder-url');
  }

  Sortable.prototype = {
    init: function() {
      this.setupListeners();

      return this;
    },

    setupListeners: function() {
      this.setupSortable();
    },

    setupSortable: function() {
      var self = this;
      self.$selector.sortable({
        update: function(event, ui) {
          $.post(self.reorder_url, self.$selector.sortable('serialize'));
        },
        cancel: 'input,textarea,button,select,option,[contenteditable],.unsortable',
        /***
         * This helper fixes a bug with jqueryUI draggable where
         * your draggable items will not be directly on your cursor.
         * This clones the item with absolute positioning as a fix.
         * ***/
        helper: function(event, ui) {
          var $clone = $(ui).clone();
          $clone.css('position', 'absolute');
          return $clone.get(0);
        }
      });
    },

    reinit: function() {
      this.$selector.sortable('destroy');
      this.$selector.off();

      this.setupListeners();
    }
  }

  return Sortable;
});
