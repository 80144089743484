define(['TCISpinner', 'Modal'], function(TCISpinner, Modal) {
  function setupGradebookPageListeners() {
    $('[data-behavior~=scores_classroom_dropdown]').on('change', function () {
      changeClassroom($(this));
      setupSectionCollapseListener();
    });
    $('[data-behavior~=scores_lesson_dropdown]').on('change', function () {
      changeLesson($(this).val());
      setupSectionCollapseListener();
    });
    $('.trial_popup_link, .trials_popup_close').on('click', function () {
      showTrialMessagePopup();
    });
    $('.js-edit-max-notebook-points').on('click', function() {
      showMaxNotebookPointsModal($(this));
    });
    setupSectionCollapseListener();
  }

  function classAverage() {
    $('.percent_correct').each(function() {
      if ($('.first_attempt').length > 0) {
        var grade = parseInt($(this).text())
      }
      else if ($(this).data('score') != undefined) {
        var grade = parseFloat($(this).data("score") / $(this).data("possible") * 100)
      }
      else {
        return false;
      }

      var low_grade = parseInt($('#low_score_value').text());

      if (grade < low_grade) {
        $(this).closest('tr').css({
          background: "rgb(238,238,238)",
          border: "1px solid rgb(170,0,0)",
          marginTop: "5px"
        });
      }
    });
  }

  function changeClassroom(obj) {
    var program = $(obj).attr('data-program'),
      selected_classroom = obj.val(),
      selected_lesson = $('[data-behavior~=scores_lesson_dropdown] option[selected="selected"]').val(),
      url,
      state_obj = { classroom: selected_classroom };

    if (selected_lesson === undefined) {
      selected_lesson = $('#classroom_without_students').attr('data-hidden-lesson-id');
    } else {
      // leave selected lesson as it is
    }
    url = '/teacher/scores/' + selected_classroom + '?lesson_id=' + selected_lesson + '&program_id=' + program;

    window.location.assign((url));
  }

  function changeLesson(value) {
    var program = $('[data-behavior~=scores_classroom_dropdown]').attr('data-program'),
      selected_lesson = value,
      selected_classroom = $('[data-behavior~=scores_classroom_dropdown] option[selected="selected"]').val(),
      url = '/teacher/scores/' + selected_classroom + '?lesson_id=' + selected_lesson + '&program_id=' + program;

    window.location.assign((url));
  }

  function showTrialMessagePopup() {
    if ($('.trial_popup_link').hasClass('help_open')) {
      $('.trials_popup').addClass('hide');
      $('.trial_popup_link').removeClass('help_open');
    }
    else {
      $('.trials_popup').removeClass('hide');
      $('.trial_popup_link').addClass('help_open');
    }
    return false;
  }

  function showMaxNotebookPointsModal(trigger_element) {
    config = {
      trigger_element: trigger_element[0],
      popup_element: $("#max_notebook_score_popup")[0],
      overlay: true,
      popup_class: 'modal_popup_wrapper rc_modal',
      close_button_class: 'modal_close_button'
    };
    current_open_modal = current_open_modal || new Modal(config);
    current_open_modal.initialized ? current_open_modal.open() : current_open_modal.init();

    $('form.teacher_lesson').on("ajax:success", function(xhr) {
      var data = xhr.detail[0];
      $("#max_notebook_points").text(data.max_notebook_points);
      current_open_modal.close();
    });

    $('.max_notebook_score_submit').on('click', function() {
      $("<input type='submit'>").css('display','none').appendTo('form.teacher_lesson').click();
    });
  }

  function updateScoreCheck() {
    const columnLength = document.querySelectorAll('.head.section-breakdown').length;
    const cellWidth = 200;
    const $gradedLabel = $('.score-check-container');
    $gradedLabel.css('width', `${columnLength * cellWidth}px`);
  }

  function setupSectionCollapseListener() {
    const sectionCollapse = document.querySelector('.section-collapse');
    const icon = document.querySelector('.section-collapse .fa');
    const label = document.querySelector('.section-collapse div');

    if (sectionCollapse) {
      sectionCollapse.addEventListener('click', () => {
        if (sectionCollapse.classList.contains('expanded')) {
          sectionCollapse.classList.remove('expanded');
          document.querySelectorAll('.section-breakdown').forEach(breakdown => breakdown.classList.remove('visible'));
          icon.className = 'fa fa-caret-right';
          label.innerHTML = $(label).data('expandText');
        }
        else {
          sectionCollapse.classList.add('expanded');
          document.querySelectorAll('.section-breakdown').forEach(breakdown => breakdown.classList.add('visible'));
          updateScoreCheck();
          icon.className = 'fa fa-caret-left';
          label.innerHTML = $(label).data('collapseText');
        }
      });

      $(document).on('render_async_load', () => {
        if (sectionCollapse.classList.contains('expanded')) {
          document.querySelectorAll('.section-breakdown').forEach(breakdown => breakdown.classList.add('visible'));
          updateScoreCheck();
        }
        else {
          document.querySelectorAll('.section-breakdown').forEach(breakdown => breakdown.classList.remove('visible'));
        }
      });
    }
  }

  return {
    init: function() {
      setupGradebookPageListeners();
      classAverage();
    }
  };
});
