define(['Vendor/modules/BrowserDetect', 'SlideShow/SlideshowSetup'], function(BrowserDetect, SlideshowSetup) {
  function VideoPopup() {
    $('#dialog').dialog({
      autoOpen: false,
      width: 530,
      resizable: false,
      position: {"center": 100},
      closeText: "",
      closeOnEscape: false,
      beforeClose: function () {
        if (BrowserDetect.browser == "Explorer") {
          window.location.reload(true);
        }
        $("#video_player").empty();
      }
    });

    $('.s3_popup').on('click', function (e) {
      e.preventDefault();
      var poster = $(this).find('.TCI_video_thumb_wrapper img').prop('src') || $('#video_thumb img').prop('src') || '';
      var loc = $(this).attr('data-s3-url');
      var vtitle = '';
      vtitle = $(this).attr('title');

      if (vtitle) {
        $('#dialog').dialog({title: vtitle})
      }

      var vw = 800;
      var vh = 450;

      if (loc) {
        $("#video_player").html(write_s3_player(loc, vw, vh, poster));
        $('#dialog').dialog('open');
        SlideshowSetup.initializeVideoPlayer();
      }

      return false;
    });

    $('.vimeo_popup').on('click', function (e) {
      e.preventDefault();
      var loc = $(this).attr('data-vimeoid');
      var vtitle = '';
      vtitle = $(this).attr('title');

      if (vtitle) {
        $('#dialog').dialog({title: vtitle})
      }

      var vw = 870;
      var vh = 480;

      if (loc) {
        $("#video_player").html(write_vimeo_player(loc, vw, vh));
        $('#dialog').dialog('open');
      }

      return false;
    });
  }

  function write_s3_player(vid_loc, _vw, _vh, poster) {
    var add_w = 29;
    var add_h = 80;
    var html;

    if (BrowserDetect.browser == "Explorer") {
      add_w = 31;
      add_h = 82;
    }

    var nw = parseFloat(_vw) + add_w;
    var nh = parseFloat(_vh) + add_h;

    $('#dialog').dialog({width: nw, height: nh});

    html = '<video src="' + vid_loc + '" class="aws_video"' + 'width = ' + _vw + ' height=' + _vh + ' poster =' + poster + '></video>';
    html += '<div class="playback_message">For best playback, let the video fully load. Then click play.</div>';
    return html;
  }

  function write_vimeo_player(vid_loc, _vw, _vh) {
    var add_w = 29;
    var add_h = 80;
    var html;

    if (BrowserDetect.browser == "Explorer") {
      add_w = 31;
      add_h = 82;
    }

    var nw = parseFloat(_vw) + add_w;
    var nh = parseFloat(_vh) + add_h;

    $('#dialog').dialog({width: nw, height: nh});

    html = '<div class="embed_vimeo">';
    html += '<iframe id="vimeo_iframe" src="https://player.vimeo.com/video/';
    html += vid_loc + '?autoplay=1&fun=0" ';
    html += 'width="' + _vw + '" height="' + _vh + '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
    html += '</div>';
    html += '<div class="playback_message">For best playback, let the video fully load. Then click play.</div>';

    return html;
  }

  return {
    init: function() {
      VideoPopup();
    }
  }
});