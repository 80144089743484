define ['Modal', 'Magnifier', 'TCIUtils', 'i18n'], (Modal, Magnifier, TCIUtils) ->
  window.current_open_modal = undefined
  window.rc_modals = { questions: [], sections: [], factoid: undefined, instructions: undefined }

  open_ie_question = ->
    score = parseInt($('#challenge_score').text())
    next_score = (score + 1)
    rewards_seen = $('.ie_challenge_container').data('rewards-seen')

    reward_number = switch
      when (next_score >= 5)  && (next_score < 6)  then 1
      when (next_score >= 10) && (next_score < 11) then 2
      when (next_score >= 15) && (next_score < 16) then 3
      when (next_score >= 20) && (next_score < 21) then 4
      else undefined

    if reward_number && reward_number > rewards_seen
      $(this.popup_element).find('form #reward').val(reward_number)
      $(this.popup_element).addClass("daily-double").data('reward-number', reward_number)

      if $(this.popup_element).find('.modal-artifact').length == 0
        modal_artifact = $(document.createElement('div')).attr('data-reward-number', reward_number).addClass("modal-artifact cursor_point")

        tooltip_wrapper = $(document.createElement('div')).addClass("tooltip-wrapper")
        tooltip_text = $(document.createElement('div')).addClass("tooltip-text")
        tooltip_text.text(I18n.translate('lesson_game_artifact'))

        tooltip_text.appendTo(tooltip_wrapper)
        tooltip_wrapper.appendTo(modal_artifact)

        modal_artifact.appendTo($(this.popup_element).find('.rc_question_submit_wrapper'))

        modal_artifact.on 'click', ->
          $('.modal-artifact').toggleClass('active')

    else
      $(this.popup_element).find('form #reward').val('false')
      $(this.popup_element).removeClass('daily-double')
      $('.modal-artifact').remove();

    this.show()

  open_atw_question = ->
    score = parseInt($('#challenge_score_num').text())
    next_score = (score + 1)
    show_overlay = $('.atw_challenge_container').data('show-overlay')
    show_badge = $('.atw_challenge_container').data('show-badge')

    reward_number = getRewardNumber(next_score)

    if show_badge

      $(this.popup_element).find('form #reward').val(reward_number)
      $(this.popup_element).addClass("defeat-serpent").data('reward-number', reward_number)

      if $(this.popup_element).find('.modal_trophy').length == 0
        modal_trophy = $(document.createElement('div')).attr('data-reward-number', reward_number).addClass("modal-trophy cursor_point")

        tooltip_wrapper = $(document.createElement('div')).addClass("tooltip-wrapper")
        tooltip_text = $(document.createElement('div')).addClass("tooltip-text")
        tooltip_text.text(I18n.translate('lesson_game_serpent'))

        tooltip_text.appendTo(tooltip_wrapper)
        tooltip_wrapper.appendTo(modal_trophy)

        modal_trophy.appendTo($(this.popup_element).find('.rc_question_submit_wrapper'))

        modal_trophy.on 'click', ->
          $('.modal-trophy').toggleClass('active')

    else
      $(this.popup_element).find('form #reward').val('false')
      $(this.popup_element).removeClass('defeat-serpent')
      $('.modal-trophy').remove();

    if show_overlay
      display_overlay(reward_number)
      overlay_callback_init(this, reward_number)
    else
      this.show()

  getRewardNumber = (next_score) ->
    reward_number = switch
      when (next_score >= 3)  && (next_score < 4)  then 1
      when (next_score >= 6) && (next_score < 7) then 2
      when (next_score >= 9) && (next_score < 10) then 3
      when (next_score >= 12) && (next_score < 13) then 4
      else undefined
    return reward_number


  display_overlay = (step) ->
    $('.rewards_overlay_container').attr('data-step', step)
    $('.rewards_overlay_container').find('[data-step=' + step + ']').removeClass('opacity-0')
    $('.static_overlay').removeClass('hide')
    $('#okay_button').removeClass('hide')


  hide_overlay = (step) ->
    $('.rewards_overlay_container').removeAttr('data-step')
    $('.rewards_overlay_container').find('[data-step='+ step + ']').addClass('opacity-0')
    $('.static_overlay').addClass('hide')
    $('#okay_button').addClass('hide')
    $('.atw_challenge_container').data('show-overlay',false)

  overlay_callback_init = (next_obj, step) ->
    $('#okay_button').off('click').on 'click', (e) ->
      hide_overlay(step)
      next_obj.show()

  window.rc_binding_setup = ->
    $('li.ipad_cursor, p.ipad_cursor').off('click').on 'click', (e) ->
      dialog_id = ''
      dialog_id = $(this).attr('id').split('_')[1]

      config =
        trigger_element: $(this)[0]
        popup_element: $("#rc_dialog_" + dialog_id)[0]
        overlay: true
        popup_class: 'modal_popup_wrapper rc_modal'
        close_button_class: 'modal_close_button'

      if $(this).is('.ie_challenge_question_box, .ie_challenge_question')
        config["open"] = open_ie_question
      if $(this).is('.atw_challenge_container, .atw_challenge_question_box')
        config["open"] = open_atw_question

      window.rc_modals.questions[dialog_id] = window.rc_modals.questions[dialog_id] || new Modal(config)

      if !rc_modals.questions[dialog_id].initialized
        rc_modals.questions[dialog_id].init()
        if $(rc_modals.questions[dialog_id].popup_element).find('.challenge_image img').length > 0
          build_zoom_tool window.rc_modals.questions[dialog_id].popup_element
      else
        window.rc_modals.questions[dialog_id].open()

      window.current_open_modal = window.rc_modals.questions[dialog_id]

    open_factoid = ->
      if window.current_open_modal?
        $(window.current_open_modal.popup_element).parent().css('z-index', 2)
        $('.magnified_image').remove()
      window.rc_modals.factoid.show()
    close_factoid = ->
      if window.current_open_modal?
        $(window.current_open_modal.popup_element).parent().css('z-index', '')
        $('.magnified_image').css('z-index', '')
      window.rc_modals.factoid.hide()
      if window.current_open_modal?
        build_zoom_tool window.current_open_modal.popup_element

    # setup factoid modal
    config =
      trigger_element: undefined
      popup_element: $("#rc_dialog_factoid")[0]
      overlay: true
      popup_class: 'modal_popup_wrapper rc_modal factoid'
      close_button_class: 'modal_close_button'
      open: open_factoid
      close: close_factoid
    window.rc_modals.factoid = window.rc_modals.factoid || new Modal(config)

    $(".rc_question_submit, .rc_final_question_submit").off('click').on 'click', (e) ->
      dialog_id = ''
      dialog_id = $(this).attr('id').split('_')[3]
      $('#rc_message_' + dialog_id).text(I18n.translate('checking_answer')).css('background-color', 'transparent').removeClass('vis_hide')
      $("<input type='submit'>").css('display','none').appendTo('#form_' + dialog_id).click();
      $(this).text(I18n.translate('saving')).prop('disabled', true)
      $("#question-answers-#{dialog_id} input, #dialog_question_#{dialog_id} input").each(() ->
        $(this).prop('disabled', true)
      )

    $('.lesson-game-answer-choice').on 'click keydown', (e) ->
      if e.type == 'keydown'
        key = e.which || e.keyCode || 0;
        if (key != 32)
          return
      question_num = $(this).data('question-number')
      $("#save_rc_question_#{question_num}").prop('disabled', false);

    #setup instructions modal
    $('.instructions').off('click').on('click', ->
      challenge_abbreviation = $(this).attr('id').split('_')[1]
      config =
        trigger_element: $(this)[0]
        popup_element: $("#rc_instructions_" + challenge_abbreviation)[0]
        overlay: true
        popup_class: 'modal_popup_wrapper rc_modal'
        close_button_class: 'modal_close_button'

      window.rc_modals.instructions = window.rc_modals.instructions || new Modal(config)

      if !rc_modals.instructions.initialized
        rc_modals.instructions.init()
      else
        window.rc_modals.instructions.show()
    )

    $('.challenge_question_box, .ctc_challenge_question_box').on('click', ->
      $('.dialog_answers :input').blur()
    )


  build_zoom_tool = (div) ->
    iOS = false
    p = navigator.platform
    if( p == 'iPad' || p == 'iPhone' || p == 'iPod' )
      iOS = true

    div_obj = $(div).find(" .challenge_image")
    $(div_obj).attr('id', (new Date).getTime())
    if !iOS
      zoomed_image = new Magnifier(
        type: 'challenge'
        div_obj: '#' + $(div_obj).attr('id')
        tool_radius: 100
      )
      zoomed_image.init()

  bonus_question_warning_popup = ->
    $(window).on('click', (e) ->
      if $('.js_bonus_question_warning').is(':visible')
        $('.js_bonus_question_warning').fadeOut(100)
      else
        if $(e.target).hasClass('bonus_hide')
          $('.js_bonus_question_warning').fadeIn(50)
    )

  # After a gif animates, its animation status is persisted until a hard refresh.
  # By cache busting the final question gif, we force it to animate each time
  # the page is loaded, whether it is a hard or normal refresh.
  cache_bust_chtcb_final_question_gif = ->
    if $('.chtcb_challenge_bonus').length > 0
      background_image_url = $('.chtcb_challenge_bonus').css('background-image').slice(5, -2) + '?' + Math.floor(Date.now() / 1000)
      new_background_image_url = 'image-url("' + background_image_url + '")'
      $('.chtcb_challenge_bonus').css('background', new_background_image_url)

  init: ->
    rc_binding_setup()
    cache_bust_chtcb_final_question_gif()
    bonus_question_warning_popup()
    if $("li.sr_answered").length > 0
      $("li.sr_answered").sparkleh({
        color:  ["#f1e2c6", "#cf5a37", "#875764", "#c5958f", "#771e0e", "#401829", "#3a0e0c", "#fcf358", "#f8e8c9", "#7f3b3d"],
        count: 10,
        overlap: 0
      });
