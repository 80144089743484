const inflection = require('inflection');
const React = require('react');
const ReactDOM = require('react-dom');
const TTSButton = require('../components/shared/TextToSpeechButton').default;

define(['Vendor/modules/BrowserDetect', 'modules/SlideshowImage'], function(BrowserDetect, SlideshowImage) {
  function GlossaryTerms() {
    $('span.glossary').each(function() {
      const glossary_term = $.trim($(this).text()).replace(/[^\w\s-]/g, '').replace(/[\s-]/g, '_').toLowerCase();
      let glossary_term_singular;

      // This is a quick patch to deal with words that inflection.js doesn't handle.
      // It will need to be expanded/refactored as more trouble words show up
      if (glossary_term === 'reprieves') {
        glossary_term_singular = 'reprieve';
      }
      else if (inflection.singularize(glossary_term) !== glossary_term) {
        glossary_term_singular = inflection.singularize(glossary_term);
      }
      else {
        glossary_term_singular = glossary_term;
      }

      $(this).addClass(`glossary_${glossary_term}`);
      $(this).addClass(`glossary_${glossary_term_singular}`);

      // Move glossary terms to after the word
      $(`.glossary_${glossary_term}.definition`).insertAfter(`.glossary_${glossary_term}.glossary`);
      $(`.glossary_${glossary_term_singular}.definition`).insertAfter(`.glossary_${glossary_term_singular}.glossary`);
      // toggle visible and invisible

      const doesNotEndInS = (glossary_term === glossary_term_singular);

      if (doesNotEndInS) {
        $(`.glossary_${glossary_term}.glossary`).on('click', function() {
          if ($(`.glossary_${glossary_term}.definition:visible`).length > 0) {
            $(`.glossary_${glossary_term}.definition`).hide();
          }
          else {
            $(`.glossary_${glossary_term}.definition`).show();
          }
        });
      }
      else {
        $(`.glossary_${glossary_term_singular}.glossary`).on('click', function() {
          if (($(`.glossary_${glossary_term_singular}.definition:visible`).length > 0) ||
            ($(`.glossary_${glossary_term}.definition:visible`).length > 0)) {
            $(`.glossary_${glossary_term}.definition`).hide();
            $(`.glossary_${glossary_term_singular}.definition`).hide();
          }
          else {
            $(`.glossary_${glossary_term}.definition`).show();
            $(`.glossary_${glossary_term_singular}.definition`).show();
          }
        });
      }
    });

    // Close on click
    $('.close_box').on('click', function() {
      $(this).parent().hide();
    });

    // Close on ESC
    document.addEventListener('keydown', (event) => {
      if ($('.close_box').length && (['Escape', 'Esc'].includes(event.key))) {
        event.preventDefault();
        $('.close_box').parent().hide();
      }
    });

    // Close on click outside element
    $('div.definition').each((i, definition) => {
      const el = $(definition);

      document.addEventListener('mousedown', (event) => {
        if (
          el.css('display') !== 'none' &&
          !el.is(event.target) &&
          el.has(event.target).length === 0 &&
          !$('.close_box').is(event.target)
        ) el.hide();
      });
    });
  }

  // Glossary Cards
  const GlossaryCardGame = function() {
    this.cards = [];
    this.current_card = 0;
    this.last_card = false;
    this.first_card = true;
    this.alternate_version = false;
  };

  GlossaryCardGame.prototype = {
    init: function(json) {
      const json_array = JSON.parse(json);

      this.current_card = 0;
      this.cards.push(json_array[0]);
      this.populate_card(json_array[0]);

      for (let i = 0; i < json_array.length; i++) {
        for (let j = 0; j < this.cards.length; j++) {
          if (json_array[i].term.toLowerCase() === this.cards[j].term.toLowerCase()) {
            break;
          }
          else if (j === this.cards.length - 1) {
            this.cards.push(json_array[i]);
          }
        }
      }

      if (this.cards.length === 1) {
        this.last_card = true;
        this.first_card = true;
        $('.next_card').attr('disabled', true);
      }

      // Set up listeners
      $('#flip_button').on('click', function() {
        this.flip_card();
      }.bind(this));
      $('.next_card').on('click', function() {
        this.next_card();
      }.bind(this));
      $('.previous_card').on('click', function() {
        this.previous_card();
      }.bind(this));
      $('#view_alternate_link').on('click', function() {
        this.switch_to_alternate_view();
        $('#alternate_link_message').html('<strong>Viewing alternate card version.</strong> Clear your browser\'s cookies to view the full feature.');
      }.bind(this));
    },

    switch_to_alternate_view: function() {
      this.alternate_version = true;
      if (!CheckForCardCookie()) {
        SetCardCookie('alt_view', 'xp', 1);
      }
      $('#alternate_link_message').html('<strong>Viewing alternate card version.</strong> Clear your browser\'s cookies to view the full feature.');
      $('#card').removeClass('flipped')
        .addClass('card_alt');
    },

    populate_card: function(card) {
      $('#card .front').html("<span id='card-term'>" + card.term + "</span>" + "<span class='tts-container' id='tts-container-front'></span>");
      $('#card .front').attr('aria-hidden', false);
      $('#card .front').attr('tabIndex', '0');
      $('#card .back').html("<span id='card-definition'>" + card.definition + "</span>" + "<span class='tts-container' id='tts-container-back'></span>");
      $('#card .back').attr('aria-hidden', true);
      $('#card .back').attr('tabIndex', '-1');

      const text_speech_enabled = this.is_text_to_speech_enabled();
      if (text_speech_enabled) this.setup_card_text_to_speech();

      if (card.s3_url !== null && card.s3_url !== 'missing.png') {
        const img = new Image();
        img.src = card.s3_url;
        $(img).attr('data-zoomurl', card.s3_url);
        new SlideshowImage().init(img);
        $('#card .back').prepend(img);
      }
    },

    setup_card_text_to_speech: function () {
      $(document).ready(() => {
        const front_container = document.getElementById('tts-container-front');
        const back_container = document.getElementById('tts-container-back');
        const user_locale = this.get_user_locale();
        $('.tts-container').css({marginLeft: '8px'});

        ReactDOM.render(<TTSButton
            idToRead={'card-term'}
            locale={user_locale}
        />, front_container);

        ReactDOM.render(<TTSButton
            idToRead={'card-definition'}
            locale={user_locale}
        />, back_container);
      })
    },

    is_text_to_speech_enabled: function () {
      return $('#card').attr('audio-enabled') === 'true' || false
    },

    get_user_locale: function () {
      return $('#card').attr('locale') || 'en'
    },

    toggle_screenreader_attrs: function() {
      const front = $('#card .front');
      const back = $('#card .back');
      const card = $('#card');

      if (card.hasClass('flipped')) {
        front.attr('aria-hidden', 'false');
        front.attr('tabIndex', '0');
        back.attr('aria-hidden', 'true');
        back.attr('tabIndex', '-1');
      }
      else {
        front.attr('aria-hidden', 'true');
        front.attr('tabIndex', '-1');
        back.attr('aria-hidden', 'false');
        back.attr('tabIndex', '0');
      }
    },

    flip_card: function() {
      this.toggle_screenreader_attrs();
      $('#card').toggleClass('flipped');
    },


    next_card: function() {
      // if you're on the last card
      if (this.last_card) {
        return false;
      }

      // if you're not on the last card already
      this.current_card++;
      this.first_card = false;
      $('.previous_card').attr('disabled', false);
      $('#card').removeClass('flipped');
      this.populate_card(this.cards[this.current_card]);

      // if you're moving to the last card
      if (this.current_card === this.cards.length - 1) {
        this.last_card = true;
        $('.next_card').attr('disabled', true);
      }
    },

    previous_card: function() {
      // if you're on the first card
      if (this.first_card) {
        return false;
      }

      // if you're not on the last card already
      this.current_card--;
      this.last_card = false;
      $('.next_card').attr('disabled', false);
      $('#card').removeClass('flipped');
      this.populate_card(this.cards[this.current_card]);

      // if you're moving to the last card
      if (this.current_card === 0) {
        this.first_card = true;
        $('.previous_card').attr('disabled', true);
      }
    }
  };

  function SetCardCookie(c_name, value, exdays) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    const c_value = escape(value) + ((exdays == null) ? '' : `; expires=${exdate.toUTCString()}`);
    document.cookie = `${c_name}=${c_value}`;
  }

  function CheckForCardCookie() {
    const cookiecontent = GetCardCookie('alt_view');
    return cookiecontent === 'xp';
  }

  function GetCardCookie(c_name) {
    let i;
    let x;
    let y;
    const ARRcookies = document.cookie.split(';');
    for (i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
      x = x.replace(/^\s+|\s+$/g, '');
      if (x === c_name) {
        return unescape(y);
      }
    }
  }

  // Glossary
  function GlossaryLetterSelect() {
    const $glossary_words = $('.glossary_words_js');
    $glossary_words.removeClass('hide');

    // Listeners to change selected letter/displayed words
    $('.glossary_letters_js').on('click', function() {
      const letter = $(this).attr('id').split('_')[1];

      $glossary_words.addClass('hide');
      $('.glossary_letters_js').removeClass('selected_glossary_letter');

      const glossary_words = `.glossary_words_${letter.toLowerCase()}`;
      $(`#glossary_${letter}`).addClass('selected_glossary_letter');

      if (letter === 'ALL') {
        // Make all words visible.
        $glossary_words.removeClass('hide');
      }
      else {
        // Only make the selected letter's words visible.
        $(glossary_words).removeClass('hide');
      }
    });
  }

  function RemoveGlossaryDuplicates() {
    const list = [];

    $('.glossary_words_js').each(function() {
      const term = $(this).find('span')
        .text()
        .toLowerCase()
        .trim();

      // Check to see if we've run across this term before
      if (list.indexOf(term) === -1) {
        // If not, add to our list
        list.push(term);
      }
      else {
        // If so, remove the element from the page
        $(this).remove();
      }
    });
  }

  function replaceGlossaryLinks() {
    if ($('#student_text_glossary_link').length > 0 && $('a[data-glossarylink]').length > 0) {
      const glossary_href = $('#student_text_glossary_link').text();

      $('a[data-glossarylink]').each(function() {
        $(this).attr('href', glossary_href);
        $(this).attr('target', '_blank');
      });
    }
  }

  function insertVocabCardsLink() {
    if ($('#student_text_vocab_cards_link').length > 0 && $('#dynamic-vocab-cards-link').length > 0) {
      const vocabCardsHref = $('#student_text_vocab_cards_link').attr('href');
      $('#dynamic-vocab-cards-link').attr('href', vocabCardsHref);
      $('#dynamic-vocab-cards-link').attr('target', '_blank');
    }
  }

  return {
    init: function() {
      GlossaryTerms();
      replaceGlossaryLinks();
      insertVocabCardsLink();

      if ($('.glossary_words_js').length > 0) {
        GlossaryLetterSelect();
        RemoveGlossaryDuplicates();
      }

      if ($('#glossary_card').length > 0) {
        glossary_card_game = new GlossaryCardGame;
        glossary_card_game.init($('#glossary_terms_json').attr('data-json'));
      }
    }
  };
});
