define([], function() {
  function buildImageCheckboxGame(game_data, game_id) {
    var content_block = '',
      game_question = game_data[1],
      numCorrect = 0,
      is_correct = '';

    content_block += '<div class="ic_answer_wrapper">';

    for (var i in game_data[0]) {
      is_correct = '';
      i = parseInt(i, 10);

      if (i % 3 == 0) {
        content_block += '<div class="clear mb5"></div>';
      }

      if (game_data[0][i].correct == 1) {
        numCorrect++;
        is_correct = 'data-correct="true"';
      }

      content_block += '<div class="ic_answer ic_answer_' + (i + 1) + '" ' + is_correct + '>';

      content_block += '<img src="' + game_data[0][i].img + '" />';
      // Checkbox, response
      content_block += '<div class="ic_check_box">' +
      '<div><i class="fa fa-times"></i><i class="fa fa-check"></i></div>' +
      '</div>';
      content_block += '<div class="ic_response">' + game_data[0][i].text + '</div></div>';
    }

    content_block += '</div><a class="ic_check_answer">Check Answer</a>' +
    '<div class="ic_warning">You may only select ' + numCorrect + ' answers.</div>';

    content_block = '<div class="ic_question interaction_question">' + game_question + ' (Choose '+ numCorrect +')</div>' +
    content_block;

    content_block += "<div class='it_dialog_box'></div>";
    $('#interaction_' + game_id).html(content_block);

    return numCorrect;
  }

  return {
    build: buildImageCheckboxGame
  }
});