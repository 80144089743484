define [], ->
  move_toolbar = ->
    return unless $('#section_text_outer_container').length

    $(window).scroll ->
      top_offset = $(window).scrollTop()
      content_offset = 200
      content_height = $('#section_text_outer_container').outerHeight()
      left_offset = $('#section_text_container').offset().left;
      default_left = 0
      $toolbar = $('#th-controls-toolbar')

      if ((top_offset > content_offset) && (top_offset < content_height + content_offset))
        $toolbar.css({
          'position': 'fixed',
          'margin': '0 auto',
          'top': if $toolbar.hasClass('impersonating') then 50 else 0,
          'left': left_offset + default_left
        }).addClass('sticking')
      else if (top_offset >= (content_height + content_offset))
        $toolbar.css({
          'top': '-50px',
          'left': left_offset + default_left
        }).removeClass('sticking')
      else
        $toolbar.css({
          'position': 'static',
          'top': '-50px',
          'left': default_left
        }).removeClass('sticking')

  init: ->
    move_toolbar()
