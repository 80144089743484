define([], function() {
  function classroomDropdown() {
    $('input#student_teacher_email.classroomDropdown_target').on('blur', function() {
      $('.lock_message').remove();
      const teacher_email = $(this).val();
      if (teacher_email === '') {
        $('select#new_student_classroom_id option').remove();
      }
      else {
        const url = $('span#ajax_url').html();
        $.ajax({
          dataType: 'json',
          cache: false,
          url: `${url}?teacher_email=${teacher_email}`,
          timeout: 2000,
          error: function() {
            alert('The teacher could not be found. Please enter a valid email or username.');
          },
          success: function(data) {
            $('select#new_student_classroom_id option').remove();
            if (data.length === 0) {
              alert('The teacher could not be found. Please enter a valid email or username.');
            }
            else if (data.indexOf('Teacher has locked this classroom.') !== -1) {
              $("<p class='lock_message'>" +
                'Your teacher has turned off student signups. ' +
                'Please contact your teacher.</p>').insertAfter('#student_teacher_email');
            }
            else {
              $.each(data, function(i, j) {
                $(`<option value=${j.id}>${j.name}</option>`).appendTo('select#new_student_classroom_id');
              });
            }
          }
        });
        return false;
      }
      return true;
    });
  }

  return {
    init: function() {
      classroomDropdown();
    }
  };
});
