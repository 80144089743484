define([], function() {
  function init() {
    $('[data-tci-validate]').each(function() {
      setValidation($(this));
    });
  }

  function setValidation($element) {
    var options = $element.data('tci-validate'),
        validation_group = [],
        obj;

    for (var opt in options) {
      obj = {};
      if (validations.hasOwnProperty(opt)) {
        obj[opt] = options[opt];
        validation_group.push(obj);
      }
    }

    $element.on('keydown', function(e) {
      for (var i = 0; i < validation_group.length; i++) {
        for (var func in validation_group[i]) {
          if (func === 'type') {
            if (!validations[func](e.keyCode, $(this).data('tci-validate').type)) {
              e.preventDefault();
            }
          }
        }
      }
    });

    $element.on('keyup blur', function() {
      for (var i = 0; i < validation_group.length; i++) {
        for (var func in validation_group[i]) {
          if (func === 'min') {
            if (!validations[func]($(this).val(), $(this).attr('min')) && $(this).val()) {
              $(this).val($(this).attr('min'));
            }
          }
          else if (func === 'max') {
            if (!validations[func]($(this).val(), $(this).attr('max')) && $(this).val()) {
              $(this).val($(this).attr('max'));
            }
          }
        }
      }
    });
  }

  var validations = {
    min: function(int, min) {
      return (parseInt(int) >= parseInt(min))
    },

    max: function(int, max) {
      return (parseInt(int) <= parseInt(max))
    },
    type: function(keyCode, type) {
      if (type === 'integer') {
        if (keyCode === 8 || keyCode === 9 ) return true;           //backspace/delete, tab
        else if ((keyCode >= 37) && (keyCode <= 40)) return true;   //arrow keys
        else if ((keyCode >= 48) && (keyCode <= 57)) return true;   //top 0-9
        else if ((keyCode >= 96) && (keyCode <= 105)) return true;  //windows numpad 0-9
      }
      else return false;
    }
  };

  return {
    init: init
  }
});