define(['StudentTextImagePopup'], function(StudentTextImagePopup) {

  function StudentTextImage(config) {
    this.container = config.element;
  }

  StudentTextImage.prototype = {
    init: function() {
      let this_obj = this;

      // Grab necessary data from main div

      // `this.container` could be a container or the <img> tag which itself,
      // and the presence of the `data-image-id` attribute distinguishes this case.
      this.id = this.container.getAttribute('data-image-id') ||
                  this.container.getAttribute('id').split('_')[2];
      this.caption = this.container.getAttribute('data-student-text-caption');
      this.credit = this.container.getAttribute('data-student-text-credit');
      this.zoom_type = this.container.getAttribute('data-zoom-type');
      this.large_src = this.container.getAttribute('data-student-text-large-src');
      this.original_src = this.container.getAttribute('data-student-text-original-src');
      this.src = this.container.getAttribute('data-student-text-src');
      this.alt = this.container.getAttribute('data-student-text-alt');
      this.padding = {
        left: 15,
        right: 15,
        top: 15,
        bottom: 15
      };
      this.border_widths = {
        left: 1,
        right: 1,
        top: 1,
        bottom: 1
      };

      // If the container is itself an img, no need to create and append one
      if (!this.container.getAttribute('data-image-id')) {
        // Build inline image and append it to container div
        this.small_image = document.createElement('img');
        this.small_image.src = this.src;
        this.small_image.alt = this.alt;
        this.small_image.setAttribute('ignore', 'ignore');
        this.container.appendChild(this.small_image);
      }

      this._setImageLoader();

      // Create popup object tied to this image
      this.popup = new StudentTextImagePopup({ parent: this, id: this.id });
      this.popup.init();

      // Event listeners
      $(this.container).on('click',
        (function(this_obj) {
          return function (ev) {
            this_obj.popup.open();
          };
        }) (this_obj)
      );
    },

    // Check for existing image loader div, otherwise create
    _setImageLoader: function() {
      this.image_loader = $('#image_loader')[0];

      if (this.image_loader == undefined) {
        this.image_loader = document.createElement('div');
        this.image_loader.id = 'image_loader';
        document.body.appendChild(this.image_loader);
      }
    },
  };

  return StudentTextImage
});
