define(['TCIUtils'], function(TCIUtils) {
  function truncate_program() {
    var trunc_length = 60;
    $('.js_truncate_program option').each(function () {
      var original_text = $(this).text();
      var fits = '';
      if (original_text.length > trunc_length) {
        fits = '...';
      }
      var trunc_text = original_text.substring(0, trunc_length);
      $(this).text(trunc_text + fits);
    });
  }

  function switch_media_type() {
    $('.js_select_interaction_video').on('click', function () {
      $('#js_interaction_video_form').show();
      $('#js_interaction_aws_form').hide();
      $('#js_interaction_image_form').hide();
    });
    $('.js_select_interaction_image').on('click', function () {
      $('#js_interaction_image_form').show();
      $('#js_interaction_aws_form').hide();
      $('#js_interaction_video_form').hide();
    });
    $('.js_select_interaction_aws').on('click', function() {
      $('#js_interaction_aws_form').show();
      $('#js_interaction_image_form').hide();
      $('#js_interaction_video_form').hide();
    });
  }

//Hotspots

  function record_draggable_placement() {
    var pin_size = parseInt($('.hs_pin').first().css('width'));
    var text_height = parseInt($('.hs_text').first().height());
    $('.admin_hs_pins').draggable({
      revert: 'invalid',
      start: function () {
        $(this).css('z-index', 1000);
      },
      stop: function () {
        $(this).css('z-index', 10);
        var id = $(this).attr('id');
        var id_num = parseInt(id.split('_')[1]);
        var top = parseInt($(this).css('top')) + text_height - 60;
        var left = parseInt($(this).css('left')) + ((id_num - 1) * pin_size) + pin_size / 2;

        hotspot_set_persistent_pin_position(id_num, top, left)

        $('#hs_text_' + (id_num))
          .css({'left': (left), 'top': (top)})
          .fadeIn(100)
          .text($('#interaction_text_' + (id_num-1)).val());

        hotspot_set_persistent_text_position(id_num, top, left)
      }
    });

    $('.admin_hs_text').draggable({
      revert: 'invalid',
      start: function () {
        $(this).css('z-index', 1000);
      },
      stop: function () {
        $(this).css('z-index', 10);
        var id = $(this).attr('id');
        var id_num = id.split('_');
        id_num = parseInt(id_num[id_num.length - 1]);

        var top = parseInt($(this).css('top'));
        var left = parseInt($(this).css('left'));

        if ($('#interaction_text_top_0').length > 0) {
          $('#interaction_text_top_' + (id_num - 1)).val(top);
          $('#interaction_text_left_' + (id_num - 1)).val(left);
        } else {
          $('#hotspot_text_top_' + (id_num - 1)).val(top);
          $('#hotspot_text_left_' + (id_num - 1)).val(left);
        }
      }
    });

    $('.admin_hs_dropzone').droppable({
      over: function(e, ui) {
        if (e.target.id == "admin_hs_trash") {
          reset_cdnd_to_origin($(ui.draggable))
          var id = $(ui.draggable).attr('id');
          var id_num = parseInt(id.split('_')[1]);
          $('#hs_text_' + (id_num))
            .css('display','none');
          hotspot_set_persistent_pin_position(id_num, null, null)
          hotspot_set_persistent_text_position(id_num, null, null)
        }
      }
    });
  }

  function hotspot_set_persistent_pin_position(id_num, top, left)
  {
    $('#interaction_hs_top_' + (id_num - 1)).val(top);
    $('#interaction_hs_left_' + (id_num - 1)).val(left);

    $('#hotspot_hs_top_' + (id_num - 1)).val(top);
    $('#hotspot_hs_left_' + (id_num - 1)).val(left);
  }

  function hotspot_set_persistent_text_position(id_num, top, left)
  {
    $('#interaction_text_top_' + (id_num - 1)).val(top);
    $('#interaction_text_left_' + (id_num - 1)).val(left);

    $('#hotspot_text_top_' + (id_num - 1)).val(top);
    $('#hotspot_text_left_' + (id_num - 1)).val(left);
  }

  function update_textbox_width_init() {
      $('[id*=hotspot_width]').blur(function (e) {
          set_textbox_width_helper(e.target);
      })
      $('[id*=interaction_width]').blur(function (e) {
          set_textbox_width_helper(e.target);
      })
  }

  function reset_textbox_width()
  {
    var width_inputs = $('[id*=interaction_width]');
    $.each( width_inputs, function(index, element){
        set_textbox_width_helper(element) } );
  }

  function set_textbox_width_helper(element)
  {
    var id = $(element).attr('id');
    var id_num = id.split('_');
    id_num = parseInt(id_num[id_num.length - 1]);
    var width = element.value;
      if (width){
      $('#hs_text_' + (id_num + 1))
          .css('width', width + '%');
    }
  }

    /*****************************************************************/
  /************************ File Selection *************************/
  /*****************************************************************/


  function handleItFileSelect(evt, node) {
    var f = evt.target.files[0];
    var reader = new FileReader();

    reader.onload = (function () {
      return function (e) {
        $('.admin_wysiwyg_dropzone img').remove();
        $('.admin_wysiwyg_dropzone').css('background-image', 'url(' + e.target.result + ')');
      };
    })(f);

    reader.readAsDataURL(f);
  }

  function addHandleFileSelectListener(node){
    if ($(node).length > 0) {
      $(node)[0].addEventListener('change', handleItFileSelect, false);
    } else if ($('#interaction_interaction_images_attributes_0_interaction_image').length > 0) {
      $('#interaction_interaction_images_attributes_0_interaction_image')[0].addEventListener('change', handleItFileSelect, false);
    }
  }

  /*****************************************************************/
  /************************** Info Popup ***************************/
  /*****************************************************************/


// when a popup text is fill ->
//   - place value into #ip_text html
// when a pin is moved ->
//   - assign new top & left to .info_popup_ip_left_ + id

  function record_popup_drag(){

    var pin_size = parseInt($('.ip_pin').first().css('width'));
    var text_height = parseInt($('.ip_text').first().height());

    $('.admin_ip_pins').draggable({
      revert: 'invalid',
      start: function () {
        $(this).css('z-index', 1000);
      },
      stop: function () {
        $(this).css('z-index', 10);
        var id = $(this).attr('id');
        var id_num = parseInt(id.split('_')[1]);
        var top = parseInt($(this).css('top')) + text_height + 550;
        var left = parseInt($(this).css('left')) + ((id_num - 1) * pin_size) + pin_size / 2;

        $('#interaction_ip_top_' + (id_num - 1)).val(top);
        $('#interaction_ip_left_' + (id_num - 1)).val(left);

        $('#info_popup_ip_top_' + (id_num - 1)).val(top);
        $('#info_popup_ip_left_' + (id_num - 1)).val(left);

        $('#ip_text_' + (id_num))
          .css({'left': (left), 'top': (top)})
          .fadeIn(100)
          .text($('#interaction_ip_text_' + (id_num - 1)).val());

        $('#interaction_text_left_' + (id_num - 1)).val(left);
        $('#interaction_text_top_' + (id_num - 1)).val(top);

        $('#info_popup_text_left_' + (id_num - 1)).val(left);
        $('#info_popup_text_top_' + (id_num - 1)).val(top);
      }
    });

    $('.admin_ip_text').draggable({
      revert: 'invalid',
      start: function () {
        $(this).css('z-index', 1000);
      },
      stop: function () {
        $(this).css('z-index', 10);
        var id = $(this).attr('id');
        var id_num = id.split('_');
        id_num = parseInt(id_num[id_num.length - 1]);

        var top = parseInt($(this).css('top'));
        var left = parseInt($(this).css('left'));

        if ($('#interaction_text_top_0').length > 0) {
          $('#interaction_text_top_' + (id_num - 1)).val(top);
          $('#interaction_text_left_' + (id_num - 1)).val(left);
        } else {
          $('#info_popup_text_top_' + (id_num - 1)).val(top);
          $('#info_popup_text_left_' + (id_num - 1)).val(left);
        }
      }
    });

    $('.admin_ip_dropzone').droppable();
  }


  function placePopups(){
    var pin_size = parseInt($('.ip_pin').first().css('width'));
    var text_height = parseInt($('.ip_text').first().css('height'));

    //Replace pins and text on Edit page.
    if ($('#interaction_ip_left_0').length > 0) {
      for (var i = 0; i < 4; i++) {
        var top = $('#interaction_ip_top_' + i).val() - text_height;
        var left = $('#interaction_ip_left_' + i).val() - pin_size / 2;

        var text_top = $('#interaction_text_top_' + i).val();
        var text_left = $('#interaction_text_left_' + i).val();

        if (top != -text_height) {
          $('#pin_' + (i + 1)).css({
            'left': (left - (i * pin_size)) + 'px',
            'top': (top - 550) + 'px'
          });

          if ($('#interaction_text_top_' + i).val()) {
            $('#ip_text_' + (i + 1)).css({
              'display': 'block',
              'left': (text_left) + 'px',
              'top': (text_top) + 'px'
            }).text($('#interaction_ip_text_' + i).val());
          }
        }
      }
      //Replaces pins on the create page if you've triggered an error.
    } else if (('#info_popup_ip_top_0').length > 0) {
      for (var i = 0; i < 4; i++) {
        var top = $('#info_popup_text_top_' + i).val() - text_height;
        var left = $('#info_popup_text_left_' + i).val() - pin_size / 2;

        var text_top = $('#info_popup_text_top_' + i).val();
        var text_left = $('#info_popup_text_left_' + i).val();

        if (top != -text_height) {
          $('#ip_' + (i + 1)).css({
            'left': (left - (i * pin_size)) + 'px',
            'top': (top - 550) + 'px'
          });

          if ($('#info_popup_text_top_' + i).val()) {
            $('#ip_text_' + (i + 1)).css({
              'display': 'block',
              'left': (text_left) + 'px',
              'top': (text_top) + 'px'
            })
              .text($('#interaction_ip_text_' + i).val());
          }
        }
      }
    }
  };


  function infoPopupSetUp(){
    this.node = "#info_popup_interaction_images_attributes_0_interaction_image";
    addHandleFileSelectListener(this.node);
    placePopups();
  }

  /* Custom Drag And Drop */

  function dnd_record_draggable_placement() {

    $('.admin_cdnd_tile').draggable({
      revert: 'invalid',
      start: function () {
        $(this).css('z-index', 1000);
      },
      stop: function () {
        $(this).css('z-index', 10);
        var id = $(this).attr('id'),
          id_num = parseInt(id.split('_')[2]),
          top = parseInt($(this).css('top')) + 550,
          left = parseInt($(this).css('left')) + ((id_num - 1) * parseInt($('.admin_cdnd_tile').first().css('width'), 10));

        if (($('#custom_dragdrop_text_text_top_0').length || $('#interaction_text_top_0').length) && id_num > 3) {
          left =  parseInt($(this).css('left')) + ((id_num - 4) * parseInt($('.admin_cdnd_tile').first().css('width'), 10));
          top = parseInt($(this).css('top')) + 550 + parseInt($('.admin_cdnd_tile').first().css('height'), 10);
        }

        $('#custom_dragdrop_image_top_' + (id_num - 1)).val(top);
        $('#custom_dragdrop_image_left_' + (id_num - 1)).val(left);

        $('#interaction_image_top_' + (id_num - 1)).val(top);
        $('#interaction_image_left_' + (id_num - 1)).val(left);

        $('#custom_dragdrop_text_text_top_' + (id_num - 1)).val(top);
        $('#custom_dragdrop_text_text_left_' + (id_num - 1)).val(left);

        $('#interaction_text_top_' + (id_num - 1)).val(top);
        $('#interaction_text_left_' + (id_num - 1)).val(left);
      }
    });

    $('.admin_dnd_dropzone').droppable({
      over: function(e, ui) {
        if (e.target.id == "admin_cdnd_trash") {
          reset_cdnd_to_origin($(ui.draggable))
        }
      }
    });
  }

  function reset_cdnd_to_origin($elem) {
    var id_num = $elem.attr('id').split('_')[2];
    $(document).trigger('mouseup');
    $elem.css('top', 0);
    $elem.css('left', 0);

    $('#custom_dragdrop_text_text_top_' + (id_num - 1)).val('');
    $('#custom_dragdrop_text_text_left_' + (id_num - 1)).val('');
    $('#interaction_text_top_' + (id_num - 1)).val('');
    $('#interaction_text_left_' + (id_num - 1)).val('');
    $('#custom_dragdrop_image_top_' + (id_num - 1)).val('');
    $('#custom_dragdrop_image_left_' + (id_num - 1)).val('');
    $('#interaction_image_top_' + (id_num - 1)).val('');
    $('#interaction_image_left_' + (id_num - 1)).val('');
  }

  function switch_cdnd_game_type() {
    var $selector;

    $selector = ($('#interaction_game_type').length) ? $('#interaction_game_type') : $('#custom_dragdrop_text_game_type');

    if ($selector.val() == 'phrase') {
      toggle_cdnd_game_type('phrase');
    }

    $selector.on('change', function() {
      toggle_cdnd_game_type($(this).val());
    });
  }

  function toggle_cdnd_game_type(game_type) {
    if (game_type == 'phrase') {
      $('.admin_cdnd_tile').addClass('type_phrase');
      $('#cdnd_tile_origin').css({
        width: '800px'
      });
    }
    else {
      $('.admin_cdnd_tile').removeClass('type_phrase');
      $('#cdnd_tile_origin').css({
        width: '900px'
      });
    }
  }

// setValuesFromCheckboxes() will use checkbox values to fill hidden form
  function setValuesFromCheckboxes() {
    $('input[type="checkbox"]').on('click', function() {
      var image_id = $(this).attr('id').split('_')[1],
        checked_tds = [],
        $parent_row = $(this).closest('tr');

      $parent_row.find('input[type="checkbox"]').each(function() {
        if ($(this).is(':checked')) {
          checked_tds.push(parseInt($(this).val(), 10) + 1);
        }
      });
      image_id = parseInt(image_id, 10) - 1;

      if ($('#custom_dragdrop_image_zone_' + image_id).length) {
        $target_input = $('#custom_dragdrop_image_zone_' + image_id);
      }
      else if ($('#interaction_image_zone_' + image_id).length) {
        $target_input = $('#interaction_image_zone_' + image_id);
      }
      else if ($('#custom_dragdrop_text_text_zone_' + image_id).length) {
        $target_input = $('#custom_dragdrop_text_text_zone_' + image_id);
      }
      else if ($('#interaction_text_zone_' + image_id).length) {
        $target_input = $('#interaction_text_zone_' + image_id);
      }
      $target_input.val(checked_tds.join(','));
    });
  }

  function setMCValuesFromCheckboxes() {
    var checked = [],
      stringified,
      $hidden_field;

    if ($('#interaction_correct_option').length){
      $hidden_field = $('#interaction_correct_option');
    }
    else if ($('#interaction_correct').length) {
      $hidden_field = $('#interaction_correct');
    }
    else if ($('#multiple_choice_correct_option').length) {
      $hidden_field = $('#multiple_choice_correct_option');
    }
    else if ($('#multiple_image_choice_correct').length) {
      $hidden_field = $('#multiple_image_choice_correct');
    }

    if ($hidden_field != undefined) {
      if ($hidden_field.val() != '') {
        checked = $hidden_field.val().split(', ');
      }
      if (checked.indexOf('') > -1) {
        checked.splice(checked.indexOf(''), 1);
      }
    }

    $('.mc_game_correct_checkbox_js').on('click', function() {
      var answer = $(this).attr('id').split('_')[1],
        index_val;

      if ($(this).is(':checked')) {
        checked.push(answer);
      }
      else {
        index_val = checked.indexOf(answer);
        checked.splice(index_val, 1);
        if (checked.length == 1 && checked[0] == '') {
          checked = [];
        }
      }

      stringified = checked.join(', ');
      $hidden_field.val(stringified);
    });
  }

  function setMCCheckboxesFromValue() {
    var value = $('#interaction_correct_option').val(),
      split_values;

    if (!(value)) {
      return false;
    }

    split_values = value.split(', ');

    for (var i = 0; i < split_values.length; i++) {
      $('#option_' + split_values[i]).attr('checked', 'checked');
    }
  }

  function setMICCheckboxesFromValue() {
    var value = $('#interaction_correct').val(),
      split_values;

    if (!(value)) {
      return false;
    }

    split_values = value.split(', ');
    for (var i = 0; i < split_values.length; i++) {
      $('#option_' + split_values[i]).attr('checked', 'checked');
    }
  }

  function assignDefaultZones(input_name, type){
    for (var i = 0; i < 6; i++) {
      $(input_name + i).val(i + 1);
      checkCorrectBox(i + 1, [i], type);
    }
  }

  function assignZoneValues(input_name, text) {
    var vals;

    for (var i = 0; i < 6; i++) {
      if( !$(input_name + i).val() ) {
        vals = [];
      }else{
        vals = $(input_name + i).val().split(',');
      }
      vals = vals.map(function(num) {return (parseInt(num, 10) - 1)});
      checkCorrectBox(i + 1, vals, text);
    }
  }

  function checkCorrectBox(row, vals, type) {
    for (var i = 0; i < vals.length; i++) {
      $("#" + type + "_" + row + "_" + vals[i]).prop('checked', true);
    }
  }


  function init() {
    infoPopupSetUp();
    toggleProgramStates();
    displayGradeLevel();

    if ($('.js_truncate_program').length > 0) {
      truncate_program();
    }
    if ($('.js_select_interaction_image').length > 0) {
      switch_media_type();
    }
    if ($('#admin_hs_interaction_content').length > 0) {
      record_draggable_placement();
      update_textbox_width_init();
      reset_textbox_width();
    }
    if ($('#admin_ip_interaction_content').length > 0) {
      record_popup_drag();
    }
    if ($('#interaction_game_type').length || $('#custom_dragdrop_text_game_type').length) {
      switch_cdnd_game_type();
    }
    if ($('#admin_dnd_interaction_content').length > 0) {
      dnd_record_draggable_placement();
      setValuesFromCheckboxes();

      var edit_form = '#interaction_image_zone_',
        new_form  = '#custom_dragdrop_image_zone_',
        edit_text_form = '#interaction_text_zone_',
        new_text_form = '#custom_dragdrop_text_text_zone_';


      if ($(edit_form + "0").length > 0) {
        assignZoneValues(edit_form, 'image');
      }
      else if ($(new_form + "0").length > 0) {
        assignDefaultZones(new_form, 'image');
      }
      else if ($(edit_text_form + "0").length > 0) {
        assignZoneValues(edit_text_form, 'text');
      }
      else if ($(new_text_form + "0").length > 0) {
        assignDefaultZones(new_text_form, 'text');
      }
      else {
        console.log("Unknown form");
      }
    }

    if (($('.aws_video').length > 0) && ($('#slideshow_scrubber').length === 0)) {

      TCIUtils.initVideoPlayers();
    }

    if (($('#multiple_choice_correct_option').length > 0) || ($('#multiple_image_choice_correct').length > 0)) {
      setMCValuesFromCheckboxes();
    }

    if (($('#interaction_correct_option').length > 0) || ($('#interaction_correct').length > 0)) {
      setMCCheckboxesFromValue();
      setMCValuesFromCheckboxes();
      setMICCheckboxesFromValue();
    }

    addHandleFileSelectListener('#hotspot_interaction_images_attributes_0_interaction_image');

    if ($('#custom_dragdrop_text_interaction_images_attributes_0_interaction_image').length ) {
      addHandleFileSelectListener('#custom_dragdrop_text_interaction_images_attributes_0_interaction_image')
    }

    var pin_size = parseInt($('.hs_pin').first().css('width'));
    var text_height = parseInt($('.hs_text').first().css('height'));

    /*****************************************************************/
    /*************************** Hot Spots ***************************/
    /*****************************************************************/
    //Replace pins and text on Edit page.
    if ($('#interaction_hs_left_0').length > 0) {
      for (var i = 0; i < 4; i++) {
        var top = $('#interaction_hs_top_' + i).val() - text_height;
        var left = $('#interaction_hs_left_' + i).val() - pin_size / 2;

        var text_top = $('#interaction_text_top_' + i).val();
        var text_left = $('#interaction_text_left_' + i).val();

        if (top != -text_height) {
          $('#hs_' + (i + 1)).css({
            'left': (left - (i * pin_size)) + 'px',
            'top': (top + 60) + 'px'
          });

          if ($('#interaction_text_top_' + i).val()) {
            $('#hs_text_' + (i + 1)).css({
              'display': 'block',
              'left': (text_left) + 'px',
              'top': (text_top) + 'px'
            })
              .text($('#interaction_text_' + i).val());
          }
        }
      }
      //Replaces pins on the create page if you've triggered an error.
    } else if (('#hotspot_hs_top_0').length > 0) {
      for (var i = 0; i < 4; i++) {
        var top = $('#hotspot_text_top_' + i).val() - text_height;
        var left = $('#hotspot_text_left_' + i).val() - pin_size / 2;

        var text_top = $('#hotspot_text_top_' + i).val();
        var text_left = $('#hotspot_text_left_' + i).val();

        if (top != -text_height) {
          $('#hs_' + (i + 1)).css({
            'left': (left - (i * pin_size)) + 'px',
            'top': (top + 60) + 'px'
          });

          if ($('#hotspot_text_top_' + i).val()) {
            $('#hs_text_' + (i + 1)).css({
              'display': 'block',
              'left': (text_left) + 'px',
              'top': (text_top) + 'px'
            })
              .text($('#interaction_text_' + i).val());
          }
        }
      }
    }

    addHandleFileSelectListener('#custom_dragdrop_interaction_images_attributes_0_interaction_image');

    var tile_width = parseInt($('.admin_cdnd_tile').first().css('width')),
      tile_height = parseInt($('.admin_cdnd_tile').first().css('height'));

    // Initialize tile positions on edit
    if ($('#interaction_image_left_0').length > 0) {
      for (var i = 0; i < 8; i++) {
        var top = $('#interaction_image_top_' + i).val();
        var left = $('#interaction_image_left_' + i).val();

        if (top != "") {
          $('#dnd_tile_' + (i + 1)).css({
            'left': (left - (i * tile_width)) + 'px',
            'top': (top - 550) + 'px'
          }).text(i + 1);
        }
      }
    }

    if ($('#interaction_text_left_0').length > 0) {
      for (var i = 0; i < 8; i++) {
        var top = $('#interaction_text_top_' + i).val();
        var left = $('#interaction_text_left_' + i).val();

        if (top != "") {
          $('#dnd_tile_' + (i + 1)).css({
            'left': (left - ((i % 3) * tile_width)) + 'px',
            'top': (top - 550) + 'px'
          }).text(i + 1);
        }
      }
    }

    if ($('#admin_dnd_background img').length > 0) {
      $('#admin_dnd_background img').prependTo('#admin_dnd_interaction_content');
    }

    if ($('#custom_dragdrop_image_left_0, #custom_dragdrop_text_text_left_0').length > 0) {

      $('.admin_cdnd_tile').each(function () {
        $(this).text($(this).attr('id').split('_')[2]);
      });
    }

    $('select[name="per_page"]').on("change", function(e) {
      var form = $(e.target).parent('form');
      $("<input type='submit'>").css('display','none').appendTo(form).click();
    });
  }

  function toggleProgramStates() {
    const $availableCheckbox = $('#program-available-by-default');
    if (!$availableCheckbox.length) return;

    const $statesList = $('#program-state-list');

    if ($availableCheckbox.is(':not(:checked)')) $statesList.toggle();

    $availableCheckbox.change(() => {
      $statesList.toggle();
    });
  }

  // This function is for the program form and displays a grade level range based on grade.
  function displayGradeLevel() {
    const $gradeSelect = $('#program_grade');
    if (!$gradeSelect.length) return;

    setGradeLevelForValue();
    $gradeSelect.change(setGradeLevelForValue);
  }

  function setGradeLevelForValue() {
    const $gradeSelect = $('#program_grade');
    const $gradeLevelEl = $('#program_grade_level_label');
    if (!$gradeSelect.length || !$gradeLevelEl.length) return;

    const currentValue = $gradeSelect.val();
    const value = gradeLevelForValue(currentValue);
    $gradeLevelEl.text(value ? `Grade Level: ${value}` : '');
  }

  function gradeLevelForValue(value) {
    if (value == null || value === '') return;

    let gradeLevel;
    if (['0', '1', '2'].includes(value)) {
      gradeLevel = 'K-2';
    } else if (['3', '4', '5'].includes(value)) {
      gradeLevel = '3-5';
    } else if (['6', '7', '8'].includes(value)) {
      gradeLevel = '6-8';
    } else if (['9', '10', '11', '12'].includes(value)) {
      gradeLevel = '9-12';
    }

    return gradeLevel;
  }

  return {
    init: init
  };
});
