const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

export class FilterTag {
  constructor(config) {
    this.filter_el = config.el;
    this.container = config.container;
    this.text = config.text;
    this.callback = config.callback;
    this.type = config.type;
    this.id = config.id;
    this.parent = config.parent;
    this.alias = config.alias;
  }

  init() {
    this._build();
    this._setupListener();
  }

  _build() {
    this.el = document.createElement('div');
    this.el.className = 'filter-tag';
    this.el.innerHTML = this.parseText(this.text);
    this.container.appendChild(this.el);
  }

  destroy() {
    this.el.removeEventListener('click', this.callback);
    this.container.removeChild(this.el);
  }

  parseText() {
    let parsed = '';

    switch (this.type) {
      case 'lessons':
        parsed = `Lesson: ${this.text}`;
        break;
      case 'created_by':
        parsed = `Created By: ${this.text}`;
        break;
      case 'kinds':
        parsed = `Response Type: ${this.text}`;
        break;
      case 'exercises':
        parsed = `Source: ${this.alias}`;
        break;
      case 'knowledge_depth_levels':
        parsed = `Depth of Knowledge: ${this.text}`;
        break;
      case 'tag':
        parsed = `Tag: ${this.text}`;
        break;
      case 'categories':
        parsed = `Category: ${this.text}`;
        break;
      default:
        parsed = 'Unknown';
        break;
    }

    return `${parsed} <i class="fa fa-times" /i>`;
  }

  _setupListener() {
    this.el.addEventListener('click', () => {
      this.callback.call(this.parent, this.id, true);
    });
  }
}

export class FilterForm {
  constructor(config) {
    this.$items = config.$items;
    this.tags_container = config.tags_container;
    this.ajax_url = config.ajax_url;
    this.form = config.form;
    this.initial_lesson = config.initial_lesson;
    this.initial_exercise_type = config.initial_exercise_type;
    this.initial_category = config.initial_category;
    this.initial_created_by = config.initial_created_by;
  }

  init() {
    this.filter_tags = {};
    this.disable_submit = false;

    // Fight the good fight against unwanted form caching
    this.$items.each(function() {
      $(this)[0].checked = false;
    });

    this._buildHTML();
    this._setupListeners();
    this._setDefaultFilters();
  }

  _buildHTML() {
    this.clear_all = document.createElement('span');
    this.clear_all.className = 'clear-all';
    this.clear_all.innerHTML = 'Clear all';
    this.tags_container.appendChild(this.clear_all);
  }

  _setupListeners() {
    $(this.clear_all).on('click', () => {
      Object.keys(this.filter_tags).forEach((tag) => {
        this.removeFilter(this.filter_tags[tag].id, false);
      });
      this.submitForm();
    });

    this.$items.on('click', (e) => {
      if (this.disable_submit) {
        e.preventDefault();
        return;
      }

      this.disable_submit = true;

      if ($(e.target)[0].checked) {
        this.createFilter(e.target);
        this.submitForm();
      }
      else {
        this.removeFilter(e.target.id, true);
      }
    });
  }

  _setDefaultFilters() {
    if (this.initial_lesson && this.initial_exercise_type) {
      $(`#lesson-${this.initial_lesson}`).prop('checked', true);
      this.createFilter(document.querySelector(`#lesson-${this.initial_lesson}`));

      $(`#exercise-${this.initial_exercise_type}`).prop('checked', true);
      this.createFilter(document.querySelector(`#exercise-${this.initial_exercise_type}`));
    }

    if (this.initial_category) {
      const categoryFilterId = `#category-${this.initial_category}`;
      $(categoryFilterId).prop('checked', true);
      this.createFilter(document.querySelector(categoryFilterId));
    }

    if (this.initial_created_by) {
      this.initial_created_by.forEach(function(filter) {
        $(`#${filter}`).prop('checked', true);
        this.createFilter(document.querySelector(`#${filter}`));
      }.bind(this));
    }
  }

  createFilter(elem) {
    $(this.tags_container).show();
    const filter_tag = new FilterTag({
      filter_el: elem,
      container: this.tags_container,
      text: this.getText(elem),
      type: this.parseFilterType(elem),
      callback: this.removeFilter,
      id: elem.id,
      parent: this,
      alias: $(elem).data('exercise-name')
    });

    filter_tag.init();

    this.filter_tags[elem.id] = filter_tag;
  }

  removeFilter(filter_id, submit) {
    this.filter_tags[filter_id].destroy();
    $(`#${filter_id}`)[0].checked = false;
    delete this.filter_tags[filter_id];

    if (!Object.keys(this.filter_tags).length) { $(this.tags_container).hide(); }

    if (submit) { this.submitForm(); }
  }

  getText(el) {
    if (el.nodeName.toLowerCase() === 'input' || el.nodeName.toLowerCase() === 'li') {
      return el.parentElement.textContent || el.parentElement.innerText;
    }
    else if (el.nodeName.toLowerCase() === 'label') {
      return el.textContent || el.innerText;
    }
    return 'Filter';
  }

  parseFilterType(el) {
    return el.name.match(/filter\[([^\]]+)\]/)[1];
  }

  submitForm() {
    const spinner = document.createElement('i');
    spinner.className = 'fa fa-spinner fa-spin fa-lg';
    const $renderArea = $('[data-filter-render]');
    $renderArea.html(spinner);
    $.ajax({
      type: 'POST',
      url: this.ajax_url,
      data: $(this.form).serialize()
    })
      .done((data) => {
        $renderArea.replaceWith(data.html);
        const $bulkAction = $('#bulk-action-button');
        $bulkAction.data('selected-questions', {});
        $bulkAction.data('selected-states', {});
        window.init_questions.init(true);
        $('#total-question-count').html(data.filtered_count);
        $('#selected-question-count').html(0);

        $('#expand-all').each(function() { this.updatePage(); });

        ReactRailsUJS.mountComponents('.question-actions');
      })
      .fail(() => {
        console.log('Oops, query failed');
      })
      .always(() => {
        this.disable_submit = false;
      });
  }
}
