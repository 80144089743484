define(['Modal', 'Tabs'], function(Modal, Tabs) {
  /**
   * Base Object for an AdminReadingChallenge feature
   *
   * @param config
   * @constructor
   */
  function AdminReadingChallenge () {

  }

  AdminReadingChallenge.prototype = {
    init: function() {
      window.current_adminrc = this;
      // initialze our modals
      this.modals = {
        sections: [],
        current: undefined
      };
      this.setupFormListeners();
      this.setupTemplateListener();
      this.setupPsiSectionSubmits();

      if ($('.sortable').length > 0) {
        this.setupSortingListeners();
      }
    },

    // Helper to toggle slide and css class
    toggle: function (elem) {
      if ($(elem).hasClass('fa')) {
        $(elem).closest('.section_title').toggleClass('active');
      }
      else {
        $(elem).toggleClass('active');
      }
      var target = $(elem).data('toggle');
      $(target).slideToggle();
    },

    setupSortingListeners: function () {
      $('.sortable').sortable({
        update: function (event, ui) {
          var $this = $(this);
          $.post($this.attr('data-reorder-url'), $this.sortable('serialize'))
            .done(function( data ) {
              var questions_per_group,
                group_number;

              if ($('ol#groups').length > 0) {
                questions_per_group = parseInt($('ol#groups').attr('data-questions-per-group'));
                group_number = parseInt($this.attr('data-group'));
              }
              else {
                questions_per_group = 0;
                group_number = 0;
              }

              // update question ids
              $this.find('li').each(function (index, item) {
                item.id = `question_${index + (group_number * questions_per_group)}`;
              });
            });
        }
      });
    },

    setupFormListeners: function() {
      var self = this;

      $('.edit_section_titles').off('click').on('click', function (e) {
        var config = {
          trigger_element: $(this)[0],
          popup_element: $("#section_titles_popup")[0],
          overlay: true,
          popup_class: 'modal_popup_wrapper slide_gui_modal',
          close_button_class: 'modal_close_button'
        };
        self.modals.current = self.modals.current || new Modal(config);

        self.modals.current.initialized ? self.modals.current.show() : self.modals.current.init();
      });

      $('.edit_section').off('click').on('click', function (e) {
        var section_number = this.className[this.className.length - 1];
        e.preventDefault();
        var config = {
          trigger_element: $(this)[0],
          popup_element: $("#section_popup_" + section_number)[0],
          overlay: true,
          popup_class: 'modal_popup_wrapper slide_gui_modal',
          close_button_class: 'modal_close_button',
        };

        self.modals.sections[section_number] = self.modals.sections[section_number] || new Modal(config);

        // reset radio button to always show image
        $(self.modals.sections[section_number].popup_element).find('#psi_section_image').prop('checked', true);
        $('div.psi_section_choice').removeClass('hide').addClass('hide');
        $(".psi_section_image").removeClass('hide');

        self.modals.sections[section_number].initialized ? self.modals.sections[section_number].show() : self.modals.sections[section_number].init();

        self.modals.current = self.modals.sections[section_number];
        self.setupFormListeners();
      });

      $('form.background_image').off('ajax:success').on("ajax:success", function (xhr) {
        var data = xhr.detail[0];
        if (data['html']) {
          $(this).html(data['html']).find('.error_message').html('');
        }

        self.setupFormListeners();
      });

      $('input.psi_section_choice').off('click').on('click', function (e) {
        $('div.psi_section_choice').removeClass('hide').addClass('hide');
        $("." + (this.id)).removeClass('hide');
      });
    },

    setupPsiSectionSubmits: function () {
      $('#section_submit').off('click').on('click', (e) => {
        e.preventDefault();
        let form = $(this.modals.current.popup_element).find('form');
        $.ajax({
          type: 'PUT',
          url: form.attr('action'),
          dataType: 'json',
          data: form.serialize(),
          success: function (data) {
            $('h3.section_title').each((i, title) => {
              $(title).html(data.groups[i].data.name);
            });
            this.modals.current.hide();
            this.setupFormListeners();
            const formWrapper = $('#edit_section_wrapper');
            formWrapper.html(data.html);
            const tabs = new Tabs({ container: formWrapper[0] });
            tabs.init();
          }.bind(this),
          error: function() {
            console.log('error. bummer');
          }
        });
      });
    },

    setupTemplateListener: function() {
      $('#template_change').off('change').on('change', function() {
        let lesson = $(this).data('lesson-id');
        let program = $(this).data('program-id');
        let template_type = $(this).val();
        $.ajax({
          type: 'POST',
          url: `/admin/lessons/${lesson}/challenge/change_template`,
          data: {
            game: template_type,
            program_id: program
          },
          success: function (data) {
            window.location.href = window.location.origin + "/admin/lessons/" + lesson + "/challenge/edit?program_id=" + program;
          },
          error: function () {
            console.log('error. bummer')
          }
        });
      });
    }
  };

  return AdminReadingChallenge
});
