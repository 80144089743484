define(['D3'], function(d3) {
  function make_pie_chart(element, data, config) {
    var w = config.width || 200,                        //width
      h = config.height || 120,                            //height
      r = h / 2, //radius
      legend = {};

    if (config.legend) {
      legend.height = config.legend.height || 100;
      legend.width = config.legend.width || 100;
      legend.translateX = config.legend.translateX || (h - .95 * w);
      legend.translateY = config.legend.translateY || (r / 2);
    }
    else {
      legend.height = 100;
      legend.width = 100;
      legend.translateX = (h - .95 * w);
      legend.translateY = (r / 2);
    }

    var vis = d3.select(element)
      .append("svg:svg")              //create the SVG element inside the <body>
      .data([data])                   //associate our data with the document
      .attr("width", w)           //set the width and height of our visualization (these will be attributes of the <svg> tag
      .attr("height", h)
      .append("svg:g")                //make a group to hold our pie chart
      .attr("transform", "translate(" + r + "," + r + ")");    //move the center of the pie chart from 0, 0 to radius, radius

    var sum = 0;
    for (var i = 0; i < data.length; i++) {
      sum += data[i].value
    }

    var arc = d3.arc()              //this will create <path> elements for us using arc data
      .outerRadius(r - 1)
      .innerRadius(0);

    var pie = d3.pie()           //this will create arc data for us given a list of values
      .value(function (d) {
        return d.value;
      });    //we must tell it out to access the value of each element in our data array

    var arcs = vis.selectAll("g.slice")     //this selects all <g> elements with class slice (there aren't any yet)
      .data(pie)                          //associate the generated pie data (an array of arcs, each having startAngle, endAngle and value properties)
      .enter()                            //this will create <g> elements for every "extra" data element that should be associated with a selection. The result is creating a <g> for every object in the data array
      .append("svg:g")                //create a group to hold each slice (we will have a <path> and a <text> element associated with each slice)
      .attr("class", "slice");    //allow us to style things in the slices (like text)

    arcs.append("svg:path")
      .attr("fill", function (d, i) {
        return data[i].color;
      }) //set the color for each slice to be chosen from the color function defined above
      .attr("d", arc);                                    //this creates the actual SVG path using the associated data (pie) with the arc drawing function

    var gLabel = vis.selectAll('.arc-label')
      .data(pie)
      .enter()
      .append('g')
      .attr('class', 'arc-label');

    gLabel.append("text")
      .attr("transform", function (d) {
        return "translate(" + arc.centroid(d) + ")";
      })
      .attr("dy", ".35em")
      .style("text-anchor", "middle")
      .text(function (d, i) {
        return numberWithCommas(data[i].value);
      });

    // add legend
    var legend = vis.append("g")
      .attr("class", "legend")
      .attr("height", legend.height)
      .attr("width", legend.width)
      .attr('transform', 'translate(' + legend.translateX + ',' + legend.translateY + ')');

    legend.selectAll('rect')
      .data(data)
      .enter()
      .append("rect")
      .attr("x", w - 65)
      .attr("y", function (d, i) {
        return i * 20;
      })
      .attr("width", 10)
      .attr("height", 10)
      .style("fill", function (d, i) {
        return data[i].color
      });

    legend.selectAll('label')
      .data(data)
      .enter()
      .append("text")
      .attr("x", w - 52)
      .attr("y", function (d, i) {
        return i * 20 + 9;
      })
      .text(function (d, i) {
        return data[i].label;
      });
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return {
    makePieChart: make_pie_chart
  }
});

