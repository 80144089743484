define([], function() {
  function SplitBodyId() {
    var where = 'js_' + $('body').attr('data-controller');
    return where;
  }

  function OddEven() {
    $("tr:even").addClass("even");
    $("tr:odd").addClass("odd");
  }

  return {
    init: function() {
      if (SplitBodyId() == "js_subscriber_stats") OddEven();
    }
  }
});