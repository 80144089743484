define([], function() {
  function AdminTabs() {
    // Teachers Page tabs
    $("#invite_teacher_tab").click(function () {
      $("#invite_teacher").show();
      $('#invite_teacher_tab').addClass('selected');

      hide_add_teacher();
      hide_view_teacher();
      hide_upload_teacher();
      hide_update_teacher();
    });

    $("#add_teacher_tab").click(function () {
      $("#add_teacher").show();
      $('#add_teacher_tab').addClass('selected');

      hide_invite_teacher();
      hide_view_teacher();
      hide_upload_teacher();
      hide_update_teacher();
    });

    $("#view_teacher_link_tab").click(function () {
      $("#view_teacher_link").show();
      $('#view_teacher_link_tab').addClass('selected');

      hide_invite_teacher();
      hide_add_teacher();
      hide_upload_teacher();
      hide_update_teacher();
    });

    $("#batch_upload_teachers_tab").click(function () {
      $('#batch_upload_teacher').show();
      $('#batch_upload_teachers_tab').addClass('selected');

      hide_invite_teacher();
      hide_add_teacher();
      hide_view_teacher();
      hide_update_teacher();
    });

    $("#batch_update_teachers_tab").click(function () {
      $('#batch_update_teacher').show();
      $('#batch_update_teachers_tab').addClass('selected');

      hide_invite_teacher();
      hide_add_teacher();
      hide_view_teacher();
      hide_upload_teacher();
    });

    $('#invite_teacher_tab').addClass('selected');

    function hide_invite_teacher() {
      $("#invite_teacher").hide();
      $("#invite_teacher_tab").removeClass('selected');
    }

    function hide_add_teacher() {
      $("#add_teacher").hide();
      $('#add_teacher_tab').removeClass('selected');
    }

    function hide_view_teacher() {
      $("#view_teacher_link").hide();
      $("#view_teacher_link_tab").removeClass('selected');
    }

    function hide_upload_teacher() {
      $("#batch_upload_teacher").hide();
      $('#batch_upload_teachers_tab').removeClass('selected');
    }

    function hide_update_teacher() {
      $("#batch_update_teacher").hide();
      $('#batch_update_teachers_tab').removeClass('selected');
    }

    // Students Page tabs
    $("#add_student_directly_tab").click(function () {
      $("#add_student_directly").show();
      $('#add_student_directly_tab').addClass('selected');

      hide_view_student();
      hide_upload_student();
      hide_update_student();
    });

    $("#view_student_link_tab").click(function () {
      $("#view_student_link").show();
      $('#view_student_link_tab').addClass('selected');

      hide_add_student_directly();
      hide_upload_student();
      hide_update_student();
    });

    $("#batch_upload_students_tab").click(function () {
      $('#batch_upload_student').show();
      $('#batch_upload_students_tab').addClass('selected');

      hide_add_student_directly();
      hide_view_student();
      hide_update_student();
    });

    $("#batch_update_students_tab").click(function () {
      $('#batch_update_student').show();
      $('#batch_update_students_tab').addClass('selected');

      hide_add_student_directly();
      hide_view_student();
      hide_upload_student();
    });

    $('#invite_student_tab').addClass('selected');

    function hide_add_student_directly() {
      $("#add_student_directly").hide();
      $('#add_student_directly_tab').removeClass('selected');
    }

    function hide_view_student() {
      $("#view_student_link").hide();
      $("#view_student_link_tab").removeClass('selected');
    }

    function hide_upload_student() {
      $("#batch_upload_student").hide();
      $('#batch_upload_students_tab').removeClass('selected');
    }

    function hide_update_student() {
      $("#batch_update_student").hide();
      $('#batch_update_students_tab').removeClass('selected');
    }
  }

  function TeacherTabs() {

    $("div.student_tabs ul.tabs li").click(function() {
      var selected_tab_name = $(this).attr('id');
      var selected_content = selected_tab_name.slice(0, selected_tab_name.length - 4);

      $("div.student_tabs ul.tabs li").removeClass('selected');
      $('.tabs_container_wrapper > div').removeClass('hide').addClass('hide');

      $(this).addClass('selected');
      $('#' + selected_content).removeClass('hide');
    });

    $("#add_student_tab").addClass("selected");

    $("#tci_assessment_tab").addClass('selected');

    $("#tci_assessment_tab").on('click', function (e) {
      e.preventDefault();
      $("#tci_assessment").show();
      $("#tci_assessment_tab").addClass('selected');

      hide_printable_assessment();
      hide_online_assessment();
    });

    $("#printable_assessment_tab").on('click', function (e) {
      e.preventDefault();
      $("#printable_assessment").show();
      $("#printable_assessment_tab").addClass('selected');

      hide_tci_assessment();
      hide_online_assessment();
    });

    $("#online_assessment_tab").on('click', function (e) {
      e.preventDefault();
      $("#online_assessment").show();
      $("#online_assessment_tab").addClass('selected');

      hide_printable_assessment();
      hide_tci_assessment();
    });

    function hide_printable_assessment() {
      $("#printable_assessment").hide();
      $("#printable_assessment_tab").removeClass('selected');
    }

    function hide_tci_assessment() {
      $("#tci_assessment").hide();
      $("#tci_assessment_tab").removeClass('selected');
    }

    function hide_online_assessment() {
      $("#online_assessment").hide();
      $("#online_assessment_tab").removeClass('selected');
    }

  }

  function LanguageTabs() {
    $(".language_es").hide();

    $('#english-tab').on('click', function(e){
      e.preventDefault();
      $('#english-tab').addClass('selected');
      $('#spanish-tab').removeClass('selected');
      $(".language_en").show();
      $(".language_es").hide();
    });

    $('#spanish-tab').on('click', function(e){
      e.preventDefault();
      $('#spanish-tab').addClass('selected');
      $('#english-tab').removeClass('selected');
      $(".language_es").show();
      $(".language_en").hide();
    });
  }

  return {
    init: function() {
      AdminTabs();
      TeacherTabs();
      LanguageTabs();
    },
    LanguageTabs: LanguageTabs
  }
});