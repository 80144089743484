define([], function() {
  function datePicker() {
    $('.js-assignment-due-date').datepicker({
      buttonText: '', // renders empty alt text for buttonImage
      beforeShowDay: $.datepicker.noWeekends,
      minDate: 0,
      maxDate: 365,
      dateFormat: 'mm-dd-yy',
      showOn: 'button',
      buttonImage: '/images/icon_calendar_small.png',
      buttonImageOnly: true,
      onSelect: () => {
        // hide validation error message
        // date will always be in correct format and in the future from picker
        $('#js-invalid-date-message').stop(true, true).hide();
      }
    });

    $('.message_start_date, .message_end_date').datepicker({
      minDate: 0,
      maxDate: 365,
      dateFormat: 'dd-mm-yy',
      showOn: 'button',
      buttonImage: '/images/icon_calendar_small.png',
      buttonImageOnly: true,
      onSelect: (date) => {
        this.value = `${date} 00:00:00 PDT`;
      }
    });
  }

  return {
    init: () => datePicker()
  };
});
