define(['TCIUtils'], function(TCIUtils) {
  function buildVocabGame(game_data, game_id) {
    var terms = [];
    var definitions = [];
    var content_block = '';

    for (var i = 0; i < game_data.length; i++) {
      for (var obj in game_data[i]) {
        if (obj != "") terms[i] = obj;
        if (game_data[i][obj] != "") definitions[i] = game_data[i][obj];
      }
    }

    content_block = "<div class='interaction_question hide'>Drag each term to its correct definition.</div>" +
    "<div class='vocab_term_block abs_it'>" +
    "<div id='origin_" + game_id + "' class='vocab_term_origin dd_origin'>" +
    "<p class='vocab_origin_title'>Vocabulary Term</p>";

    var temp_holder = [];
    for (var i = 0; i < terms.length; i++) {
      temp_holder[i] = "<p class='dd_answer dd_answer_js id_" + (i + 1) + " pos_fl'>" + terms[i] + "</p>";
    }
    temp_holder = TCIUtils.fisherYatesShuffle(temp_holder);
    for (var i = 0; i < terms.length; i++) {
      content_block += temp_holder[i]
    }

    content_block += "</div><!-- end of vocab-term-origin div -->" +
    "<div class='clear'>" +
    "</div><!-- end of vocab-terms-block div -->" +
    "<div class='check_answer_block'>" +
    "<a href='##' class='type_1_check_answer dd_check_answers'>Check My Answers</a>" +
    "<a href='##' class='type_1_reset_answer dd_reset_answers'>Reset</a>" +
    "</div><!-- end of check-answer-block div -->" +
    "</div><!-- end of vocab-terms-block div -->" +
    "<div class='vocab_definition_block abs_it'>" +
    "<ul>";

    var temp_holder = [];
    for (var i = 0; i < definitions.length; i++) {
      temp_holder[i] = "<div class='dd_dropzone id_" + (i + 1) + " pos_fl'></div>" + "<li>" + definitions[i] + "</li>";
    }
    for (var i = 0; i < definitions.length; i++) {
      content_block += temp_holder[i]
    }

    content_block += "</ul></div><!-- end of vocab-definition-block div -->";

    content_block += "<div class='it_dialog_box'></div>";
    $('#interaction_' + game_id).html(content_block);
  }

  return {
    build: buildVocabGame
  }
});