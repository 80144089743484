define ['Magnifier'], (Magnifier) ->
  init = () ->
    iOS = false
    p = navigator.platform
    if( p == 'iPad' || p == 'iPhone' || p == 'iPod' )
      iOS = true

    $(window).on 'load', ->
      $('.details').each ->
        details_width = $(this).siblings('img').outerWidth()
        $(this).css("width", details_width)

  #  $('.trigger.illustration img').on('click', ->
  #    id = $(this).attr('rel')
  #    zoom_type = $(this).attr('data-zoom-type')
  #    if !iOS && zoom_type == 'magnifier'
  #      zoomed_image = new Magnifier(
  #        type: 'section'
  #        div_obj: id
  #      )
  #      zoomed_image.init()
  #
  #    big_image = $(this).attr('rel')
  #    $(big_image).show()
  #
  #    $('.overlay_background').show()
  #    $('.simple_overlay').each ->
  #      $(this).children('.scrollbox').remove()
  #      image_width = $(this).children('img').outerWidth()
  #      image_height = $(this).children('img').outerHeight()
  #      largest_image = document.createElement('img')
  #      largest_image.src = $(this).find('.zoomed_url').attr('href')
  #      largest_image.className = "largest_image"
  #      offset_y = if $('.msg').length > 0 then 570 else 675
  #      $(this).children('.details_overlay').css("width", image_width)
  #      $(this).css("position", "absolute")
  #      if zoom_type == 'sliders'
  #        $(this).children('img').hide()
  #        container_width = Math.min(900, image_width)
  #        container_height = Math.min($(window).height() - 100, image_height)
  #        scrollbox = document.createElement('div')
  #        scrollbox.className = "scrollbox"
  #        scrollbox.appendChild(largest_image)
  #        scrollbox.style.overflow = 'scroll'
  #
  #        $(this).append(scrollbox)
  #
  #        $(this).css({
  #          width: container_width + 5
  #          height: container_height + 5
  #          top: (($(window).height() - container_height) / 2) + $(window).scrollTop() - 800
  #          left: (($(window).width() - container_width) / 2) - ($(window).width() / 2 - 450)
  #          padding: '5px'
  #        })
  #
  #        $(scrollbox).css({
  #          width: container_width
  #          height: container_height
  #          overflow: 'scroll'
  #        })
  #      else
  #        overlay_posY = (($(window).height() - $(this).outerHeight()) / 2) + $(window).scrollTop() - offset_y
  #        if $('.msg').length > 0
  #          if overlay_posY >= -300
  #            $(this).css("top", overlay_posY + "px")
  #          else
  #            $(this).css("top", -300 + "px")
  #        else
  #          if overlay_posY >= -140
  #            $(this).css("top", overlay_posY + "px")
  #          else
  #            $(this).css("top", -140 + "px")
  #
  #        $(this).css("left", ((960 - $(this).outerWidth()) / 2) + "px")
  #  )

  #  $('.simple_overlay a.close').on('click', ->
  #    $('.simple_overlay img').off()
  #    big_image = $(this).parent().attr('id')
  #    $("##{big_image}").hide()
  #    $('.overlay_background').hide()
  #    if ($('#magnified_image').length > 0)
  #      $('#magnified_image').remove()
  #    if !iOS
  #      zoomed_image = null
  #  )

  #  $('.overlay_background').on('click', ->
  #    $('.simple_overlay').hide()
  #    $(this).hide()
  #    if ($('#magnified_image').length > 0)
  #      $('#magnified_image').remove()
  #    if !iOS
  #      zoomed_image = null
  #  )

    if $('#map').length > 0
      if !iOS
        zoomed_image = new Magnifier(
          type: 'map'
          div_obj: '#map'
        )
        zoomed_image.init()

  init: init

