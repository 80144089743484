define(['Modal'], function(Modal) {

  /*
    font awesome's css animation on the admin slides page causes the noteCell
    column to look really weird because of the relative positioning on that table cell
    this defines a jquery function so we can trigger the animation with javascript
  */
  $.fn.animateRotate = function(angle, duration, easing, complete) {
    return this.each(function() {
      var $elem = $(this);

      $({deg: 0}).animate({deg: angle}, {
        duration: duration,
        easing: easing,
        step: function(now) {
          $elem.css({
             transform: 'rotate(' + now + 'deg)'
           });
        },
        complete: complete || $.noop
      });
    });
  };

  /*
    PresentationGuide:
    click a button (popup_trigger) to popup a modal (popup_element) where you can:
      1) see a link to the current presentation guide
      2) have the option of generating a new one (generate_trigger)

    If triggering a new presentation guide generation:
      1) the loading_container shows a spinner and loading text.
      2) on success of triggering the initial generation (ajax get request to generate_url),
         we make another ajax request to poll the pdf_updated_url
         using a timestamp representing the last time the pdf was updated
      3) when the timestamp is outdated (and the pdf is updated), we replace the contents
         of the loading_container with the new pdf url
  */
  function PresentationGuide(config) {
    this.popup_element = config.popup_element;
    this.popup_trigger = config.popup_trigger;
    this.generate_trigger = config.generate_trigger;
    this.generate_url = config.generate_url;
    this.pdf_updated_url = config.pdf_updated_url;
    this.loading_container = config.loading_container;
    this.current_pdf_link = config.current_pdf_link
  }

  PresentationGuide.prototype = {
    init: function() {
      this.modal = new Modal({
        trigger_element: this.popup_trigger,
        popup_element: this.popup_element,
        popup_class: 'modal_popup_wrapper grey-modal',
        close_button_class: 'modal_close_button',
        default_closed: true,
        overlay: true,
        center: true,
        width: '320px',
        title: 'Lesson Guide'
      });

      this.modal.init();

      this.setupListeners();
    },

    setupListeners: function() {
      $(this.generate_trigger).on('click', function() {
        this.modal.close();

        this.triggerGeneration();
        $(this.popup_trigger).addClass('disabled');

        $(this.loading_container).show();
        this.makeLoadingText();
      }.bind(this));
    },

    triggerGeneration: function() {
      $.ajax({
        type: 'JSON',
        method: 'GET',
        url: this.generate_url,
        context: this
      }).done(function(data){
          if (data["previous_update"] !== undefined) {
            this.previous_update = data["previous_update"];
            this.isPdfUpdated(this.previous_update);
          }
        }).fail(function(data){
          console.log('Whoops, something failed:', data);
        });
    },

    isPdfUpdated: function(previous_update) {
      $.ajax({
        type: 'JSON',
        method: 'GET',
        data: { 'timestamp': this.previous_update },
        url: this.pdf_updated_url,
        context: this
      }).done(function(data) {
        if (data['url'] !== undefined) {
          this.setCurrentPdfLink(data['url']);
          $(this.popup_trigger).removeClass('disabled');
          $(this.popup_trigger).text('Update Guide');
        }
        else {
          setTimeout(function() { this.isPdfUpdated(this.previous_update) }.bind(this), 10000);
        }
      }).fail(function(data) {
        console.log('Whoops, something failed:', data);
      });
    },

    setCurrentPdfLink: function(href) {
      if (this.current_pdf_link === undefined) {
        this.current_pdf_link = document.createElement('a');
        this.current_pdf_link.innerText = 'View current presentation guide'
        this.current_pdf_link.href = href;
        this.current_pdf_link.target = 'blank';

        $(this.popup_element).prepend(this.current_pdf_link);
      }
      else {
        $(this.current_pdf_link).attr('href', href);
      }
    },

    makeRotate: function(elem) {
      $(elem).animateRotate(
        360,
        1337,
        'linear',
        function() { this.makeRotate(elem); }.bind(this)
      );
    },

    makeLoadingText: function() {
      var loading_icon = document.createElement('i');
      loading_icon.classList.add('fa', 'fa-spinner', 'fa-1x', 'fa-fw');
      this.makeRotate(loading_icon);

      var loading_text = document.createElement('span');
      loading_text.innerText = ' Generating Guide…';

      this.popup_trigger.innerText = '';
      this.popup_trigger.append(loading_icon);
      this.popup_trigger.append(loading_text);
    }
  };

  return PresentationGuide;
});
