define([], function() {
  function init() {
    var dX = 300;
    var dY = 300;
    var speed_X = 300;
    var speed_Y = 300;

    var full_dimensions = new Array('0', '0');
    var small_dimensions = new Array();

    $('img.map_small').on('load', function() {
      $('div#map_default , div#map_small_box').css( {'height' : $(this).height() , 'width' : $(this).width() , 'border' : '1px solid #aaa'} );
      $(this).css({'margin-top' : -$(this).height()/2 , 'margin-left' : -$(this).width()/2 });
      small_dimensions = [$(this).width() , $(this).height()];
    });

    $('img.map_full').on('load', function() {
      full_dimensions = [$('.map_full').width(), $('.map_full').height()];
      if ((full_dimensions[0] > small_dimensions[0]) || (full_dimensions[1] > small_dimensions[1])) {
        $('#zoom_nav').css('display' , 'block');
      }
    });

    $('#zoom_in').click( function() {
      if ($('#map_full_box').css('z-index') == '1') {
        $('#map_full_box').css('z-index' , '3');
        enableNav('#zoom_out');
        enableNav('#move_right');
        enableNav('#move_down');
        disableNav(this);
      }
    });

    $('#zoom_out').click( function() {
      if ($('#map_full_box').css('z-index') == '3') {
        $('#map_full_box').css('z-index' , '1');
        $('#map_full_box').css({ 'left' : '0', 'top' : '0' });
        disableNav('#move_right');
        disableNav('#move_left');
        disableNav(this);
        disableNav('#move_down');
        disableNav('#move_up');
        enableNav('#zoom_in');

      }
    });

    $('#move_right').click( function() {
      var left_dist = $('#map_full_box').css('left');
      if ($('#map_full_box').css('z-index') == '3') {
        if (Math.abs(parseInt(left_dist) - dX) >= (parseInt(full_dimensions[0])) - small_dimensions[0]) {
          var last_move = -(parseInt(full_dimensions[0]) - small_dimensions[0]);
          $('#map_full_box').animate({ 'left' : last_move }, function() {
            disableNav('#move_right');
          });
        }
        else {
          $('#map_full_box').animate({ 'left': '-=' + dX}, speed_X);
        }
        enableNav('#move_left');
      }
    });

    $('#move_left').click( function() {
      var left_dist = $('#map_full_box').css('left');
      if ($('#map_full_box').css('z-index') == '3') {
        if ((parseInt(left_dist) + dX) >= 0) {
          $('#map_full_box').animate({ 'left' : '0' }, function() {
            disableNav('#move_left');
          });
        }
        else {
          $('#map_full_box').animate({ 'left': '+=' + dX }, speed_X);
        }
        enableNav('#move_right');
      }
    });

    $('#move_down').click( function() {
      var top_dist = $('#map_full_box').css('top');
      if ($('#map_full_box').css('z-index') == '3') {
        if (Math.abs(parseInt(top_dist) - dY) >= (parseInt(full_dimensions[1])) - small_dimensions[1]) {
          var last_move = -(parseInt(full_dimensions[1]) - small_dimensions[1]);
          $('#map_full_box').animate({ 'top' : last_move }, function() {
            disableNav('#move_down');
          });
        }
        else {
          $('#map_full_box').animate({ 'top': '-=' + dY }, speed_Y);
        }
        enableNav('#move_up');
      }
    });

    $('#move_up').click( function() {
      var top_dist = $('#map_full_box').css('top');
      if ($('#map_full_box').css('z-index') == '3') {
        if ((parseInt(top_dist) + dY) >= 0) {
          $('#map_full_box').animate({ 'top' : '0' }, function() {
            disableNav('#move_up');
          });
        }
        else {
          $('#map_full_box').animate({ 'top': '+=' + dY }, speed_Y);
        }
        enableNav('#move_down');
      }
    });

    function disableNav(link) {
      $(link).css({ 'opacity' : '0.1' , 'filter' : 'alpha(opacity=10)' , 'cursor' : 'default'});
    }

    function enableNav(link) {
      $(link).css({'opacity' : '1.0' , 'filter' : 'alpha(opacity=100)' , 'cursor' : 'pointer'});
    }
  }

  return {
    init: init
  }
});