define([], () => {
  function Accordion(config) {
    this.default_state = config.default_state || 'collapsed';
    this.expand_all_button = config.expand_all_button;
    this.$control_elements = config.$control_elements;
    this.trigger_location = config.trigger_location || 'prepend';
    this.dependents_class = config.dependents_class;
    this.controls = [];
    this.button_text_append = config.button_text_append;
    this.master_id = config.master_id;
    this.aria_label = config.aria_label || false;
  }

  Accordion.prototype = {
    init: function() {
      let i;
      let new_accordion_item;

      for (i = 0; i < this.$control_elements.length; i++) {
        new_accordion_item = new AccordionItem({
          control_element: this.$control_elements[i],
          is_expanded: (this.default_state !== 'collapsed'),
          dependents_class: this.dependents_class,
          pair_id: $(this.$control_elements[i]).attr('data-accordian-pair-id'),
          trigger_location: this.trigger_location,
          aria_label: this.aria_label
        });

        new_accordion_item.init();
        this.controls.push(new_accordion_item);
      }

      this.is_expanded = (this.default_state !== 'collapsed');

      // Set up listeners
      $(this.expand_all_button).on('click', e => this.toggleExpandAll(e));
    },

    toggleExpandAll: function(e) {
      e.preventDefault();

      let i;

      if (this.is_expanded) {
        for (i = 0; i < this.controls.length; i++) {
          this.controls[i].collapse();
        }
        this.is_expanded = false;

        if (this.button_text_append) {
          this.expand_all_button.innerHTML = `Expand All ${this.button_text_append}`;
        }
        else {
          this.expand_all_button.innerHTML = I18n.t('expand_all');
        }
      }
      else {
        for (i = 0; i < this.controls.length; i++) {
          this.controls[i].expand();
        }
        this.is_expanded = true;

        if (this.button_text_append) {
          this.expand_all_button.innerHTML = `Collapse All ${this.button_text_append}`;
        }
        else {
          this.expand_all_button.innerHTML = I18n.t('collapse_all');
        }
      }
    }
  };


  function AccordionItem(config) {
    this.control_element = config.control_element;
    this.is_expanded = config.is_expanded;
    this.dependents_class = config.dependents_class;
    this.pair_id = config.pair_id;
    this.trigger_location = config.trigger_location;
    this.aria_label = config.aria_label;
  }

  AccordionItem.prototype = {
    init: function() {
      this.$dependents = $(`.${this.dependents_class}[data-accordian-pair-id='${this.pair_id}']`);

      this.toggle_button = document.createElement('a');
      this.toggle_button.className = 'accordian_button_collapsed';
      this.toggle_button.setAttribute('role', 'button');
      this.toggle_button.setAttribute('href', '#');

      $(this.control_element).prepend(this.toggle_button);

      if (this.is_expanded) this.expand();
      else this.collapse();

      // Set label for buttons
      if (this.aria_label) {
        $(this.toggle_button).attr('aria-label', this.aria_label);
      }

      // Set up listeners
      $(this.toggle_button).on('click', e => this.toggle(e));
    },

    expand: function() {
      this.$dependents.show();
      this.is_expanded = true;
      this.toggle_button.className = 'accordian_button_expanded';
    },

    collapse: function() {
      this.$dependents.hide();
      this.is_expanded = false;
      this.toggle_button.className = 'accordian_button_collapsed';
    },

    toggle: function(e) {
      e.preventDefault();
      if (this.is_expanded) this.collapse();
      else this.expand();
    }
  };

  return Accordion;
});
