define([], function() {
  function MultipleChoiceGame(config) {
    this.parent_id = config.parent_id;
    this.number_of_answers = config.number_of_answers;
    this.answer_choices = [];
    this.question = '';
    this.info_blocks = [];
    this.iOS = false;

    this.iOS = /(iPad|iPhone|iPod)/g.test( navigator.userAgent );
  }

  MultipleChoiceGame.prototype = {
    init: function () {

      var this_obj = this,
        namespace = '#' + this_obj.parent_id;

      if (!this.iOS) {
        $('.mc_answer').addClass('not_ios');
      }

      // Event listeners
      $(namespace + ' .mc_answer').on('click', function () {
        if ($(this).hasClass('mc_selected')) {
          $(this).removeClass('mc_selected');
        }
        else {
          $(this).addClass('mc_selected');
        }
      });

      $(namespace + ' .mc_check_answer').on('click', function () {
        var number_correct = $(namespace + ' .mc_answer.mc_correct.mc_selected').length,
          modifier = '';

        if ($(namespace + ' .mc_selected').length) {
          $(namespace + ' .mc_answer').removeClass('mc_submitted');
          $(namespace + ' .mc_answer.mc_selected').addClass('mc_submitted');

          if ($(namespace + ' .mc_answer.mc_correct.mc_submitted').length == this_obj.number_of_answers &&
            $(namespace + ' .mc_answer.mc_submitted').length == this_obj.number_of_answers) {
            $(namespace + ' .mc_incorrect_text')
              .stop(true, true)
              .fadeOut(100);

            $(namespace + ' .mc_somecorrect_text')
              .stop(true, true)
              .fadeOut(100);

            $(namespace + ' .mc_correct_text')
              .stop(true, true)
              .slideDown(300)
              .delay(10000)
              .fadeOut(1000);
          }
          else if (($(namespace + ' .mc_answer.mc_submitted').not('.mc_correct').length > 0 &&
            $(namespace + ' .mc_answer.mc_correct.mc_submitted').length > 0)
            || (($(namespace + ' .mc_answer.mc_correct.mc_submitted').length < this_obj.number_of_answers) &&
            $(namespace + ' .mc_answer.mc_correct.mc_submitted').length > 0)) {

            if ($(namespace + ' .mc_answer.mc_submitted').length > this_obj.number_of_answers &&
              $(namespace + ' .mc_answer.mc_correct.mc_submitted').length == this_obj.number_of_answers) {
              modifier = ' only ';
            }

            $(namespace + ' .mc_incorrect_text')
              .stop(true, true)
              .fadeOut(100);

            $(namespace + ' .mc_correct_text')
              .stop(true, true)
              .fadeOut(100);

            if (this_obj.number_of_answers == 1) {
              $(namespace + ' .mc_somecorrect_text')
                .find('.mc_response_text')
                .text('There is ' + modifier + this_obj.number_of_answers + ' correct answer. You\'ve found ' + number_correct + ' of them.');
            }
            else {
              $(namespace + ' .mc_somecorrect_text')
                .find('.mc_response_text')
                .text('There are ' + modifier + this_obj.number_of_answers + ' correct answers. You\'ve found ' + number_correct + ' of them.');
            }

            $(namespace + ' .mc_somecorrect_text')
              .stop(true, true)
              .slideDown(300)
              .delay(4000)
              .fadeOut(1000);
          }
          else {

            $(namespace + ' .mc_correct_text')
              .stop(true, true)
              .fadeOut(100);

            $(namespace + ' .mc_somecorrect_text')
              .stop(true, true)
              .fadeOut(100);

            if (this_obj.number_of_answers == 1) {
              $(namespace + ' .mc_incorrect_text')
                .find('.mc_response_text')
                .text('There is ' + this_obj.number_of_answers + ' correct answer. Try again.');
            }
            else {
              $(namespace + ' .mc_incorrect_text')
                .find('.mc_response_text')
                .text('There are ' + this_obj.number_of_answers + ' correct answers. Try again.');
            }

            $(namespace + ' .mc_incorrect_text')
              .stop(true, true)
              .slideDown(300)
              .delay(4000)
              .fadeOut(1000);
          }
        }
        else {
          $(namespace + ' .it_dialog_box')
            .text('Please select an answer.')
            .show()
            .delay(2500)
            .fadeOut(100)
        }
      });
      $(namespace + ' .mc_reset_game').on('click', function() {
        $(namespace + ' .mc_answer').removeClass('mc_selected');
        $(namespace + ' .mc_answer').removeClass('mc_submitted');
      });
    }
  };

  return MultipleChoiceGame
});
