define([], function() {
  var kind_select,
      icon_container,
      icon_select,
      icon_preview;

  function setVariables(config) {
    kind_select     = config.kind_select;
    icon_container  = config.icon_container;
    icon_select     = config.icon_select;
    icon_preview    = config.icon_preview;
  }
  function setListeners() {
    $(icon_select).on('change', previewIcon);
    $(kind_select).on('change', toggleIcon);
  }

  function previewIcon(e) {
    var icon_class = e.target.options[e.target.selectedIndex].dataset.class;
    icon_preview.className = icon_class;
  }

  function toggleIcon(e) {
    var selected = e.target.options[e.target.selectedIndex].value;
    if (selected === 'special feature') $(icon_container).removeClass('hidden');
    else $(icon_container).addClass('hidden');
  }

  return {
    preview: function(config) {
      setVariables(config);
      setListeners();
    }
  }
});