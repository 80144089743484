define([], function() {
  var centered,
    url,
    close,
    isTrial,
    message;

  function getURL(el) {
    return $(el).data('system-message-url');
  }

  function buildCenteredContent(element) {
    message = element.innerHTML;
    element.innerHTML = '';
    centered = document.createElement('div');
    centered.innerHTML = message;

    element.appendChild(centered);
  }

  function buildClose() {
    close = document.createElement('button');

    close.id = 'dismiss_sysmessage';
    close.innerText = '✕';

    centered.appendChild(close);
  }

  function setupListeners() {
    $(close).on('click', function() {
      $.ajax({
        type: 'PUT',
        url: url,
        data: { type: isTrial ? 'trial' : 'system' }
      })
        .done(function() {
          $('#system_message_container').hide(500);
        });
    });

    $('.next_link').on('click', function() {
      $(this).closest('.messages').hide();
      if ($(this).closest('.messages').next().length == 0) {
        $("div.messages").first().show().removeClass('hide');
      } else {
        $(this).closest('.messages').next().show().removeClass('hide');
      }
    });
    $('.prev_link').on('click', function() {
      $(this).closest('.messages').hide();
      if ($(this).closest('.messages').prev().length == 0) {
        $("div.messages").last().show().removeClass('hide');
      } else {
        $(this).closest('.messages').prev().show().removeClass('hide');
      }
    });
  }

  return {
    init: function() {
      $element = $('#system_message_container');
      if ($element.length) {
        url = getURL($element[0]);
        isTrial = $element.hasClass('trial_message');
        buildCenteredContent($element[0]);
        buildClose();
        setupListeners();
      }
    }
  }
});
