/* eslint-disable no-alert */
import CodeMirror from 'codemirror/lib/codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/addon/edit/closetag.js';

const ENGLISH_LEVEL_B_ID = 'section_section_reading_levels_attributes_1_translations_attributes_0_body';
const SPANISH_ID = 'section_section_reading_levels_attributes_2_translations_attributes_0_body';
const ORIGINAL_SPANISH_ID = 'section_translations_attributes_1_body';

const CODEMIRROR_SETTINGS = {
  autoCloseTags: true,
  lineNumbers: true,
  lineWrapping: true,
  mode: 'htmlmixed',
};

const replaceTemplate = () => {
  const html = $('select.template_select').find('option:selected').data('template');
  window.editor.getDoc().setValue(html);
  $('textarea#template_target').text(html);
  window.editor.refresh();
};

const setupListeners = () => {
  $('select.template_select').on('change', () => {
    if ($('textarea#template_target').html() !== '') {
      if (confirm('Are you sure you want to overwrite the HTML?')) replaceTemplate();
    }
    else replaceTemplate();
  });
};

const setupEditors = () => {
  const englishLevelBEditor = document.getElementById(ENGLISH_LEVEL_B_ID);
  let spanishEditor = document.getElementById(SPANISH_ID);

  // If viewing without reading levels, get original tab:
  if (!spanishEditor) {
    if (!spanishEditor) {
      spanishEditor = document.getElementById(ORIGINAL_SPANISH_ID);
    }
  }

  spanishEditor = spanishEditor || document.getElementById('slide_html_es');

  window.editor = CodeMirror.fromTextArea(document.getElementById('template_target'), CODEMIRROR_SETTINGS);

  if (englishLevelBEditor) {
    window.editor2 = CodeMirror.fromTextArea(englishLevelBEditor, CODEMIRROR_SETTINGS);
  }

  if (spanishEditor) {
    window.editor3 = CodeMirror.fromTextArea(spanishEditor, CODEMIRROR_SETTINGS);
  }
};

const templateSelect = () => {
  setupEditors();
  setupListeners();
};

export default templateSelect;
