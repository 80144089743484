define(['ImagePopup'], function(ImagePopup) {
  /**
   * Magnifier < ImagePopup
   *
   * ImagePopup with magnifying glass functionality for
   * large image zoom.
   *
   * @param config
   * @constructor
   */
  function Magnifier(config) {

    // Inherit from base class
    ImagePopup.call(this, config);

    // Extend
    this.fade_tool = config.fade_tool || false;
    this.radius = config.tool_radius || 0;
    this.zoomable_offset = 0;
    this.zoomable_offset_x = 0;
    this.zoomable_offset_y = 0;
    this.ZOOM_FACTOR = 2;
    this.MAG_BORDER_WIDTH = 4;
  }

// Start with a base prototype of ImagePopup.prototype
  Magnifier.prototype = Object.create(ImagePopup.prototype);
  Magnifier.prototype.constructor = Magnifier;

  /**
   *  Basic initialization that extends the base
   *  method to include listeners for mousemove (for
   *  magnifying glass)
   */
  Magnifier.prototype.init = function() {
    // Super
    ImagePopup.prototype.init.apply(this, arguments);
    this.setUpListeners();
  };

  /** Set up listeners */
  Magnifier.prototype.setUpListeners = function() {
    $(document).off('mousemove');
    $(document).on('mousemove', function(e) {
      this.move(e);
    }.bind(this));
  };

  /**
   * Overwrites the base method in order to include the
   * creation of the magnifying glass after image load
   *
   * @returns {*}
   * @private
   */
  Magnifier.prototype._createZoomedImage = function() {
    var dfd = $.Deferred(),
      this_obj = this,
      image = document.createElement('img');

    image.onload = function() {
      dfd.resolve();
    };

    image.src = this.zoom_url;

    dfd.done(function() {
      document.body.appendChild(image);
      this_obj._setImageDimensions(image);
      this_obj._createMagnifier();
    });

    return dfd.promise();
  };

  /**
   * Builds the magnifying glass DOM element
   * @private
   */
  Magnifier.prototype._createMagnifier = function() {
    var magnifier_container,
      this_obj = this;

    if (this.type == 'section') {
      magnifier_container = $('.simple_overlay:visible');
    }
    else if (this.type == 'map') {
      magnifier_container = $('#map');
    }
    else if (this.type == 'challenge') {
      magnifier_container = $('body');
    }

    this.magnified_image = document.createElement('div');
    this.magnified_image.className = 'magnified_image hide';
    this.magnified_image.style.backgroundImage = 'url("' + this_obj.zoom_url + '")';
    $(magnifier_container).append($(this.magnified_image));

    $(this.magnified_image).css( {
        'background-size' : this_obj.large_width + "px " + this_obj.large_height + "px",
        'border-radius' : this_obj.radius + '%' }
    );
  };

  /**
   * Shows the magnifying glass on mouse over, and hides it on
   * mouse out.
   *
   * Also calculates the proper position of the background image
   * in order to create the magnifying glass effect.
   *
   * @param event
   */
  Magnifier.prototype.move = function(event) {
    var $img = $(this.div_obj).find('img'),
      border_width = $img.css('border-width') ? (parseInt($img.css('border-width'))) * 2 : 0,
      small_width = border_width + parseInt($img.width()),
      small_height = border_width + parseInt($img.height()),
      $relative_div, slopX, slopY,
      mX, mY, zX, zY, bgp,
      this_obj = this;

    if (this.type == 'section') {
      $relative_div = $('#section_text_outer_container');
      slopX = 18;
      slopY = 20;
    }
    else if (this.type == 'map') {
      $relative_div = $('#map');
      slopX = 3;
      slopY = 27;
    }
    else if (this.type == 'challenge') {
      $relative_div = $(this.div_obj);
      slopX = 0;
      slopY = 4;
    }

    this.zoomable_offset_x = parseInt($relative_div.offset().left) + (parseInt($relative_div.css('left')) ? parseInt($relative_div.css('left')) : 0)  + (parseInt($relative_div.css('margin-left')) ? parseInt($relative_div.css('margin-left')) : 0) + slopX;
    this.zoomable_offset_y = parseInt($relative_div.offset().top) + (parseInt($relative_div.css('top')) ? parseInt($relative_div.css('top')) : 0 ) + slopY;


    if (this.type == 'section') {
      this.zoomable_offset_x = parseInt(this.div_obj.style.left, 10) + parseInt($(this.div_obj).css('padding-left'), 10) + 2;
      this.zoomable_offset_y = parseInt($(window).scrollTop(), 10) + parseInt(this.div_obj.style.top, 10) + parseInt($(this.div_obj).css('padding-top'), 10) + 3;
    }

    mX = event.pageX - this.zoomable_offset_x;
    mY = event.pageY - this.zoomable_offset_y;

    if (mX < $img.outerWidth() && mY < $img.outerHeight() && mX > 0 && mY > 0) {
      $(this.magnified_image).fadeIn(100).removeClass('hide');
    }
    else {
      $(this.magnified_image).fadeOut(100).addClass('hide');
    }

    if (this.type == 'challenge') {
      $(this.magnified_image).css('top', event.pageY - $(this_obj.magnified_image).height()/ 2);
      $(this.magnified_image).css('left', event.pageX - $(this_obj.magnified_image).width()/ 2);
    }
    else {
      $(this.magnified_image).css('top', mY + (slopY - 2) - $(this_obj.magnified_image).height()/ 2);
      $(this.magnified_image).css('left', mX + (slopX - 3) - $(this_obj.magnified_image).width()/ 2);
    }

    zX = Math.round(mX / small_width * this.large_width - $(this.magnified_image).width() / 2 + 3) * -1;
    zY = Math.round(mY / small_height * this.large_height - $(this.magnified_image).height() / 2 + 3) * -1;

    bgp = zX + "px " + zY + "px";

    $(this.magnified_image).css('background-position', bgp);
  };

  return Magnifier
});

