define([], function() {
  function ModalForm(config) {
    this.$submit_button = config.$submit_button;
    this.$form = config.$form;
  }

  ModalForm.prototype = {
    init: function() {
      this._setupListeners();

      return this
    },

    _setupListeners: function() {
      this.$submit_button.on('click', (e) => {
        this._submitForm();
        e.preventDefault();
      });
    },

    _submitForm: function() {
      $.ajax({
        type: 'POST',
        url: this.$form.attr('action'),
        data: $(this.$form).serialize()
      })
        .done(() => {
          window.current_open_modal.close();
        });
    },
  };

  return ModalForm
});
