define([], function() {
  function toggleBatchType() {
      $('#import_teachers_csv_type_of_upload_3, #import_students_csv_type_of_upload_3').on('click', function () {
          $('.batch_upload_selected').addClass('hide');
          $('.batch_update_selected').removeClass('hide');
      });

      $('#import_teachers_csv_type_of_upload_1, #import_students_csv_type_of_upload_1').on('click', function () {
          $('.batch_update_selected').addClass('hide');
          $('.batch_upload_selected').removeClass('hide');
      });

      $('#import_teachers_csv_type_of_upload_2, #import_students_csv_type_of_upload_2').on('click', function () {
          $('.batch_update_selected').addClass('hide');
          $('.batch_upload_selected').removeClass('hide');
      });
  }

  return {
   init: function() {
     toggleBatchType();
   }
 }
});

